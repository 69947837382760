import React from "react";
import AgendaItem from "../Agenda/AgendaItem";
import { AgendaBlockType } from "../../types/agendaBlockType";

interface Props {
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    mealBlockId?: number;
    onClick?: () => Promise<void>;
  }[];
  withoutLabel?: boolean;
}

export default function ReviewAgendaSection({ agenda, withoutLabel }: Props) {
  return (
    <>
      {!withoutLabel ? (
        <div className="reviewAgenda">
          <h3 className="reviewAgenda-title">Agenda</h3>
          {agenda
            .sort((it1, it2) => it1.index - it2.index)
            .map((item) => (
              <AgendaItem {...item} height="max" key={item.id} />
            ))}
        </div>
      ) : (
        <div className="flex flex-column gap-20 fullWidth">
          {agenda
            .sort((it1, it2) => it1.index - it2.index)
            .map((item) => (
              <AgendaItem {...item} height="max" key={item.id} />
            ))}
        </div>
      )}
    </>
  );
}
