import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/images/x-close-gray-lg.svg";
import { deleteDocument, postDocument, updateDocument } from "../../httpQueries/http";
import minusCircle from "../../assets/images/minus-circle.svg";
import plusIcon from "../../assets/images/plus-primary.svg";
import { FormInput } from "../Inputs/FormInput";
import { Button } from "../Buttons/Button";

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  eventId: number;
  setIsFileAdded?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFileModal?: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      img?: string | undefined;
    } | null>
  >;
  setSelectedLinks?: React.Dispatch<
    React.SetStateAction<{ url: string; id: number }[]>
  >;
  selectedLinks?: { url: string; id: number }[];
  editItem?: { url: string; id: number };
}

export default function AttachLinkModal({
  setShow,
  eventId,
  setIsFileAdded,
  setSelectedFileModal,
  setSelectedLinks,
  editItem,
}: Props) {
  const [links, setLinks] = useState<{ id: number; link: string }[]>([
    { id: 1, link: "" },
  ]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (editItem) {
      const indexOfHttps = editItem.url.lastIndexOf('https://') + 8;

      setLinks(prevState => [...prevState.filter(item => item.link.length), { id: editItem.id, link: editItem.url.slice(indexOfHttps) }]);
    }
  }, [editItem])

  const onAddLinks = () => {
    if (editItem) {
      deleteDocument({ id: eventId, token, documentId: editItem.id });
    }

    Promise.all(
      links.map((link) => {
        postDocument({
          id: eventId,
          token,
          body: {
            fileName: link.link,
            url: `https://${link.link}`,
          },
        }).then((response) => {
          try {
            setSelectedLinks &&
              setSelectedLinks((prevState) => {
                if (response?.document.url) {
                  return [
                    ...prevState,
                    { url: response?.document.url, id: response!.document.id },
                  ];
                } else {
                  return prevState;
                }
              });
            updateDocument({
              token,
              id: eventId,
              documentId: response!.document.id,
            });
          } catch (e) {
            console.log(e);
          }
        });
      })
    );
    setShow(false);
  };

  return (
    <div className="modalNotification">
      <div className="modalNotification-container modalNotification-container-attachDocument modalNotification-container-invite">
        <div className="modalNotification-header gap-0">
          <div className="">
            <img
              src={closeIcon}
              alt="close icon"
              className="modalNotification-header-actions-close modalInvite-closeIcon"
              onClick={() => {
                setShow(false);
                setIsFileAdded && setIsFileAdded((prevState) => !prevState);
                setSelectedFileModal && setSelectedFileModal(null);
              }}
            />
          </div>
          <div className="modalNotification-header-info">
            <h3>Add link(s)</h3>
            <p>Add any links you would like to have</p>
          </div>
        </div>
        <div className="modalNotification-main">
          {editItem ? (
            <FormInput
              key={editItem.id}
              type="Website"
              label={"Link*"}
              placeholder="www.example.com"
              fullWidth
              value={links[0].link}
              onChange={
                (e) =>
                setLinks((prevState) => [
                  ...prevState.filter((item) => item.id !== editItem.id),
                  { id: editItem.id, link: e.target.value },
                ])
              }
            />
          ) : (
            links
              .sort((item1, item2) => item1.id - item2.id)
              .map((currentLink, i) => {
                if (i === 0) {
                  return (
                    <FormInput
                      key={currentLink.id}
                      type="Website"
                      label={"Link*"}
                      placeholder="www.example.com"
                      fullWidth
                      value={currentLink.link.split("//")[1]}
                      onChange={(e) =>
                        setLinks((prevState) => [
                          ...prevState.filter(
                            (item) => item.id !== currentLink.id
                          ),
                          { id: currentLink.id, link: e.target.value },
                        ])
                      }
                    />
                  );
                }

                return (
                  <div className="flex items-end" key={currentLink.id}>
                    <FormInput
                      type="Website"
                      label={"Link*"}
                      placeholder="www.example.com"
                      fullWidth
                      value={currentLink.link}
                      key={currentLink.id}
                      onChange={(e) =>
                        setLinks((prevState) => [
                          ...prevState.filter(
                            (item) => item.id !== currentLink.id
                          ),
                          {
                            id: currentLink.id,
                            link: e.target.value.split("//")[1] || e.target.value,
                          },
                        ])
                      }
                    />
                    <Button
                      buttonType="small"
                      hierarchy="tertiaryGray"
                      size="lg"
                      contentCenter
                      buttonImage={minusCircle}
                      onClick={() =>
                        setLinks((prevState) =>
                          prevState.filter((item) => item.id !== currentLink.id)
                        )
                      }
                    />
                  </div>
                );
              })
          )}
          {!editItem && (
            <Button
              hierarchy="linkColor"
              size="lg"
              buttonType="regular"
              disabled={links.length === 5}
              onClick={() => {
                if (links.length <= 4) {
                  setLinks((prevState) => [
                    ...prevState,
                    {
                      id:
                        prevState.sort((it1, it2) => it1.id - it2.id)[
                          prevState.length - 1
                        ].id + 1,
                      link: "",
                    },
                  ]);
                }
              }}
            >
              <div className="flex items-center gap-8">
                <img src={plusIcon} alt="" />
                <p>Add another link</p>
              </div>
            </Button>
          )}
        </div>
        <div className="modalNotification-actions">
          <Button
            buttonType="regular"
            hierarchy="secondaryGray"
            size="lg"
            contentCenter
            fullWidth
            onClick={() => {
              setShow(false);
              setIsFileAdded && setIsFileAdded((prevState) => !prevState);
              setSelectedFileModal && setSelectedFileModal(null);
            }}
          >
            Cancel
          </Button>
          <Button
            buttonType="regular"
            hierarchy="primary"
            size="lg"
            contentCenter
            fullWidth
            onClick={onAddLinks}
            // disabled={!!links.length}
          >
            {editItem ? "Save" : "Add links"}
          </Button>
        </div>
      </div>
    </div>
  );
}
