import React from "react";
import CheckBox from "../CheckBox/CheckBox";

interface Props {
  label: string;
  options: {
    name: string;
    price: number;
    customizations: any[];
    min_qty: number;
    max_qty: number;
    conditional_price: {};
    formatted_price: string;
    default_qty: number;
    option_id: string;
    isSelected: boolean;
  }[];
  setOptions: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        price: number;
        customizations: any[];
        min_qty: number;
        max_qty: number;
        conditional_price: {};
        formatted_price: string;
        default_qty: number;
        option_id: string;
        isSelected: boolean;
      }[]
    >
  >;
}

export default function SelectOptions({ label, options, setOptions }: Props) {
  return (
    <div className="radioButtons">
      <h3 className="radioButtons-label">{label}</h3>
      <div>
        {options?.map((option) => (
          <div className="radioButtons-button" key={option.option_id}>
            <CheckBox setIsSelected={() => {}} isSelected={false} />
            <div className="radioButtons-button-content">
              <h3 className="radioButtons-button-content-name">
                {option.name}
              </h3>
              {option.price ? (
                <p className="radioButtons-button-content-price">
                  {option.price}
                </p>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
