import { useState, useEffect, useContext } from "react";
import closeIcon from "../../../assets/images/x-close-black.svg";
import { createPortal } from "react-dom";
import { FormInput } from "../../../components/Inputs/FormInput";
import DatePickerModal from "../../../components/DatePickers/DatePickerModal";
import TimePicker from "../../../components/TimePicker/TimePicker";
import { Button } from "../../../components/Buttons/Button";
import { LocationSearch } from "../../../components/LocationSearch/LocationSearch";
import ZonePicker from "../../../components/ZonePicker/ZonePicker";
import DatePicker from "../../../components/DatePickers/Date";
import { DropdownMenu } from "../../../components/Dropdown/DropdownMenu";
import ModalNotification from "../../../components/Modal/ModalNotification";
import { useNavigate, useParams } from "react-router-dom";
import { EventInfoContext } from "../../../utilities/eventInfoContext";
import ModalSaveAsDraft from "../../../components/Modal/ModalSaveAsDraft";
import searchIcon from "../../../assets/images/search-lg-gray.svg";
import {
  editEvent,
  fetchEvent,
  fetchLocations,
  fetchMyData,
  fetchTimezone,
  postEvent,
  postUserCompleteTutorial,
} from "../../../httpQueries/http";
import { getTimeInMinutes } from "../../../utilities/getTimeInMinutes";
import moment from "moment";
import { getUTCTimeFromMinutes } from "../../../utilities/getUTCTimeFromMinutes";
import { useDispatch } from "../../../store/hooks";
import { recentlyCreatedEventActions } from "../../../store/recentlyCreatedEvent";
import { supabase } from "../../../utilities/supabaseClient";
import { currentUserActions } from "../../../store/currentUser";
import avatar from "../../../assets/images/Avatar-olivia.svg";
import { AxiosError } from "axios";
import plusCircle from "../../../assets/images/plus-circle-2.svg";
import { timeZones } from "../../../utilities/defaultTimeZonesArray";
import { Times } from "../../../utilities/timesArray";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  getTimeFromMinutes,
  getTimeFromMinutesForEdit,
} from "../../../utilities/getTimeFromMinutes";
import { getEventTime } from "../../../utilities/getEventStartTime";

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  isDesktopSize: boolean;
  setSelectedStartTime: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
    } | null>
  >;
  setSelectedEndTime: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
    } | null>
  >;
  setFirstDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  setDescriptionInputValue: React.Dispatch<React.SetStateAction<string>>;
  setNameInputValue: React.Dispatch<React.SetStateAction<string>>;
  setEventIds: React.Dispatch<
    React.SetStateAction<{
      eventId: number;
      eventDayId: number;
    } | null>
  >;
  setSelectedLocation: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      supportText?: string | undefined;
      lat?: string;
      lng?: string;
    } | null>
  >;
  setLocationNotes: React.Dispatch<React.SetStateAction<string>>;
  setSelectedZone: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      timezone?: string;
    } | null>
  >;
  isEdit?: boolean;
  setCurrentLastStep: React.Dispatch<React.SetStateAction<number>>;
  currentLastStep: number;
  eventSlug: string;
  setSlug: React.Dispatch<React.SetStateAction<string>>;
}

export default function EventDetails({
  isDesktopSize,
  setSelectedStartTime,
  setSelectedEndTime,
  setFirstDate,
  setDescriptionInputValue,
  setNameInputValue,
  setSelectedLocation,
  setLocationNotes,
  setSelectedZone,
  setEventIds,
  isEdit,
  currentLastStep,
  setSlug,
}: Props) {
  const currentStep = 1;
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showStartTimePicker, setShowStartTimePicker] = useState(false);
  const [showEndTimePicker, setShowEndTimePicker] = useState(false);
  const [showLocationInfo, setShowLocationInfo] = useState(false);
  const [showSelectLocation, setShowSelectLocation] = useState(false);
  const [contentIsScrolled, setContentIsScrolled] = useState(false);
  const [inputLocationValue, setInputLocationValue] = useState("");
  const [showError, setShowError] = useState<{
    name: string;
    description: string;
  } | null>(null);
  const [isNameError, setIsNameError] = useState<string | null>(null);
  const [isDescriptionError, setIsDescriptionError] = useState<string | null>(
    null
  );
  const [isLocationError, setIsLocationError] = useState<string | null>(null);
  const [isStartTimeError, setIsStartTimeError] = useState<boolean>(false);
  const [timeZoneError, setTimeZoneError] = useState(false);
  const [isDateError, setIsDateError] = useState<boolean>(false);
  const [modalNotificationIsOpen, setModalNotificationIsOpen] = useState<{
    name: string;
    description: string;
    firstButtonName: string;
    secondButtonName: string;
    onFirstButtonClick: () => void;
    onSecondButtonClick: () => void;
  } | null>(null);
  const [isContinue, setIsContinue] = useState(false);
  const [isSavedAsDraft, setIsSavedAsDraft] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    eventDate: firstDate,
    startTime: selectedStartTime,
    endTime: selectedEndTime,
    eventDescription: descriptionInputValue,
    eventName: nameInputValue,
    location: selectedLocation,
    locationNotes,
    timeZone: selectedZone,
    eventIds,
  } = useContext(EventInfoContext);
  const token = localStorage.getItem("token");
  const slug = useParams().slug;
  const [locations, setLocations] = useState<
    { name: string; lng: number; lat: number }[] | undefined
  >([]);
  let timer = setTimeout(() => {}, 300);
  const [startData, setStartData] = useState<{
    name: string;
    description: string;
    startDate: null | moment.Moment;
    startTime: string;
    endTime: string | null;
    zone: string | null;
    location: string;
    notes: string;
  }>({
    name: "",
    description: "",
    startDate: null,
    startTime: "",
    endTime: null,
    zone: null,
    location: "",
    notes: "",
  });

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(async () => {
      const response = await fetchLocations({
        search: inputLocationValue,
        token,
      });

      setLocations(response);
    }, 400);

    return () => clearTimeout(timer);
  }, [inputLocationValue]);

  useEffect(() => {
    fetchTimezone({
      lat: selectedLocation?.lat ? +selectedLocation?.lat : undefined,
      lng: selectedLocation?.lng ? +selectedLocation.lng : undefined,
      token,
    }).then((res) => {
      if (res) {
        const timezone = timeZones.find(
          (item) => item.timezone === res.timezoneName
        );

        if (timezone) {
          const timezoneOffsetIsPositive = res.timezoneOffset >= 0;
          const timezoneOffsetHasHalfAnHout =
            res.timezoneOffset !== Math.floor(res.timezoneOffset);
          const timezoneOffset = timezoneOffsetIsPositive
            ? Math.floor(res.timezoneOffset)
            : Math.floor(res.timezoneOffset * -1);

          timezone.name = `(GMT${timezoneOffsetIsPositive ? "+" : "-"}${
            timezoneOffset < 10 ? "0" : ""
          }${timezoneOffset}:${timezoneOffsetHasHalfAnHout ? "30" : "00"}) ${
            timezone.name.split(")")[timezone.name.split(")").length - 1]
          }`;
          setSelectedZone(timezone || null);
        }
      }
    });
  }, [selectedLocation]);

  useEffect(() => {
    if (isEdit) {
      loadEditableEventData().then((data) => {
        if (data) {
          const startTime = getEventTime(data!.startTime, data.timezoneName);
          const endTime = data?.endTime
            ? getEventTime(data!.endTime, data.timezoneName)
            : null;

          setFirstDate(moment(data!.startDate));
          setDescriptionInputValue(data!.description);
          setNameInputValue(data!.name);
          setSelectedEndTime(endTime || null);
          setSelectedLocation({
            id: 1,
            name: `${data!.location}`,
            supportText: "",
            lat: data.locationLatitude!,
            lng: data.locationLongitude!,
          });
          setLocationNotes(data?.locationNote || "");
          setSelectedStartTime(startTime || null);
          setSelectedZone(
            data?.timezone
              ? { id: 1, name: data.timezone, timezone: data.timezoneName }
              : null
          );
          setEventIds({ eventId: data!.id, eventDayId: data!.days[0].id });

          setStartData({
            name: data!.name,
            description: data!.description,
            startDate: moment(data!.startDate),
            startTime: getUTCTimeFromMinutes(data!.startTime),
            endTime: data?.endTime
              ? getUTCTimeFromMinutes(data!.endTime)
              : null,
            zone: data?.timezone ? data.timezone : null,
            location: data!.location,
            notes: data?.locationNote || "",
          });
        } else {
          navigate("/events?filter=organizer");
        }
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (document) {
      document.body.style.overflow = showSelectLocation ? "hidden" : "auto";
    }
  }, [showSelectLocation]);

  useEffect(() => {
    if (isContinue === true) {
      if (
        !isNameError &&
        !isDateError &&
        !isDescriptionError &&
        !isLocationError &&
        !isStartTimeError &&
        !timeZoneError
      ) {
        if (!isEdit) {
          postEventDetails()
            .then((response) => {
              setSlug(response.slug);
              setEventIds({
                eventId: response.id,
                eventDayId: response.days[0].id,
              });

              navigate(`/createEvent/agenda`);
            })
            .catch(async (errorPost: AxiosError<{ message: string[] }>) => {
              const errorMessage = errorPost.response?.data?.message;

              if (errorMessage?.includes("this name is already taken")) {
                setIsNameError("This event name already exists.");
                setShowError({
                  name: "Event name not unique",
                  description: "This event name already exists.",
                });
              }

              if (
                errorMessage?.includes(
                  "timezoneName must be a valid IANA time-zone"
                )
              ) {
                setTimeZoneError(true);
                setShowError({
                  name: "Time zone is required",
                  description: "Please, select a time zone",
                });
              }

              if (errorPost.response?.status === 403) {
                const { error } = await supabase().auth.signOut();
                localStorage.clear();
                localStorage.clear();

                navigate("/login");
              }
            });
        }

        if (isEdit) {
          if (
            startData.name !== nameInputValue ||
            startData.description !== descriptionInputValue ||
            startData.endTime !==
              (selectedEndTime?.name ? selectedEndTime.name : null) ||
            startData.location !== (selectedLocation?.name || "") ||
            startData.notes !== locationNotes ||
            startData.startDate?.valueOf() !== firstDate?.valueOf() ||
            startData.startTime !== (selectedStartTime?.name || "") ||
            startData.zone !== selectedZone
          ) {
            editEventDetails()
              .then((response) => {
                setEventIds({
                  eventId: response.id,
                  eventDayId: response.days[0].id,
                });
                if (currentStep + 1 >= currentLastStep && !isEdit) {
                  navigate(`/createEvent/agenda`);
                } else {
                  navigate(`/edit/${slug}/agenda`);
                }
              })
              .catch(async (error) => {
                if (error.response?.status === 403) {
                  const { error } = await supabase().auth.signOut();
                  localStorage.clear();
                  localStorage.clear();

                  navigate("/login");
                }
              });
          } else {
            if (currentStep + 1 >= currentLastStep && !isEdit) {
              navigate(`/createEvent/agenda`);
            } else {
              navigate(`/edit/${slug}/agenda`);
            }
          }
        }
      }

      setIsContinue(false);
    }
  }, [isContinue]);

  useEffect(() => {
    if (isSavedAsDraft === true) {
      setTimeout(() => setIsSavedAsDraft(false), 5000);
    }
  }, [isSavedAsDraft]);

  async function loadEditableEventData() {
    const data = await fetchEvent({ slug, token });

    return data;
  }

  async function postEventDetails() {
    const res = await postEvent({
      body: {
        startTime: getTimeInMinutes(selectedStartTime!.name),
        endTime: selectedEndTime
          ? getTimeInMinutes(selectedEndTime!.name)
          : undefined,
        name: nameInputValue!,
        description: descriptionInputValue!,
        location: `${selectedLocation!.name} ${selectedLocation!.supportText}`,
        locationLatitude: selectedLocation?.lat
          ? `${selectedLocation?.lat}`
          : null,
        locationLongitude: selectedLocation?.lng
          ? `${selectedLocation?.lng}`
          : null,
        timezone: selectedZone?.name,
        timezoneName: selectedZone?.timezone,
        locationNote: locationNotes ? locationNotes : undefined,
        startDate: firstDate!.utcOffset(0, true).toISOString(),
        endDate: firstDate!.utcOffset(0, true).toISOString(),
      },
      token,
    });

    await postUserCompleteTutorial({ token });

    fetchMyData({ token }).then((response) => {
      if (response) {
        dispatch(
          currentUserActions.setCurrentUser({
            fullName: response.fullName,
            email: response.email,
            profileImage: avatar,
            completeTutorial: response.isTutorialCompleted,
          })
        );
      }
    });

    dispatch(
      recentlyCreatedEventActions.setRecentlyCreatedEvent({
        name: res.name,
        slug: res.slug,
      })
    );

    return res;
  }

  async function editEventDetails() {
    const fullDayInMinutes = 1440;
    const endTime = selectedEndTime
      ? getTimeInMinutes(selectedStartTime!.name) >
        getTimeInMinutes(selectedEndTime!.name)
        ? getTimeInMinutes(selectedEndTime!.name) + fullDayInMinutes
        : getTimeInMinutes(selectedEndTime!.name)
      : undefined;

    const res = await editEvent({
      body: {
        startTime: getTimeInMinutes(selectedStartTime!.name),
        endTime:
          endTime && endTime > fullDayInMinutes
            ? endTime - fullDayInMinutes
            : endTime,
        name: nameInputValue!,
        description: descriptionInputValue!,
        location: `${selectedLocation!.name} ${selectedLocation!.supportText}`,
        locationLatitude: selectedLocation?.lat
          ? `${selectedLocation?.lat}`
          : null,
        locationLongitude: selectedLocation?.lng
          ? `${selectedLocation?.lng}`
          : null,
        locationNote: locationNotes ? locationNotes : undefined,
        timezone: selectedZone?.name,
        timezoneName: selectedZone?.timezone,
        startDate: firstDate!.utcOffset(0, true).toISOString(),
        endDate: endTime
          ? firstDate!
              .set("hours", 0)
              .set("minutes", endTime)
              .utcOffset(0, true)
              .toISOString()
          : firstDate!.utcOffset(0, true).toISOString(),
      },
      token,
      id: eventIds!.eventId,
    });

    return res;
  }

  const onContinue = async () => {
    setIsContinue(true);

    if (!nameInputValue!.trim().length) {
      setIsNameError("Field is required");
      setShowError({
        name: "Missing information",
        description: "Please fill in all required fields before submitting.",
      });
    }

    if (nameInputValue!.trim().length) {
      setIsNameError(null);
    }

    if (!descriptionInputValue!.trim().length) {
      setIsDescriptionError("Field is required");
      setShowError({
        name: "Missing information",
        description: "Please fill in all required fields before submitting.",
      });
    }

    if (descriptionInputValue!.trim().length) {
      setIsDescriptionError(null);
    }

    if (!selectedLocation) {
      setIsLocationError("Field is required");
      setShowError({
        name: "Missing information",
        description: "Please fill in all required fields before submitting.",
      });
    }

    if (selectedLocation) {
      setIsLocationError(null);
    }

    if (!selectedStartTime) {
      setIsStartTimeError(true);
    }

    if (selectedStartTime) {
      setIsStartTimeError(false);
    }

    if (!firstDate) {
      setIsDateError(true);
    }

    if (firstDate) {
      setIsDateError(false);
    }

    if (!selectedZone) {
      setTimeZoneError(true);
    }

    if (selectedZone) {
      setTimeZoneError(false);
    }
  };

  return (
    <div
      className={`fullHeight fullWidth flex flex-column ${
        isDesktopSize ? "gap-24" : "gap-16"
      }`}
    >
      <div className="eventDetails_main-header eventDetails_main-header-absolute">
        <h2 className="color-gray-900 text-xl font-semibold">Create event</h2>
        <img
          src={closeIcon}
          alt=""
          onClick={() => navigate("/events?filter=organizer")}
        />
      </div>
      <div className="overflowYScroll eventDetails_content-absolute">
        <div
          className="eventDetails_main eventDetails_main-createEvent"
          style={{ height: "fit-content", marginBottom: "100px" }}
        >
          {modalNotificationIsOpen
            ? createPortal(
                <ModalNotification
                  setIsOpen={setModalNotificationIsOpen}
                  name={modalNotificationIsOpen.name}
                  description={modalNotificationIsOpen.description}
                  onFirstButton={modalNotificationIsOpen.onFirstButtonClick}
                  onSecondButton={modalNotificationIsOpen.onSecondButtonClick}
                  firstButtonName={modalNotificationIsOpen.firstButtonName}
                  secondButtonName={modalNotificationIsOpen.secondButtonName}
                />,
                document.getElementById("modal") as HTMLElement
              )
            : null}
          {isSavedAsDraft ? (
            <ModalSaveAsDraft setIsOpen={setIsSavedAsDraft} />
          ) : null}
          <div
            className="eventDetails_content eventDetails_content-createEvent"
            style={{ height: "fit-content" }}
            id="mobileEventDetailsContent"
            onScroll={() =>
              setContentIsScrolled(
                (
                  document.getElementById(
                    "mobileEventDetailsContent"
                  ) as HTMLElement
                ).scrollTop >= 32
              )
            }
          >
            <div className="eventDetails_main-content">
              <h3 className="xs font-semibold color-gray-900">
                Basic information
              </h3>
              <FormInput
                type="Text"
                label="Event name*"
                isError={!!isNameError}
                errorMessage={!!isNameError ? isNameError : undefined}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => setNameInputValue(event.target.value)}
                hasHelpIcon={!!isNameError}
                value={nameInputValue || ""}
                design="new"
              />
              <FormInput
                type="TextArea"
                label="Description*"
                isError={!!isDescriptionError}
                errorMessage={!!isDescriptionError ? isDescriptionError : ""}
                hasHelpIcon={!!isDescriptionError}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => setDescriptionInputValue(event.target.value)}
                value={descriptionInputValue || ""}
                design="new"
              />
              {isDesktopSize ? (
                <DatePicker
                  type="smallWithTodayFeat"
                  top={"-225%"}
                  left={"0"}
                  placeholder="Date of event"
                  isError={isDateError}
                  firstDate={firstDate}
                  setFirstDate={setFirstDate}
                  setIsOpen={setShowDatePicker}
                  isOpen={showDatePicker}
                  setShowOtherPickers={() => {
                    setShowEndTimePicker(false);
                    setShowSelectLocation(false);
                    setShowStartTimePicker(false);
                  }}
                  selectedDayOnlyFromToday={true}
                  paddingNone={firstDate ? true : false}
                />
              ) : (
                <DatePickerModal
                  show={showDatePicker}
                  type="smallWithTodayFeat"
                  setShow={setShowDatePicker}
                  firstDate={firstDate}
                  setFirstDate={setFirstDate}
                  selectedDayOnlyFromToday={true}
                  paddinNone={firstDate ? true : false}
                />
              )}
              <div
                className={`flex gap-24 items-center ${
                  !isDesktopSize ? "flex-column" : ""
                }`}
              >
                <TimePicker
                  setShow={() => {
                    setShowStartTimePicker((prevState) => !prevState);
                    setShowSelectLocation(false);
                    setShowDatePicker(false);
                    setShowEndTimePicker(false);
                  }}
                  time={selectedStartTime}
                  setTime={setSelectedStartTime}
                  show={showStartTimePicker}
                  placeholder="Start time*"
                  startOfPhrase="Starts"
                  isDesktopSize={isDesktopSize}
                  isError={isStartTimeError}
                />
                <TimePicker
                  setShow={() => {
                    setShowEndTimePicker((prevState) => !prevState);
                    setShowSelectLocation(false);
                    setShowDatePicker(false);
                    setShowStartTimePicker(false);
                  }}
                  time={selectedEndTime}
                  setTime={setSelectedEndTime}
                  show={showEndTimePicker}
                  placeholder="End time"
                  startOfPhrase="Ends"
                  isDesktopSize={isDesktopSize}
                  minValue={selectedStartTime}
                  disabled={!selectedStartTime}
                />
              </div>
              {/* <ZonePicker
                  setShow={() => {
                    setShowZonePicker(prevState => !prevState);
                    setShowSelectLocation(false);
                    setShowDatePicker(false);
                    setShowStartTimePicker(false);
                    setShowEndTimePicker(false);
                  }}
                  setZone={setSelectedZone}
                  show={showZonePicker}
                  zone={selectedZone}
                  isDesktopSize={isDesktopSize}
                  isError={timeZoneError}
                /> */}
              <div
                onClick={() => {
                  setShowDatePicker(false);
                  setShowEndTimePicker(false);
                  setShowStartTimePicker(false);
                }}
                className="positionRelative"
              >
                {showSelectLocation &&
                isDesktopSize &&
                inputLocationValue.length &&
                locations ? (
                  <DropdownMenu
                    content={locations.map((location) => ({
                      name: location.name.slice(
                        0,
                        location.name.indexOf(",") + 1
                      ),
                      supportText: location.name.slice(
                        location.name.indexOf(",") + 1
                      ),
                      id: `${location.lat}${location.lng}`,
                      lat: location.lat,
                      lng: location.lng,
                    }))}
                    selectedItem={selectedLocation}
                    setSelectedItem={setSelectedLocation}
                    setShow={() => {
                      setShowSelectLocation(false);
                    }}
                    withSupportText
                    size="lg"
                    heightSize="sm"
                    positionTop={"-180px"}
                    fullWidth
                    zIndex="100"
                  />
                ) : null}
                {!showSelectLocation && selectedLocation ? (
                  <div
                    className="formNew"
                    onClick={() => setShowSelectLocation(true)}
                  >
                    <h3 className="eventDetails_content-location-title">
                      Location*
                    </h3>
                    <div className="inputNew inputNew-filled justifyStart overflowXScroll">
                      <img src={searchIcon} alt="" />
                      <p className="eventDetails_content-location-main whiteSpaceNoWrap">
                        {selectedLocation?.name}
                      </p>
                      <p className="eventDetails_content-location-support whiteSpaceNoWrap">
                        {selectedLocation?.supportText}
                      </p>
                    </div>
                  </div>
                ) : (
                  <FormInput
                    label={
                      selectedLocation?.name
                        ? `${selectedLocation?.name}, ${selectedLocation?.supportText}`
                        : "Location*"
                    }
                    type="SearchGray"
                    isError={!!isLocationError}
                    errorMessage={!!isLocationError ? isLocationError : ""}
                    onChange={(
                      event: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => setInputLocationValue(event.target.value)}
                    value={inputLocationValue}
                    hasHelpIcon={!!isLocationError}
                    hasIconBefore
                    setShow={() =>
                      isDesktopSize ? setShowSelectLocation(false) : () => {}
                    }
                    onFocus={() => {
                      setShowSelectLocation(true);
                    }}
                    design="new"
                  />
                )}
              </div>
              <div className="flex flex-column gap-10">
                <p className={`text-xs font-medium color-gray-600`}>Timezone</p>
                <p
                  className={`text-lg font-medium ${
                    timeZoneError ? "color-red-900 text-md" : "color-gray-950"
                  }`}
                >
                  {timeZoneError && !selectedZone
                    ? "Please, select a timezone"
                    : selectedZone?.name}
                </p>
              </div>
              {showSelectLocation && !isDesktopSize
                ? createPortal(
                    <LocationSearch
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      setShowSelectLocation={setShowSelectLocation}
                      locations={locations?.map((location) => ({
                        name: location.name.slice(
                          0,
                          location.name.indexOf(",") + 1
                        ),
                        supportText: location.name.slice(
                          location.name.indexOf(",") + 1
                        ),
                        id: `${location.lat}${location.lng}`,
                        lat: location.lat,
                        lng: location.lng,
                      }))}
                      inputValue={inputLocationValue}
                      setInputValue={setInputLocationValue}
                    />,
                    document.getElementById("modal") as HTMLElement
                  )
                : null}
              <Button
                buttonType="regular"
                size="md"
                hierarchy="linkColor"
                onClick={() => setShowLocationInfo((prevState) => !prevState)}
                fullWidth
                paddingNone
              >
                <div
                  className={`openButton ${
                    true ? "" : "openButton-width-content"
                  } text-sm`}
                >
                  <img
                    src={plusCircle}
                    alt="chevron down icon"
                    className="calendarIcon"
                  />
                  <p>Add location details</p>
                </div>
              </Button>
              {showLocationInfo ? (
                <div className="flex flex-column gap-24">
                  <FormInput
                    label={
                      locationNotes?.length
                        ? "Location Notes"
                        : "Location details; ex: 3rd floor in the office"
                    }
                    type="TextArea"
                    value={locationNotes}
                    design="new"
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => setLocationNotes(e.target.value)}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div
        className="eventDetails_main-footer"
        style={{ position: "fixed", bottom: "0" }}
      >
        {isDesktopSize ? (
          <Button
            buttonType="regular"
            hierarchy="tertiaryColor"
            size="lg"
            onClick={() =>
              setModalNotificationIsOpen({
                name: "Delete event",
                description:
                  "You have not saved this event yet. If you leave now, your event will be deleted. Are you sure you want to continue?",
                firstButtonName: "Cancel",
                secondButtonName: "Delete event",
                onFirstButtonClick: () => setModalNotificationIsOpen(null),
                onSecondButtonClick: () => navigate("/"),
              })
            }
          >
            Cancel
          </Button>
        ) : null}
        <div
          className={`flex flex-row gap-12 items-center ${
            !isDesktopSize ? "spaceBetween fullWidth" : ""
          }`}
        >
          <Button
            buttonType="regular"
            hierarchy="newDesign-secondary"
            size="lg"
            fullWidth
            contentCenter
            onClick={() => setIsSavedAsDraft(true)}
            newDesignPaddingNone
          >
            <div className="eventDetails_main-footer-buttonSaveAsDraft">
              <p>Save as draft</p>
            </div>
          </Button>
          <Button
            size="lg"
            buttonType="regular"
            hierarchy="newDesign-primary"
            fullWidth
            contentCenter
            newDesignPaddingNone
            fullHeight
            onClick={onContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
