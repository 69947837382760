import React, { useContext, useEffect, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { Button } from "../../components/Buttons/Button";
import ReviewAgendaSection from "../../components/Sections/ReviewAgendaSection";
import LogoRepeat from "../../components/LogoRepeat/LogoRepeat";
import pathIcon from "../../assets/images/path90.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import usersPlusIcon from "../../assets/images/users-plus.svg";
import {
  deleteEvent,
  editEventLogo,
  fetchAttendeesNetwork,
  fetchDocument,
  fetchEvent,
  fetchEventAgenda,
  fetchInvitedAttendees,
  fetchMeals,
  fetchRestaurantMenu,
  fetchSpecificRestaurant,
  fetchTodoItems,
  postAttendeeRSVP,
  postMealsSelections,
  publishEvent,
  queryClient,
} from "../../httpQueries/http";
import moment from "moment";
import presentationIcon from "../../assets/images/presentation-chart-01.svg";
import clockPlusIcon from "../../assets/images/clock-plus.svg";
import happyFaceIcon from "../../assets/images/face-happy.svg";
import { durations } from "../../utilities/agendaDurationsArray";
import { editTime } from "../../utilities/EditAgendaBlockTime";
import { logoColor } from "../../types/logoColor";
import { logoImage } from "../../types/logoImage";
import { getLogoRepeatImage } from "../../utilities/getLogoRepeatImage";
import { AttendeeRSVP } from "../../types/attendeeRSVP";
import { supabase } from "../../utilities/supabaseClient";
import Loader from "../../components/Loader";
import { AgendaBlockType } from "../../types/agendaBlockType";
import { createPortal } from "react-dom";
import ModalAddNewAttende from "../../components/Modal/ModalAddNewAttende";
import AttachDocumentModal from "../../components/Modal/AttachDocumentModal";
import ModalAddAttendees from "../../components/Modal/ModalAddAttendees";
import { MealBlockChoice, MealBlockType } from "../../types/meals";
import PreviewPreselectedMealsInviteesMeals from "./SelectCustomizationAttendeesView/PreviewPreselectedMealsForAttendees";
import { TodoItem, TodoItemType } from "../../types/todoItem";
import { markTodoAsCompleted } from "../../utilities/markTodoAsCompleted";
import PaymentCheckout from "../../components/PaymentModal/PaymentCheckout";
import TodoPreview from "../../components/Todo/TodoPreview";
import checkCircleIcon from "../../assets/images/check-circle-black.svg";
import alertTriangle from "../../assets/images/alert-triangle.svg";
import userXIcon from "../../assets/images/user-x-01.svg";
import EventReviewBlockV2 from "../../components/EventBlock/EventReviewBlockV2";
import arrowLeft from "../../assets/images/arrow-narrow-left.svg";
import closeIcon from "../../assets/images/x-close-black.svg";
import { MapboxMap } from "../../components/MapBox/Map";
import LocationButton from "../../components/LocationButton";
import ModalNotificationNew from "../../components/Modal/ModalNotificationNew";
import checkSuccessIcon from "../../assets/images/check-circle-green.svg";
import RSVP from "../../components/RSVP/RSVP";
import { NotificationValue } from "../../utilities/NotificationContext";
import ModalCreateEventLogo from "../../components/Modal/ModalCreateEventLogo";
import { getEventTime } from "../../utilities/getEventStartTime";
import { RSVPEventValue } from "../../utilities/RSVPEventContext";
import pdfIcon from "../../assets/images/fileTypeIconPdf.svg";
import EventInfoPreview from "../../components/Previews/EventInfoPreview";
import fileAttachmentIcon from "../../assets/images/file-attachment-05-black.svg";
import AttachLinkModal from "../../components/Modal/AttachLinkModal";
import { DropdownMenu } from "../../components/Dropdown/DropdownMenu";
import linkIcon from "../../assets/images/link-03.svg";
import uploadCloudIcon from "../../assets/images/upload-cloud-02-black.svg";
import Document from "../../components/Document/Document";

export default function ReviewEventDetailsView() {
  const slug = useParams().slug;
  const [selectedRSVP, setSelectedRSVP] = useState<AttendeeRSVP | undefined>();
  const [isAttachDocumentModalOpen, setIsAttachDocumentModalOpen] =
    useState(false);
  const [isDocumentsUploaded, setIsDocumentsUploaded] = useState(false);
  const { data: event, isFetching } = useQuery({
    queryKey: ["event", slug, isDocumentsUploaded],
    queryFn: () => fetchEvent({ slug, token }).catch(() => navigate("/*")),
  });
  const { data: todoItems, refetch } = useQuery({
    queryKey: ["todos", event, event?.rsvp],
    queryFn: () =>
      fetchTodoItems({ token, take: 10, skip: 0, eventId: event?.id }),
  });
  const [preOrder, setPreOrder] = useState<
    {
      customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
      }[];
      productId: string;
      markedPrice: number;
      notes: string;
    }[]
  >([]);
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [selectedAddAttendees, setSelectedAddAttendees] = useState<{
    id: number;
    name: string;
    supportText?: string | undefined;
    value?: number | undefined;
  } | null>(null);
  const [newUserFullName, setNewUserFullName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPhoneNumber, setNewUserPhoneNumber] = useState("");
  const [newUserBusinessName, setNewUserBusinessName] = useState("");
  const [newUserTitle, setNewUserTitle] = useState("");
  const [userUpdated, setUserUpdated] = useState<number | null>(null);
  const [userDeleted, setUserDeleted] = useState<number | null>(null);
  const [createNewUserIsOpen, setCreateNewUserIsOpen] = useState(false);
  const [attendees, setAttendees] = useState<
    | {
        attendees: {
          id: number;
          networkMemberId: number;
          status: "added";
          rsvp: AttendeeRSVP | null;
          networkMember: {
            id: number;
            fullName: string;
            email: string;
            phone: string;
            businessName: string;
            address: string;
            title: string;
            userId: null | number;
            accountId: number;
            createdAt: string;
            updatedAt: string;
            user: {
              accountId: number;
              createdAt: string;
              email: string;
              fullName: string;
              id: number;
              phone?: string | null;
              status: string;
              supabaseId: string;
              updatedAt: string;
            } | null;
          };
          mealSelections: any[];
        }[];
        count: number;
      }
    | "isFetching"
  >({ attendees: [], count: 0 });
  const [searchValue, setSearchValue] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [attendeesNetwork, setAttendeesNetwork] = useState<
    {
      accountId: number;
      createdAt: string;
      email: string;
      fullName: string;
      id: number;
      phone?: string;
      updatedAt: string;
      address: string | null;
      businessName: string | null;
      title: string | null;
    }[]
  >();
  const [selectMealsModalIsOpen, setSelectMealsModalIsOpen] = useState(false);
  const token = localStorage.getItem("token");
  const [isUsersInvitedQueryWorks, setIsUsersInvitedQueryWorks] =
    useState(true);
  const [eventAgendaMealBlock, setEventAgendaMealBlock] = useState<
    {
      name: string;
      subcategory_id: string;
      menu_item_list: {
        count: number;
        name: string;
        price: number;
        qty_available: null;
        unit_size: null;
        unit_of_measurement: string;
        description: string;
        is_available: boolean;
        image: string;
        customizations: [
          {
            name: string;
            min_choice_options: number;
            max_choice_options: number;
            options: [
              {
                name: string;
                price: number;
                customizations: any[];
                min_qty: number;
                max_qty: number;
                conditional_price: {};
                formatted_price: string;
                default_qty: number;
                option_id: string;
              }
            ];
            customization_id: string;
          }
        ];
        min_price: number;
        original_price: number;
        formatted_price: string;
        attributes: [];
        product_id: string;
        thumbnail_image: string;
        should_fetch_customizations: boolean;
        supports_image_scaling: boolean;
      }[];
    }[]
  >([]);
  const [mealBlockId, setMealBlockId] = useState<number | undefined>();
  const [selectedRestaurant, setSelectedRestaurant] = useState<{
    id: string | number;
    name: string;
    logo: string;
    status?: "Open" | "Close" | undefined;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
  } | null>(null);
  const navigate = useNavigate();
  const [orderMealsModalIsOpen, setOrderMealsModalIsOpen] = useState(false);
  const [mealBlockIdForOrder, setMealBlockIdForOrder] = useState(0);
  const [cartId, setCartId] = useState<null | string>(null);
  const [isEventHaveAlreadyPassed, setIsEventHaveAlreadyPassed] =
    useState(false);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [pageView, setPageView] = useState<"attendee" | "organizer">(
    "organizer"
  );
  const [isSelectFileModalOpen, setIsSelectFileModalOpen] =
    useState<boolean>(false);
  const [notificationModalIsOpen, setNotificationModalIsOpen] = useState<{
    name: string;
    description: string;
    firstButtonName: string;
    secondButtonName: string;
    onFirstButtonClick: () => void;
    onSecondButtonClick: () => void;
  } | null>(null);
  const [selectedFileModal, setSelectedFileModal] = useState<{
    id: number;
    name: string;
    img?: string;
  } | null>(null);
  const [orders, setOrders] = useState<
    {
      agendaBlockId: number;
      customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
      }[];
      productId: string;
      markedPrice: number;
      notes: string;
      count: number;
    }[]
  >([]);
  const { setNotification } = useContext(NotificationValue);
  const [eventLogo, setEventLogo] = useState<logoImage>(logoImage.PATH);
  const [eventLogoColor, setEventLogoColor] = useState<logoColor>(
    logoColor.BLUE
  );
  const [isEditLogoModalOpen, setIsEditLogoModalOpen] = useState(false);
  const { eventSlug, setEventSlug } = useContext(RSVPEventValue);
  const [uploadedDocuments, setUploadedDocuments] = useState<
    {
      document: {
        id: number;
        isUploaded: boolean;
        key: string;
        originalFileName: string;
        url: string | null;
      };
      url?: string | undefined;
    }[]
  >([]);
  const [documents, setDocuments] = useState<
    { name: string; url: string; id?: number }[]
  >([]);
  const [links, setLinks] = useState<{ url: string; id: number }[]>([]);
  const [currentEditableLink, setCurrentEditableLink] = useState<
    { url: string; id: number } | undefined
  >();

  useEffect(() => {
    const timer = setTimeout(async () => {
      const data = await fetchAttendeesNetwork({
        skip: 0,
        take: 10,
        fetchEvents: false,
        token,
        search: searchValue.length ? searchValue : undefined,
      });
      setAttendeesNetwork(data);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    if (userDeleted) {
      setAttendees((prevState) => {
        if (prevState !== "isFetching") {
          return {
            attendees: prevState!.attendees.filter(
              (contact) => contact.id !== userDeleted
            ),
            count: prevState!.attendees.filter(
              (contact) => contact.id !== userDeleted
            ).length,
          };
        }

        return { attendees: [], count: 0 };
      });

      setUserDeleted(null);
    }
  }, [userDeleted]);

  useEffect(() => {
    if (selectedRSVP !== event?.rsvp) {
      setSelectedRSVP(event?.rsvp);
    }

    if (event) {
      setIsEventHaveAlreadyPassed(
        +new Date(event.startTime).valueOf() < +new Date().valueOf()
      );
    } else {
      setIsEventHaveAlreadyPassed(false);
    }

    setEventLogo(event?.pattern || logoImage.PATH);
    setEventLogoColor(event?.color || logoColor.BLUE);

    setIsOrganizer(!!event?.isOrganizer);
  }, [event]);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  useEffect(() => {
    if (!isUsersInvitedQueryWorks) {
      setAttendees("isFetching");
      fetchInvitedAttendees({
        token,
        eventId: event!.id,
        take: 20,
        skip: 0,
      }).then((response) => {
        if (response) {
          setAttendees(response);
        }
      });
    }
  }, [isUsersInvitedQueryWorks]);

  useEffect(() => {
    if (isDocumentsUploaded) {
      fetchEvent({ slug, token }).then((response) => {
        queryClient.setQueryData(["event", slug], {
          data: {
            ...response,
          },
        });
      });

      setIsDocumentsUploaded(false);
    }
  }, [isDocumentsUploaded]);

  useEffect(() => {
    if (event && event.documents) {
      const documents: {
        document: {
          id: number;
          isUploaded: boolean;
          key: string;
          originalFileName: string;
          url: string | null;
        };
        url?: string | undefined;
      }[] = [];
      Promise.all(
        event.documents.map((document) =>
          fetchDocument({ documentId: document.id, token, id: event.id }).then(
            (document) => {
              if (document) {
                documents.push(document);
              }
            }
          )
        )
      );

      setUploadedDocuments(documents);
    }
  }, [event]);

  function scrollFunction() {
    if (
      Math.ceil(document.getElementById("scrolledInfo")?.scrollTop || 0) >= 1
    ) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear();
    localStorage.clear();

    navigate("/login");

    return null;
  }

  async function onRSVPSelect(item: AttendeeRSVP) {
    if (!event) return;

    if (eventSlug === event.slug) {
      setEventSlug("");
    }

    let rsvpTodo: any | TodoItem = undefined;

    if (todoItems?.todoItems) {
      rsvpTodo =
        todoItems.todoItems[
          todoItems.todoItems.findIndex(
            (item) => item.type === TodoItemType.RespondToInvite
          )
        ];
    }

    await postAttendeeRSVP({ body: { rsvp: item, eventId: event.id }, token });

    if (rsvpTodo) {
      await markTodoAsCompleted(rsvpTodo, token);
    }

    queryClient.setQueryData(["event", event.slug], {
      ...event,
      rsvp: item,
    });

    refetch();
  }

  if (!event) {
    return null;
  }

  return (
    <div
      className={`eventDetails_container ${
        !sizeIsDesktop ? "flex-column" : "flex-row"
      }`}
    >
      {isAttachDocumentModalOpen && selectedFileModal
        ? selectedFileModal.name === "Upload files"
          ? createPortal(
              <AttachDocumentModal
                setDocuments={setDocuments}
                widthIsMobile={!sizeIsDesktop}
                setSelectedFileModal={setSelectedFileModal}
                attachedFilesCount={uploadedDocuments.length}
                setShow={setIsAttachDocumentModalOpen}
                eventId={event.id}
              />,
              document.getElementById("modal") as HTMLElement
            )
          : createPortal(
              <AttachLinkModal
                setSelectedLinks={setLinks}
                setSelectedFileModal={setSelectedFileModal}
                setShow={setIsAttachDocumentModalOpen}
                eventId={event.id}
                editItem={currentEditableLink}
              />,
              document.getElementById("modal") as HTMLElement
            )
        : null}
      {createNewUserIsOpen
        ? createPortal(
            <ModalAddNewAttende
              setShow={() => setCreateNewUserIsOpen(false)}
              setUserFullName={setNewUserFullName}
              userFullName={newUserFullName}
              setUserEmail={setNewUserEmail}
              userEmail={newUserEmail}
              setUserPhoneNumber={setNewUserPhoneNumber}
              userPhoneNumber={newUserPhoneNumber}
              setUserBusinessName={setNewUserBusinessName}
              userBusinessName={newUserBusinessName}
              setUserTitle={setNewUserTitle}
              userTitle={newUserTitle}
              eventId={event!.id}
              setNewUserAdded={() => {}}
              isEdit={userUpdated}
              setUserUpdated={setUserUpdated}
              isDesktopSize={sizeIsDesktop}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {orderMealsModalIsOpen ? (
        <PaymentCheckout
          mealBlockId={mealBlockIdForOrder}
          alreadySettedCartId={cartId}
          setIsOpen={setOrderMealsModalIsOpen}
        />
      ) : null}
      {notificationModalIsOpen
        ? createPortal(
            <ModalNotificationNew
              setIsOpen={setNotificationModalIsOpen}
              name={notificationModalIsOpen.name}
              description={notificationModalIsOpen.description}
              onFirstButton={notificationModalIsOpen.onFirstButtonClick}
              onSecondButton={notificationModalIsOpen.onSecondButtonClick}
              firstButtonName={notificationModalIsOpen.firstButtonName}
              secondButtonName={notificationModalIsOpen.secondButtonName}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {isEditLogoModalOpen
        ? createPortal(
            <ModalCreateEventLogo
              onSave={(color: logoColor, image: logoImage) => {
                setEventLogo(image);
                setEventLogoColor(color);
                editEventLogo({
                  color,
                  pattern: image,
                  token,
                  id: event.id,
                  isOrganizer: event.isOrganizer,
                });
              }}
              setIsOpen={setIsEditLogoModalOpen}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {createPortal(
        <PreviewPreselectedMealsInviteesMeals
          selectedRestaurant={selectedRestaurant}
          showSelectMealModal={selectMealsModalIsOpen}
          menu={eventAgendaMealBlock}
          preOrder={preOrder}
          setPreOrder={setPreOrder}
          mealBlockId={mealBlockId}
          setSelectMealsModalIsOpen={setSelectMealsModalIsOpen}
          asyncOnAddMeal={async () => {
            if (mealBlockId && orders.length) {
              await postMealsSelections({
                id: mealBlockId,
                body: {
                  customizations: orders[0].customizations,
                  productId: orders[0].productId,
                  markedPrice: orders[0].markedPrice,
                  notes: orders[0].notes,
                },
                token,
              });

              setSelectMealsModalIsOpen(false);
            }
          }}
          isDesktopSize={sizeIsDesktop}
          setOrders={setOrders}
          orders={orders}
        />,
        document.getElementById("modal") as HTMLElement
      )}
      {selectedAddAttendees?.name === "Add existing attendees" &&
      attendees !== "isFetching"
        ? sizeIsDesktop
          ? createPortal(
              <ModalAddAttendees
                attendees={
                  attendees
                    ? attendeesNetwork!.filter((attendee) => {
                        const invitedAttendeesEmails = attendees.attendees.map(
                          (item) =>
                            item.networkMember.user
                              ? item.networkMember.user.email
                              : item.networkMember.email
                        );

                        return !invitedAttendeesEmails.includes(attendee.email);
                      })
                    : attendeesNetwork!
                }
                setShow={setSelectedAddAttendees}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                eventId={event!.id}
                setIsUsersInvitedQueryWorks={setIsUsersInvitedQueryWorks}
              />,
              document.getElementById("modal") as HTMLElement
            )
          : null
        : selectedAddAttendees?.name === "Add new"
        ? createPortal(
            <ModalAddNewAttende
              setShow={() => setSelectedAddAttendees(null)}
              setUserFullName={setNewUserFullName}
              userFullName={newUserFullName}
              setUserEmail={setNewUserEmail}
              userEmail={newUserEmail}
              setUserPhoneNumber={setNewUserPhoneNumber}
              userPhoneNumber={newUserPhoneNumber}
              setUserBusinessName={setNewUserBusinessName}
              userBusinessName={newUserBusinessName}
              setUserTitle={setNewUserTitle}
              userTitle={newUserTitle}
              eventId={event!.id}
              setNewUserAdded={() => {}}
              isEdit={userUpdated}
              setUserUpdated={setUserUpdated}
              isDesktopSize={sizeIsDesktop}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      <div
        className={`reviewEventDetailsPage-fixedHeader ${
          pageView === "attendee"
            ? "reviewEventDetailsPage-fixedHeader-attendeeView"
            : ""
        } ${isScrolled ? "reviewEventDetailsPage-fixedHeader-fixed" : ""}`}
      >
        <div className="flex gap-12 items-center">
          <img
            className="selectRestaurant-main-info-backButton"
            src={arrowLeft}
            alt=""
            onClick={() => {
              if (pageView === "attendee") {
                setPageView("organizer");
              } else {
                navigate(
                  `/events?filter=${isOrganizer ? "organizer" : "attendee"}`
                );
              }
            }}
          />
          <h3
            className={`xs font-semibold ${
              pageView === "attendee" ? "color-gray-25" : "color-gray-900"
            }`}
          >
            {pageView === "attendee"
              ? "Back to organizer view"
              : "Back to events"}
          </h3>
        </div>
        {pageView === "organizer" ? (
          <img
            src={closeIcon}
            alt=""
            onClick={() => navigate("/events?filter=organizer")}
          />
        ) : null}
      </div>
      <div
        className="
          eventDetails_main-container eventDetails_main-container-noMarginTop 
          eventDetails_main-container-noMaxWidth eventDetails_main-container-withoutBorder 
           px-12 border-box py-12
        "
        style={{
          marginTop: sizeIsDesktop ? undefined : isScrolled ? "90px" : "112px",
          height: sizeIsDesktop ? "" : "100%",
          borderRadius: sizeIsDesktop ? undefined : "0",
        }}
      >
        <div
          className="eventDetails_main eventDetails_main-bgTransparent reviewEventDetailsPage paddingNone"
          onScroll={scrollFunction}
          id="scrolledInfo"
        >
          <LogoRepeat
            color={
              isEventHaveAlreadyPassed
                ? "pastEvent"
                : eventLogoColor
                ? eventLogoColor
                : undefined
            }
            image={
              !eventLogo
                ? pathIcon
                : getLogoRepeatImage({
                    selectedColor: isEventHaveAlreadyPassed
                      ? "pastEvent"
                      : eventLogoColor,
                    selectedPattern: eventLogo,
                  })
            }
            size={"lg"}
            position="absolute"
            positionTop={sizeIsDesktop ? "13%" : "0"}
          />
          <div className="reviewEventDetailsPage-main-content">
            <div
              className="flex flex-column gap-20"
              style={{ width: sizeIsDesktop ? "65%" : "100%" }}
            >
              <header className="reviewEventDetailsPage-header">
                {isFetching ? null : (
                  <>
                    <EventReviewBlockV2
                      iconIsEditable={isOrganizer}
                      withoutButton
                      slug=""
                      description={event?.description || ""}
                      timeDifference={event.totalDuration}
                      color={
                        +new Date(event.startTime).valueOf() <
                        +new Date().valueOf()
                          ? "pastEvent"
                          : eventLogoColor
                      }
                      pattern={eventLogo}
                      key={event.slug}
                      onClick={() =>
                        isOrganizer && setIsEditLogoModalOpen(true)
                      }
                      name={event.name}
                      date={moment(event.startDate).format("ll")}
                      location={event.location}
                      startTime={
                        getEventTime(event!.startTime, event.timezoneName)
                          ?.name || ""
                      }
                      endTime={
                        event.endTime
                          ? getEventTime(event!.endTime, event.timezoneName)
                              ?.name
                          : undefined
                      }
                    />
                  </>
                )}
              </header>
              {isFetching ? (
                <div className="flex fullHeight fullWidth items-center justifyCenter">
                  <Loader size="xl" />
                </div>
              ) : (
                <main className="reviewEventDetailsPage-main">
                  <div className={`eventDetails_main-content gap-32`}>
                    {isOrganizer && pageView === "organizer" ? (
                      <div className={`fullWidth flex items-center gap-16`}>
                        <div className="reviewEventDetailsPage-main-attendingInfoBlock">
                          <div className="reviewEventDetailsPage-main-attendingInfoBlock-header">
                            <p className="reviewEventDetailsPage-main-attendingInfoBlock-header-label">
                              Attending
                            </p>
                            <img
                              src={usersPlusIcon}
                              alt=""
                              className="reviewEventDetailsPage-main-attendingInfoBlock-header-img"
                            />
                          </div>
                          <h3 className="reviewEventDetailsPage-main-attendingInfoBlock-title">
                            {
                              event.attendees.filter(
                                (attendee) => attendee.rsvp === AttendeeRSVP.Yes
                              ).length
                            }
                          </h3>
                        </div>
                        <div className="reviewEventDetailsPage-main-attendingInfoBlock">
                          <div className="reviewEventDetailsPage-main-attendingInfoBlock-header">
                            <p className="reviewEventDetailsPage-main-attendingInfoBlock-header-label">
                              Not responded
                            </p>
                            <img
                              src={alertTriangle}
                              alt=""
                              className="reviewEventDetailsPage-main-attendingInfoBlock-header-img"
                            />
                          </div>
                          <h3 className="reviewEventDetailsPage-main-attendingInfoBlock-title">
                            {
                              event.attendees.filter(
                                (attendee) => attendee.rsvp === null
                              ).length
                            }
                          </h3>
                        </div>
                        <div className="reviewEventDetailsPage-main-attendingInfoBlock">
                          <div className="reviewEventDetailsPage-main-attendingInfoBlock-header">
                            <p className="reviewEventDetailsPage-main-attendingInfoBlock-header-label">
                              Not going
                            </p>
                            <img
                              src={userXIcon}
                              alt=""
                              className="reviewEventDetailsPage-main-attendingInfoBlock-header-img"
                            />
                          </div>
                          <h3 className="reviewEventDetailsPage-main-attendingInfoBlock-title">
                            {
                              event.attendees.filter(
                                (attendee) => attendee.rsvp === AttendeeRSVP.No
                              ).length
                            }
                          </h3>
                        </div>
                      </div>
                    ) : null}
                    {event?.agenda ? (
                      <ReviewAgendaSection
                        agenda={
                          editTime(
                            event.agenda
                              .sort((it1, it2) => it1.index - it2.index)
                              .map((item, index) => ({
                                id: `${item.id}`,
                                icon:
                                  item.type === AgendaBlockType.Break
                                    ? clockPlusIcon
                                    : item.type === AgendaBlockType.Meal
                                    ? happyFaceIcon
                                    : presentationIcon,
                                name: item.name,
                                description: item.description,
                                duration: durations.find(
                                  (duration) => duration.value === item.duration
                                ) || {
                                  id: 1,
                                  name: `${item.duration} min`,
                                  value: item.duration,
                                },
                                startTime:
                                  getEventTime(
                                    event!.startTime,
                                    event.timezoneName
                                  )?.name || "",
                                endTime: event.endTime
                                  ? getEventTime(
                                      event!.endTime,
                                      event.timezoneName
                                    )?.name ?? ""
                                  : "",
                                type: item.type,
                                index,
                                mealBlockId: item.mealBlockId,
                                onClick: async () => {
                                  if (item.mealBlockId) {
                                    const mealBlock = await fetchMeals({
                                      id: item.mealBlockId!,
                                      token,
                                    });

                                    if (
                                      mealBlock?.mealBlock?.restaurantId &&
                                      mealBlock.mealBlock.choice ===
                                        MealBlockChoice.PreSelected &&
                                      !isOrganizer
                                    ) {
                                      fetchRestaurantMenu({
                                        id: mealBlock.mealBlock.restaurantId,
                                        token,
                                        isPickup:
                                          mealBlock.mealBlock.type ===
                                          MealBlockType.Pickup,
                                      }).then((menu) => {
                                        if (menu) {
                                          const preSelectedMeals: {
                                            name: string;
                                            subcategory_id: string;
                                            menu_item_list: {
                                              count: number;
                                              name: string;
                                              price: number;
                                              qty_available: null;
                                              unit_size: null;
                                              unit_of_measurement: string;
                                              description: string;
                                              is_available: boolean;
                                              image: string;
                                              customizations: [
                                                {
                                                  name: string;
                                                  min_choice_options: number;
                                                  max_choice_options: number;
                                                  options: [
                                                    {
                                                      name: string;
                                                      price: number;
                                                      customizations: any[];
                                                      min_qty: number;
                                                      max_qty: number;
                                                      conditional_price: {};
                                                      formatted_price: string;
                                                      default_qty: number;
                                                      option_id: string;
                                                    }
                                                  ];
                                                  customization_id: string;
                                                }
                                              ];
                                              min_price: number;
                                              original_price: number;
                                              formatted_price: string;
                                              attributes: [];
                                              product_id: string;
                                              thumbnail_image: string;
                                              should_fetch_customizations: boolean;
                                              supports_image_scaling: boolean;
                                            }[];
                                          }[] = [];

                                          const mealsIds =
                                            mealBlock.mealBlock
                                              .preSelectedMealsIds;

                                          if (mealsIds?.length) {
                                            menu.map((category) =>
                                              category.menu_item_list.map(
                                                (product) => {
                                                  if (
                                                    mealsIds.includes(
                                                      product.product_id
                                                    )
                                                  ) {
                                                    const preSelectedCategories =
                                                      preSelectedMeals.map(
                                                        (item) =>
                                                          item.subcategory_id
                                                      );
                                                    if (
                                                      preSelectedCategories.includes(
                                                        category.subcategory_id
                                                      )
                                                    ) {
                                                      const currentCategory =
                                                        preSelectedMeals.find(
                                                          (item) =>
                                                            item.subcategory_id ===
                                                            category.subcategory_id
                                                        );

                                                      if (currentCategory) {
                                                        preSelectedMeals[
                                                          preSelectedMeals.findIndex(
                                                            (category) =>
                                                              category.subcategory_id ===
                                                              currentCategory.subcategory_id
                                                          )
                                                        ] = {
                                                          ...currentCategory,
                                                          menu_item_list: [
                                                            ...currentCategory.menu_item_list,
                                                            {
                                                              count: 0,
                                                              ...product,
                                                            },
                                                          ],
                                                        };
                                                      }
                                                    } else {
                                                      preSelectedMeals.push({
                                                        ...category,
                                                        menu_item_list: [
                                                          {
                                                            count: 0,
                                                            ...product,
                                                          },
                                                        ],
                                                      });
                                                    }
                                                  }
                                                }
                                              )
                                            );

                                            setEventAgendaMealBlock(
                                              preSelectedMeals
                                            );
                                          }
                                        }
                                      });

                                      const restaurant =
                                        await fetchSpecificRestaurant({
                                          id: mealBlock.mealBlock.restaurantId,
                                          token,
                                        });

                                      setSelectedRestaurant(
                                        restaurant
                                          ? {
                                              id: restaurant._id,
                                              name: restaurant.name,
                                              logo: restaurant.logo_photos[0],
                                              status: restaurant.is_open
                                                ? "Open"
                                                : "Close",
                                              address:
                                                restaurant.address.street_addr,
                                              distance: restaurant.miles,
                                              cuisines: restaurant.cuisines,
                                            }
                                          : null
                                      );

                                      setMealBlockId(item.mealBlockId);
                                      setSelectMealsModalIsOpen(true);
                                    }
                                  }
                                },
                              })),
                            getEventTime(event!.startTime, event.timezoneName)
                              ?.name ?? ""
                          )!
                        }
                      />
                    ) : null}
                  </div>
                </main>
              )}
            </div>
            <div
              className={`flex flex-column gap-16 positionRelative fullWidth ${
                sizeIsDesktop ? "max-w-320" : ""
              }`}
            >
              {pageView === "organizer" ? (
                <div className="reviewEventDetailsPage-actions">
                  <h3 className="text-lg font-semibold color-gray-900">
                    Event actions
                  </h3>
                  {isOrganizer ? (
                    <div className="flex flex-column gap-8">
                      {event.isPublished ? (
                        <Button
                          size={"md"}
                          hierarchy={"newDesign-secondary"}
                          buttonType={"regular"}
                          fullWidth
                          contentCenter
                          newDesignPaddingNone
                          onClick={() => {
                            setPageView("attendee");
                          }}
                        >
                          View as attendee
                        </Button>
                      ) : null}
                      <div className="flex items-center gap-4">
                        <Button
                          size={"md"}
                          hierarchy={"errorLinkGray"}
                          buttonType={"regular"}
                          fullWidth
                          contentCenter
                          newDesignPaddingNone
                          onClick={() => {
                            setNotificationModalIsOpen({
                              name: "Delete event",
                              description:
                                "Are you sure you want to delete your event? This action can’t be undone.",
                              firstButtonName: "Cancel",
                              secondButtonName: "Delete",
                              onFirstButtonClick: () =>
                                setNotificationModalIsOpen(null),
                              onSecondButtonClick: () => {
                                deleteEvent({ id: event.id, token }).then(
                                  () => {
                                    setNotification({
                                      title: "Event has been deleted",
                                      description:
                                        "Your event has been successfully deleted.",
                                      isError: false,
                                    });
                                    navigate("/events?filter=organizer");
                                  }
                                );
                              },
                            });
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          size={"sm"}
                          hierarchy={
                            event.isPublished
                              ? "newDesign-primary"
                              : "newDesign-secondary"
                          }
                          buttonType={"regular"}
                          fullWidth
                          contentCenter
                          newDesignPaddingNone
                          onClick={() => {
                            navigate(`/edit/${event.slug}/details`);
                          }}
                        >
                          Edit
                        </Button>
                        {!event.isPublished && event.agenda.length ? (
                          <Button
                            size={"sm"}
                            hierarchy={"newDesign-primary"}
                            buttonType={"regular"}
                            fullWidth
                            contentCenter
                            newDesignPaddingNone
                            asyncOnClick={async () => {
                              if (event.agenda.length) {
                                await publishEvent({
                                  token,
                                  eventId: event.id,
                                });
                                setNotification({
                                  title: "Event has been updated",
                                  description:
                                    "Your event has been successfully updated and your invitees will be notified.",
                                  isError: false,
                                });
                                navigate("/events?filter=organizer");
                              }
                            }}
                          >
                            Publish
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center gap-16">
                      <RSVP
                        selectedRSVP={selectedRSVP}
                        setSelectedRSVP={setSelectedRSVP}
                        onRSVPSelect={onRSVPSelect}
                      />
                      {todoItems?.todoItems &&
                      todoItems.todoItems.find(
                        (todo) =>
                          todo.type === TodoItemType.MealSelection &&
                          !todo.isCompleted
                      ) ? (
                        <Button
                          size={"md"}
                          hierarchy={"newDesign-secondary"}
                          buttonType={"regular"}
                          contentCenter
                          newDesignPaddingNone
                          disabled={
                            !todoItems?.todoItems.find(
                              (todo) => todo.type === TodoItemType.MealSelection
                            )
                          }
                          asyncOnClick={async () => {
                            const todo = todoItems?.todoItems.find(
                              (todo) => todo.type === TodoItemType.MealSelection
                            );

                            if (!todo) return;

                            const mealBlock = await fetchMeals({
                              id: todo.mealBlockId!,
                              token,
                            });

                            if (mealBlock?.mealBlock?.restaurantId) {
                              fetchRestaurantMenu({
                                id: mealBlock.mealBlock.restaurantId,
                                token,
                                isPickup:
                                  mealBlock.mealBlock.type ===
                                  MealBlockType.Pickup,
                              }).then((menu) => {
                                if (menu) {
                                  const preSelectedMeals: {
                                    name: string;
                                    subcategory_id: string;
                                    menu_item_list: {
                                      count: number;
                                      name: string;
                                      price: number;
                                      qty_available: null;
                                      unit_size: null;
                                      unit_of_measurement: string;
                                      description: string;
                                      is_available: boolean;
                                      image: string;
                                      customizations: [
                                        {
                                          name: string;
                                          min_choice_options: number;
                                          max_choice_options: number;
                                          options: [
                                            {
                                              name: string;
                                              price: number;
                                              customizations: any[];
                                              min_qty: number;
                                              max_qty: number;
                                              conditional_price: {};
                                              formatted_price: string;
                                              default_qty: number;
                                              option_id: string;
                                            }
                                          ];
                                          customization_id: string;
                                        }
                                      ];
                                      min_price: number;
                                      original_price: number;
                                      formatted_price: string;
                                      attributes: [];
                                      product_id: string;
                                      thumbnail_image: string;
                                      should_fetch_customizations: boolean;
                                      supports_image_scaling: boolean;
                                    }[];
                                  }[] = [];

                                  const mealsIds =
                                    mealBlock.mealBlock.preSelectedMealsIds;

                                  if (mealsIds?.length) {
                                    menu.map((category) =>
                                      category.menu_item_list.map((product) => {
                                        if (
                                          mealsIds.includes(product.product_id)
                                        ) {
                                          const preSelectedCategories =
                                            preSelectedMeals.map(
                                              (item) => item.subcategory_id
                                            );
                                          if (
                                            preSelectedCategories.includes(
                                              category.subcategory_id
                                            )
                                          ) {
                                            const currentCategory =
                                              preSelectedMeals.find(
                                                (item) =>
                                                  item.subcategory_id ===
                                                  category.subcategory_id
                                              );

                                            if (currentCategory) {
                                              preSelectedMeals[
                                                preSelectedMeals.findIndex(
                                                  (category) =>
                                                    category.subcategory_id ===
                                                    currentCategory.subcategory_id
                                                )
                                              ] = {
                                                ...currentCategory,
                                                menu_item_list: [
                                                  ...currentCategory.menu_item_list,
                                                  {
                                                    count: 0,
                                                    ...product,
                                                  },
                                                ],
                                              };
                                            }
                                          } else {
                                            preSelectedMeals.push({
                                              ...category,
                                              menu_item_list: [
                                                {
                                                  count: 0,
                                                  ...product,
                                                },
                                              ],
                                            });
                                          }
                                        }
                                      })
                                    );

                                    setEventAgendaMealBlock(preSelectedMeals);
                                  }
                                }
                              });

                              const restaurant = await fetchSpecificRestaurant({
                                id: mealBlock.mealBlock.restaurantId,
                                token,
                              });

                              setSelectedRestaurant(
                                restaurant
                                  ? {
                                      id: restaurant._id,
                                      name: restaurant.name,
                                      logo: restaurant.logo_photos[0],
                                      status: restaurant.is_open
                                        ? "Open"
                                        : "Close",
                                      address: restaurant.address.street_addr,
                                      distance: restaurant.miles,
                                      cuisines: restaurant.cuisines,
                                    }
                                  : null
                              );

                              setMealBlockId(todo.mealBlockId);
                              setSelectMealsModalIsOpen(true);
                            }

                            await markTodoAsCompleted(todo, token);
                          }}
                        >
                          Order food
                        </Button>
                      ) : null}
                    </div>
                  )}
                </div>
              ) : null}
              {pageView === "attendee" || !isOrganizer ? (
                <div className="reviewEventDetailsPage-actions">
                  <h3 className="text-lg font-semibold color-gray-900">
                    Get directions
                  </h3>
                  <div className="reviewEventDetailsPage-actions-direction">
                    <div className="reviewEventDetailsPage-actions-direction-directionMap">
                      <MapboxMap
                        agendaBlockId={0}
                        location={event.location}
                        lng={
                          event.locationLongitude ? +event.locationLongitude : 0
                        }
                        lat={
                          event.locationLatitude ? +event.locationLatitude : 0
                        }
                        zoom={11.3}
                        withoutControls
                        fullHeight
                      />
                    </div>
                    <LocationButton
                      text="sm"
                      withoutArrow
                      name="Get direction to the event"
                      lat={event.locationLatitude}
                      lng={event.locationLongitude}
                    />
                  </div>
                </div>
              ) : null}
              <EventInfoPreview
                title={"To do's"}
                emptyStateTilte={"No tasks to do"}
                emptyStateImg={checkCircleIcon}
                minHeight="min-content"
                count={
                  todoItems?.todoItems.filter((todo) => !todo.isCompleted)
                    .length || 0
                }
                items={
                  todoItems?.todoItems
                    .filter((todo) => !todo.isCompleted)
                    .map((todo) => (
                      <TodoPreview
                        toDo={todo}
                        onTodoClick={
                          todo.type === TodoItemType.MealSelection
                            ? async () => {
                                const mealBlock = await fetchMeals({
                                  id: todo.mealBlockId!,
                                  token,
                                });

                                if (mealBlock?.mealBlock?.restaurantId) {
                                  fetchRestaurantMenu({
                                    id: mealBlock.mealBlock.restaurantId,
                                    token,
                                    isPickup:
                                      mealBlock.mealBlock.type ===
                                      MealBlockType.Pickup,
                                  }).then((menu) => {
                                    if (menu) {
                                      const preSelectedMeals: {
                                        name: string;
                                        subcategory_id: string;
                                        menu_item_list: {
                                          count: number;
                                          name: string;
                                          price: number;
                                          qty_available: null;
                                          unit_size: null;
                                          unit_of_measurement: string;
                                          description: string;
                                          is_available: boolean;
                                          image: string;
                                          customizations: [
                                            {
                                              name: string;
                                              min_choice_options: number;
                                              max_choice_options: number;
                                              options: [
                                                {
                                                  name: string;
                                                  price: number;
                                                  customizations: any[];
                                                  min_qty: number;
                                                  max_qty: number;
                                                  conditional_price: {};
                                                  formatted_price: string;
                                                  default_qty: number;
                                                  option_id: string;
                                                }
                                              ];
                                              customization_id: string;
                                            }
                                          ];
                                          min_price: number;
                                          original_price: number;
                                          formatted_price: string;
                                          attributes: [];
                                          product_id: string;
                                          thumbnail_image: string;
                                          should_fetch_customizations: boolean;
                                          supports_image_scaling: boolean;
                                        }[];
                                      }[] = [];

                                      const mealsIds =
                                        mealBlock.mealBlock.preSelectedMealsIds;

                                      if (mealsIds?.length) {
                                        menu.map((category) =>
                                          category.menu_item_list.map(
                                            (product) => {
                                              if (
                                                mealsIds.includes(
                                                  product.product_id
                                                )
                                              ) {
                                                const preSelectedCategories =
                                                  preSelectedMeals.map(
                                                    (item) =>
                                                      item.subcategory_id
                                                  );
                                                if (
                                                  preSelectedCategories.includes(
                                                    category.subcategory_id
                                                  )
                                                ) {
                                                  const currentCategory =
                                                    preSelectedMeals.find(
                                                      (item) =>
                                                        item.subcategory_id ===
                                                        category.subcategory_id
                                                    );

                                                  if (currentCategory) {
                                                    preSelectedMeals[
                                                      preSelectedMeals.findIndex(
                                                        (category) =>
                                                          category.subcategory_id ===
                                                          currentCategory.subcategory_id
                                                      )
                                                    ] = {
                                                      ...currentCategory,
                                                      menu_item_list: [
                                                        ...currentCategory.menu_item_list,
                                                        {
                                                          count: 0,
                                                          ...product,
                                                        },
                                                      ],
                                                    };
                                                  }
                                                } else {
                                                  preSelectedMeals.push({
                                                    ...category,
                                                    menu_item_list: [
                                                      {
                                                        count: 0,
                                                        ...product,
                                                      },
                                                    ],
                                                  });
                                                }
                                              }
                                            }
                                          )
                                        );

                                        setEventAgendaMealBlock(
                                          preSelectedMeals
                                        );
                                      }
                                    }
                                  });

                                  const restaurant =
                                    await fetchSpecificRestaurant({
                                      id: mealBlock.mealBlock.restaurantId,
                                      token,
                                    });

                                  setSelectedRestaurant(
                                    restaurant
                                      ? {
                                          id: restaurant._id,
                                          name: restaurant.name,
                                          logo: restaurant.logo_photos[0],
                                          status: restaurant.is_open
                                            ? "Open"
                                            : "Close",
                                          address:
                                            restaurant.address.street_addr,
                                          distance: restaurant.miles,
                                          cuisines: restaurant.cuisines,
                                        }
                                      : null
                                  );

                                  setMealBlockId(todo.mealBlockId);
                                  setSelectMealsModalIsOpen(true);
                                }

                                await markTodoAsCompleted(todo, token);
                              }
                            : todo.type === TodoItemType.MealOrder
                            ? async () => {
                                setMealBlockIdForOrder(todo.mealBlockId!);
                                setCartId(
                                  todo.mealBlock?.cartId
                                    ? todo.mealBlock?.cartId
                                    : null
                                );
                                setOrderMealsModalIsOpen(true);
                                await markTodoAsCompleted(todo, token);
                              }
                            : todo.type === TodoItemType.RespondToInvite
                            ? async (item: AttendeeRSVP | undefined) => {
                                if (item) {
                                  await onRSVPSelect(item);
                                  await markTodoAsCompleted(todo, token);
                                }
                              }
                            : async () => {}
                        }
                        name={
                          todo.type === TodoItemType.RespondToInvite
                            ? "Event invite"
                            : todo.type === TodoItemType.MealOrder
                            ? "Meal order"
                            : todo.type === TodoItemType.MealSelection
                            ? "Meal selection"
                            : todo.type === TodoItemType.SubmitEvent
                            ? "Submit event"
                            : todo.type === TodoItemType.ReviewDocument
                            ? "Review document"
                            : ""
                        }
                        description={
                          todo.type === TodoItemType.RespondToInvite
                            ? "Respond to the event invite"
                            : todo.type === TodoItemType.MealOrder
                            ? `Order meal for ${todo.event.name}`
                            : todo.type === TodoItemType.MealSelection
                            ? `Select meal for ${todo.event.name}`
                            : todo.type === TodoItemType.SubmitEvent
                            ? "Your event hasn’t been submitted."
                            : todo.type === TodoItemType.ReviewDocument
                            ? "Please, review the document"
                            : ""
                        }
                      />
                    )) || []
                }
              />
              <div className="positionRelative">
                <EventInfoPreview
                  title={"Attachments"}
                  emptyStateTilte={"No attachments"}
                  emptyStateImg={fileAttachmentIcon}
                  items={
                    uploadedDocuments
                      ?.map((item) => (
                        <Document
                          key={item.document.key}
                          url={
                            item.url
                              ? item.url
                              : item.document.url
                              ? item.document.url
                              : ""
                          }
                          img={pdfIcon}
                          name={item.document.originalFileName}
                          type={item.document.url ? "link" : "document"}
                          id={item.document.id}
                          eventId={event.id}
                          onEdit={() => {
                            setIsAttachDocumentModalOpen(true);
                            setSelectedFileModal({
                              id: 1,
                              name: "Add links",
                              img: linkIcon,
                            });
                            setCurrentEditableLink({
                              url: item.document.url || item.url || "",
                              id: item.document.id,
                            });
                            setUploadedDocuments((prevState) =>
                              prevState.filter(
                                (it) => it.document.id !== item.document.id
                              )
                            );
                          }}
                          onDelete={() => {
                            setUploadedDocuments((prevState) =>
                              prevState.filter(
                                (it) => it.document.id !== item.document.id
                              )
                            );
                          }}
                        />
                      ))
                      .concat(
                        documents.map((item) => (
                          <Document
                            key={item.url}
                            url={item.url}
                            img={pdfIcon}
                            name={item.name}
                            type={"document"}
                            id={item.id}
                            eventId={event.id}
                            onDelete={() => {
                              setDocuments((prevState) =>
                                prevState.filter((it) => it.id !== item.id)
                              );
                            }}
                          />
                        ))
                      )
                      .concat(
                        links.map((item) => (
                          <Document
                            key={item.url}
                            url={item.url}
                            img={""}
                            name={item.url}
                            type={"link"}
                            id={item.id}
                            eventId={event.id}
                            onEdit={() => {
                              setLinks((prevState) =>
                                prevState.filter((it) => it.id !== item.id)
                              );
                              setIsAttachDocumentModalOpen(true);
                              setSelectedFileModal({
                                id: 1,
                                name: "Add links",
                                img: linkIcon,
                              });
                              setCurrentEditableLink(item);
                            }}
                            onDelete={() => {
                              setLinks((prevState) =>
                                prevState.filter((it) => it.id !== item.id)
                              );
                            }}
                          />
                        ))
                      ) || []
                  }
                  addButtonAction={() => {
                    setIsSelectFileModalOpen((prevState) => !prevState);
                    setIsAttachDocumentModalOpen(true);
                  }}
                />
                {isSelectFileModalOpen ? (
                  <DropdownMenu
                    content={[
                      { id: 1, name: "Add links", img: linkIcon },
                      { id: 2, name: "Upload files", img: uploadCloudIcon },
                    ]}
                    selectedItem={selectedFileModal}
                    setSelectedItem={setSelectedFileModal}
                    setShow={() => {
                      setIsSelectFileModalOpen(false);
                    }}
                    size="max-content"
                    positionRight={"7px"}
                    positionTop={"37%"}
                  />
                ) : null}
              </div>
              {!!todoItems?.todoItems.filter((todo) => todo.isCompleted)
                .length ? (
                <div
                  className="homePage-content-todos homePage-content-todos-min-w-200 bgWhite z100"
                  style={{ height: "auto", minHeight: "min-content" }}
                >
                  <div className="homePage-content-todos-header">
                    <div className="homePage-content-todos-header-topic">
                      <h3 className="homePage-content-todos-header-topic-title">
                        Completed tasks
                      </h3>
                    </div>
                    <img src="" alt="" />
                  </div>
                  {todoItems?.todoItems.filter((todo) => todo.isCompleted)
                    .length ? (
                    <div className="homePage-content-todos-content">
                      {todoItems.todoItems
                        .filter((todo) => todo.isCompleted)
                        .map((todo) => (
                          <div
                            className="flex items-center gap-4"
                            key={todo.id}
                          >
                            <img
                              src={checkSuccessIcon}
                              alt=""
                              width={16}
                              height={16}
                            />
                            <p className="text-xs font-semibold color-gray-950">
                              {todo.type === TodoItemType.RespondToInvite
                                ? "Respond to the event invite"
                                : todo.type === TodoItemType.MealOrder
                                ? `Order meal for ${todo.event.name}`
                                : todo.type === TodoItemType.MealSelection
                                ? `Select meal for ${todo.event.name}`
                                : todo.type === TodoItemType.SubmitEvent
                                ? "Your event hasn’t been submitted."
                                : todo.type === TodoItemType.ReviewDocument
                                ? "Please, review the document"
                                : ""}
                            </p>
                          </div>
                        ))}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {!sizeIsDesktop && isOrganizer && pageView === "organizer" ? (
        <div className="reviewEventDetailsPage-footer">
          <Button
            size={"md"}
            hierarchy={"newDesign-secondary"}
            buttonType={"regular"}
            fullWidth
            contentCenter
            newDesignPaddingNone
            onClick={() => {
              setPageView("attendee");
            }}
          >
            View as attendee
          </Button>
        </div>
      ) : null}
    </div>
  );
}
