import React from "react";
import Counter from "../../Counter/Counter";
import fileCheckIcon from "../../../assets/images/file-check-02.png";

interface Props {
  title: string;
  notes: string;
  price: string;
  onIcnrease: () => void;
  onDescrease: () => void;
  count: number;
  onNotesClick: (mealId: string) => void;
  id: string;
}

export default function SimpleAddMeal({
  title,
  notes,
  price,
  onDescrease,
  onIcnrease,
  count,
  onNotesClick,
  id,
}: Props) {
  return (
    <div className={`restaurantDetails-info-restaurant`}>
      <div className="restaurantDetails-info-restaurant-info gap-16 fullWidth">
        <div className="flex fullWidth items-start flex-column gap-4">
          <div className="fullWidth flex spaceBetween items-center">
            <h3 className="restaurantDetails-info-restaurant-info-name">
              {title}
            </h3>
            <p className="text-lg font-semibold color-gray-950">X{count}</p>
          </div>
          {notes.length ? (
            <p className="text-sm font-regular color-gray-900">
              Notes: {notes}
            </p>
          ) : null}
        </div>
        <Counter
          onIncrease={onIcnrease}
          onDescrease={onDescrease}
          count={count}
          onInputChange={() => {}}
        />
        <div className="flex gap-8 items-center fullWidth spaceBetween">
          <div className="restaurantDetails-info-restaurant-notesButton">
            <img src={fileCheckIcon} alt="" />
            <p
              className="text-xs font-semibold color-gray-950"
              onClick={() => onNotesClick(id)}
            >
              Notes
            </p>
          </div>
          <p className="text-lg font-semibold color-gray-950">{price}</p>
        </div>
      </div>
    </div>
  );
}
