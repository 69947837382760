import { useWindowSize } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import EventDetails from "./components/EventDetails";
import EventAgenda from "./components/EventAgenda";
import { EventInfoContext } from "../../utilities/eventInfoContext";
import EventInviteTeam from "./components/EventInviteTeam";
import EventReviewAndSubmit from "./components/EventReviewAndSubmit";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { supabase } from "../../utilities/supabaseClient";
import { AgendaBlockType } from "../../types/agendaBlockType";
import { AttendeeRSVP } from "../../types/attendeeRSVP";
import { createPortal } from "react-dom";
import ModalNotification from "../../components/Modal/ModalNotification";

interface Props {
  type: "Details" | "Agenda" | "InviteTeam" | "Review";
  isEdit?: boolean;
}

export default function EventPage({ type, isEdit }: Props) {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [selectedStartTime, setSelectedStartTime] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [selectedEndTime, setSelectedEndTime] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [eventDate, setEventDate] = useState<moment.Moment | null>(null);
  const [eventName, setEventName] = useState("");
  const [eventIds, setEventIds] = useState<{
    eventId: number;
    eventDayId: number;
  } | null>(null);
  const [eventDescription, setEventDescription] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<{
    id: number;
    name: string;
    supportText?: string | undefined;
    lng?: string;
    lat?: string;
  } | null>(null);
  const [locationNotes, setLocationNotes] = useState("");
  const [selectedZone, setSelectedZone] = useState<{
    id: number;
    name: string;
    timezone?: string;
  } | null>(null);
  const [agenda, setAgenda] = useState<
    {
      id: string;
      icon: string;
      name: string;
      description: string;
      duration: {
        id: number;
        name: string;
        value?: number | undefined;
      };
      startTime: string;
      endTime: string;
      type: AgendaBlockType;
      mealBlockId?: number;
      index: number;
    }[]
  >([]);
  const [invitedAttendees, setInvitedAttendees] = useState<{
    attendees: {
      id: number;
      networkMemberId: number;
      status: "added";
      rsvp: AttendeeRSVP | null;
      networkMember: {
        id: number;
        fullName: string;
        email: string;
        phone: string;
        businessName: string;
        address: string;
        title: string;
        userId: null | number;
        accountId: number;
        createdAt: string;
        updatedAt: string;
        user: {
          accountId: number;
          createdAt: string;
          email: string;
          fullName: string;
          id: number;
          phone?: string | null;
          status: string;
          supabaseId: string;
          updatedAt: string;
        } | null;
      };
    }[];
    count: number;
  }>({ attendees: [], count: 0 });
  const [attachedFilesCount, setAttachedFilesCount] = useState(0);
  const [currentLastStep, setCurrentLastStep] = useState(1);
  const [slug, setSlug] = useState("");
  const [modalNotificationIsOpen, setModalNotificationIsOpen] = useState<{
    name: string;
    description: string;
    firstButtonName: string;
    secondButtonName: string;
    onFirstButtonClick: () => void;
    onSecondButtonClick: () => void;
  } | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const token = supabase()
      .auth.getSession()
      .then((response) => {
        if (!response.data.session?.access_token) {
          navigate("/login");
        }
      });
  }, []);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  return (
    <div
      className={`eventDetails_container ${
        type === "Agenda" ? "eventDetails_container-agenda" : ""
      }`}
    >
      {modalNotificationIsOpen
        ? createPortal(
            <ModalNotification
              setIsOpen={setModalNotificationIsOpen}
              name={modalNotificationIsOpen.name}
              description={modalNotificationIsOpen.description}
              onFirstButton={modalNotificationIsOpen.onFirstButtonClick}
              onSecondButton={modalNotificationIsOpen.onSecondButtonClick}
              firstButtonName={modalNotificationIsOpen.firstButtonName}
              secondButtonName={modalNotificationIsOpen.secondButtonName}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {/* {sizeIsDesktop
        ? <SideBarNavigationNew
            hierarchy='primary'
            currentItem="Home"
          />
        : null
      } */}
      <div className="eventDetails_main-container eventDetails_main-container-noMaxWidth">
        <EventInfoContext.Provider
          value={{
            eventIds,
            agenda: agenda,
            endTime: selectedEndTime ? selectedEndTime : null,
            eventDate: eventDate,
            eventDescription: eventDescription,
            eventName: eventName,
            invitedAttendees: invitedAttendees,
            location: selectedLocation,
            locationNotes: locationNotes,
            startTime: selectedStartTime ? selectedStartTime : null,
            timeZone: selectedZone,
            attachedDocumentsCount: attachedFilesCount,
            currentLastStep,
            slug,
          }}
        >
          {type === "Details" ? (
            <EventDetails
              setEventIds={setEventIds}
              isDesktopSize={sizeIsDesktop}
              setSelectedStartTime={setSelectedStartTime}
              setSelectedEndTime={setSelectedEndTime}
              setFirstDate={setEventDate}
              setDescriptionInputValue={setEventDescription}
              setNameInputValue={setEventName}
              setSelectedLocation={setSelectedLocation}
              setLocationNotes={setLocationNotes}
              setSelectedZone={setSelectedZone}
              isEdit={isEdit}
              setCurrentLastStep={setCurrentLastStep}
              currentLastStep={currentLastStep}
              eventSlug={slug}
              setSlug={setSlug}
            />
          ) : null}

          {type === "Agenda" ? (
            <EventAgenda
              isDesktopSize={sizeIsDesktop}
              agenda={agenda}
              setAgenda={setAgenda}
              isEditActions={isEdit}
              setEventIds={setEventIds}
              setSelectedStartTime={setSelectedStartTime}
              setSelectedEndTime={setSelectedEndTime}
              setFirstDate={setEventDate}
              setDescriptionInputValue={setEventDescription}
              setNameInputValue={setEventName}
              setSelectedLocation={setSelectedLocation}
              setLocationNotes={setLocationNotes}
              setSelectedZone={setSelectedZone}
              setSlug={setSlug}
              setCurrentLastStep={setCurrentLastStep}
              currentLastStep={currentLastStep}
              eventSlug={slug}
            />
          ) : null}

          {type === "InviteTeam" ? (
            <EventInviteTeam
              isDesktopSize={sizeIsDesktop}
              invitedAttendees={invitedAttendees}
              setInvitedAttendees={setInvitedAttendees}
              eventId={eventIds!.eventId}
              isEdit={isEdit}
              setCurrentLastStep={setCurrentLastStep}
              currentLastStep={currentLastStep}
              eventSlug={slug}
              setEventIds={setEventIds}
              setSelectedStartTime={setSelectedStartTime}
              setSelectedEndTime={setSelectedEndTime}
              setFirstDate={setEventDate}
              setDescriptionInputValue={setEventDescription}
              setNameInputValue={setEventName}
              setSelectedLocation={setSelectedLocation}
              setLocationNotes={setLocationNotes}
              setSelectedZone={setSelectedZone}
              setSlug={setSlug}
            />
          ) : null}

          {type === "Review" ? (
            <EventReviewAndSubmit
              isDesktopSize={sizeIsDesktop}
              setInvitedAttendees={setInvitedAttendees}
              isEdit={isEdit}
              setAttachedDocumentsCount={setAttachedFilesCount}
              setAgenda={setAgenda}
              setEventIds={setEventIds}
              setSelectedStartTime={setSelectedStartTime}
              setSelectedEndTime={setSelectedEndTime}
              setFirstDate={setEventDate}
              setDescriptionInputValue={setEventDescription}
              setNameInputValue={setEventName}
              setSelectedLocation={setSelectedLocation}
              setLocationNotes={setLocationNotes}
              setSelectedZone={setSelectedZone}
              setCurrentLastStep={setCurrentLastStep}
              currentLastStep={currentLastStep}
              eventSlug={slug}
              setSlug={setSlug}
              invitedAttendees={invitedAttendees}
            />
          ) : null}
        </EventInfoContext.Provider>
      </div>
    </div>
  );
}
