import React, { useEffect, useState } from "react";
import { postLockOrders } from "../../httpQueries/http";
import closeIcon from "../../assets/images/x-close-white-lg.svg";
import Loader from "../Loader";

export default function PaymentCheckout({
  mealBlockId,
  alreadySettedCartId,
  setIsOpen,
}: {
  mealBlockId: number;
  alreadySettedCartId: string | null;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [cartId, setCartId] = useState(alreadySettedCartId);

  useEffect(() => {
    setIsLoading(true);

    postLockOrders({ id: mealBlockId, token })
      .then((res) => {
        setCartId(res!.cartId);
        setIsLoading(false);
      })
      .catch((e) => setIsLoading(false));
  }, [mealBlockId]);

  return (
    <div className="modalNotification">
      <img
        src={closeIcon}
        alt=""
        style={{
          position: "absolute",
          top: "8px",
          right: "10px",
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(false)}
      />
      {isLoading ? (
        <div className="fullWidth fullHeight flex items-center justifyCenter">
          <Loader size="xl" />
        </div>
      ) : (
        <iframe
          src={`https://sdk.mealme.ai/checkout?api=gatherwise-sandbox&cartId=${cartId}`}
          allow="geolocation; payment"
          title="Mealme Web SDK"
          style={{
            border: "none",
            height: "100vh",
            marginBottom: "0px",
            position: "relative",
            zIndex: "100",
            marginTop: "40px",
            width: "100%",
          }}
        ></iframe>
      )}
    </div>
  );
}
