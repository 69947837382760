import EventPage from "./pages/EventPage/EventPage";
import { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom';
import avatar from './assets/images/Avatar-olivia.svg'
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import SetNewPassword from "./pages/ForgotPassword/SetNewPassword";
import EventsPageV2 from "./pages/EventsPageV2/EventsPageV2";
import Settings from "./pages/Settings/Settings";
import { Support } from "./pages/Support/Support";
import ReviewEventDetailsView from "./pages/ReviewEventsDetailsPage/ReviewEventDetailsView";
import Contacts from "./pages/Contacts/Contacts";
import {  fetchMyData, registerUser } from "./httpQueries/http";
import { useDispatch } from "./store/hooks";
import { currentUserActions } from "./store/currentUser";
import { supabase } from "./utilities/supabaseClient";
import NotFound from "./pages/NotFound/NotFound";
import NewCategoryDropdown from "./components/Dropdown/NewCategoryDropdown";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import TeamsDropdown from "./components/Dropdown/teamsDropdown";
import Loader from "./components/Loader";
import HomePage from "./pages/Home/HomePage";
import LoginByRSVP from "./pages/Login/LoginByRSVP";

function App() {
  let token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [refetchEvents, setRefetchEvents] = useState(false);
  const signUpByGoogleWorks = sessionStorage.getItem('googleSignUpWorks');

  useEffect(() => {
    if (!signUpByGoogleWorks) {

      supabase().auth.getSession().then(response => {
          if (response.data.session?.access_token) {
            if (token !== response.data.session?.access_token || !token) {
              token = response.data.session?.access_token;
              localStorage.setItem('token', token);
              localStorage.setItem('lastTokenSetTime', new Date().toISOString());
            }
            if (token) {
              fetchMyData({ token }).then(
                (response) => {
                  if (response) {
                    dispatch(currentUserActions.setCurrentUser({fullName: response.fullName, email: response.email, profileImage: avatar, completeTutorial: response.isTutorialCompleted}));
                  }
                  setRefetchEvents(true);
                }
              )
            }
          }
      })
    }
  }, []);

  useEffect(() => {
    const session = supabase().auth.getSession();
    const {
      data: { subscription },
    } = supabase().auth.onAuthStateChange(async (event, session) => {
      if (signUpByGoogleWorks) {
        if (session?.user.identities) {
          await registerUser({ user: {
            fullName: session.user.identities![0].identity_data!.full_name || session.user.identities![1].identity_data!.full_name,
            email: session.user.identities![0].identity_data!.email,
            id: session.user.id,
          }});
  
          fetchMyData({ token: session.access_token }).then(
            (response) => {
              if (response?.accountId) {
                dispatch(currentUserActions.setCurrentUser({fullName: response.fullName, email: response.email, profileImage: avatar, completeTutorial: response.isTutorialCompleted}));
                setRefetchEvents(true);
              }
            }
          );
          sessionStorage.removeItem('googleSignUpWorks');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [signUpByGoogleWorks]);
  
  return (
    <>
      {
        isLoading
          ? <div className="flex fullWidth items-center fullHeight justifyCenter">
              <Loader size='xl' />
            </div>
          : <Routes>
              <Route path="/" element={<HomePage refetchEvents={refetchEvents} />} />
              <Route path="/createEvent" element={<EventPage type='Details' />} />
              <Route path="/createEvent/inviteAttendees" element={<EventPage type='InviteTeam' />} />
              <Route path="/createEvent/agenda" element={<EventPage type='Agenda' />} />
              <Route path="/createEvent/reviewAndSubmit" element={<EventPage type='Review' />} />
              <Route path="/edit/:slug/details" element={<EventPage type='Details' isEdit />} />
              <Route path="/edit/:slug/agenda" element={<EventPage type='Agenda' isEdit />} />
              <Route path="/edit/:slug/inviteAttendees" element={<EventPage type='InviteTeam' isEdit />} />
              <Route path="/edit/:slug/reviewAndSubmit" element={<EventPage type='Review' isEdit />} />
              <Route path="/newCategory" element={<NewCategoryDropdown placeholder="Category/team" />} />
              <Route path="/teams" element={<TeamsDropdown />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/rsvp" element={<LoginByRSVP />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/resetPassword" element={<ForgotPassword />} />
              <Route path="/createNewPassword" element={<SetNewPassword />} />
              <Route path="/createNewPasswordByEmail" element={<SetNewPassword delayed />} />
              <Route path="/events" element={<EventsPageV2 />} />
              <Route path="/events/:slug" element={<ReviewEventDetailsView />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="support" element={<Support />} />
              <Route path="/notFound" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
      }
    </>
  );
}

export default App;
