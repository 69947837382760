import React, { useEffect, useState } from "react";
import { fetchRestaurantMenu } from "../../../httpQueries/http";
import MenuItem from "./MenuItem";
import { Button } from "../../Buttons/Button";
import arrowLeft from "../../../assets/images/arrow-narrow-left.svg";
import Loader from "../../Loader";
import Toggle from "../../Toggle/Toggle";
import CategoryTabs from "./CategoryTabs";
import RestaurantDetails from "../RestaurantDetails";
import bookIcon from '../../../assets/images/book-open-02.svg';
import pinIcon from "../../../assets/images/marker-pin-01-gray.svg";

interface Props {
  id: string;
  name: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<"Restaurant" | "Meals">>;
  setShowSelectRestaurantModal: React.Dispatch<React.SetStateAction<boolean>>;
  isPickup: boolean;
  setCreatorIsOrder: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        state: boolean | null;
      }[]
    >
  >;
  creatorIsOrder: boolean | null;
  setTotalSum: React.Dispatch<React.SetStateAction<number>>;
  fee: number;
  setMenu: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        subcategory_id: string;
        menu_item_list: {
          count: number;
          name: string;
          price: number;
          qty_available: null;
          unit_size: null;
          unit_of_measurement: string;
          description: string;
          is_available: boolean;
          image: string;
          customizations: [
            {
              name: string;
              min_choice_options: number;
              max_choice_options: number;
              options: [
                {
                  name: string;
                  price: number;
                  customizations: any[];
                  min_qty: number;
                  max_qty: number;
                  conditional_price: {};
                  formatted_price: string;
                  default_qty: number;
                  option_id: string;
                }
              ];
              customization_id: string;
            }
          ];
          min_price: number;
          original_price: number;
          formatted_price: string;
          attributes: [];
          product_id: string;
          thumbnail_image: string;
          should_fetch_customizations: boolean;
          supports_image_scaling: boolean;
        }[];
      }[]
    >
  >;
  menu: {
    name: string;
    subcategory_id: string;
    menu_item_list: {
      count: number;
      name: string;
      price: number;
      qty_available: null;
      unit_size: null;
      unit_of_measurement: string;
      description: string;
      is_available: boolean;
      image: string;
      customizations: [
        {
          name: string;
          min_choice_options: number;
          max_choice_options: number;
          options: [
            {
              name: string;
              price: number;
              customizations: any[];
              min_qty: number;
              max_qty: number;
              conditional_price: {};
              formatted_price: string;
              default_qty: number;
              option_id: string;
            }
          ];
          customization_id: string;
        }
      ];
      min_price: number;
      original_price: number;
      formatted_price: string;
      attributes: [];
      product_id: string;
      thumbnail_image: string;
      should_fetch_customizations: boolean;
      supports_image_scaling: boolean;
    }[];
  }[];
  onAddBlock: () => void;
  setOrderedMeals: React.Dispatch<
    React.SetStateAction<
      {
        customizations: {
          customizationId: string;
          optionId: string;
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
      }[]
    >
  >;
  orderedMeals: {
    customizations: {
      customizationId: string;
      optionId: string;
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
  }[];
  currentStep: "Restaurant" | "Meals";
  setSelectedAvailableMealsForAttendees: React.Dispatch<
    React.SetStateAction<{ id: number; productsIds: string[] }[]>
  >;
  selectedAvailableMealsForAttendees: { id: number; productsIds: string[] }[];
  setOrders: React.Dispatch<
    React.SetStateAction<
      {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string;
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }[]
    >
  >;
  orders: {
    agendaBlockId: number;
    customizations: {
      customizationId: string;
      optionId: string;
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  }[];
  selectedRestaurant: {
    id: string | number;
    name: string;
    logo: string;
    status?: "Open" | "Close";
    address: string;
    distance?: number;
    cuisines?: string[];
    menuLink?: string,
  } | null;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  agendaBlockId: number;
  isDesktopSize: boolean;
  total: number;
}

export default function RestaurantMenu({
  id,
  name,
  setCurrentStep,
  setShowSelectRestaurantModal,
  isPickup,
  setTotalSum,
  fee,
  setMenu,
  menu,
  onAddBlock,
  creatorIsOrder,
  setCreatorIsOrder,
  currentStep,
  orderedMeals,
  setOrderedMeals,
  setSelectedAvailableMealsForAttendees,
  selectedAvailableMealsForAttendees,
  setOrders,
  orders,
  selectedRestaurant,
  isLoading,
  setIsLoading,
  agendaBlockId,
  isDesktopSize,
  total,
}: Props) {
  const token = localStorage.getItem("token");
  const [isUpdatingTotalSum, setIsUpdatingTotalSum] = useState(false);
  const [addAllMealsFromCategory, setAddAllMealsFromCategory] = useState<
    { categoryId: string; isToggled: boolean }[]
  >([]);
  const [isToggledFromCategory, setIsToggledFromCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<null | string>(null);
  const [showSelectCustomizationModal, setShowSelectCustomizationModal] =
    useState<string>("");
  const [currentView, setCurrentView] = useState<1 | 2>(1);

  useEffect(() => {
    if (selectedRestaurant && typeof selectedRestaurant.id === "string") {
      setIsLoading(true);
      fetchRestaurantMenu({ id: selectedRestaurant.id, token, isPickup }).then(
        (res) => {
          const menuItems = res?.map((category) => ({
            ...category,
            menu_item_list: category.menu_item_list.map((meal) => ({
              count: 0,
              ...meal,
            })),
          }));

          setMenu(menuItems || []);
          setIsLoading(false);
        }
      );

      setSelectedAvailableMealsForAttendees((prevState) =>
        prevState.filter((item) => item.id !== agendaBlockId)
      );
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    if (isUpdatingTotalSum) {
      let currentValue = 0;

      menu.map((category) =>
        category.menu_item_list.map((menuItem) => {
          currentValue = menuItem.count * menuItem.price + currentValue;
        })
      );
      setIsUpdatingTotalSum(false);

      setTotalSum(currentValue / 100 + fee);
    }
  }, [isUpdatingTotalSum]);

  useEffect(() => {
    if (!creatorIsOrder) {
      setAddAllMealsFromCategory((prevState) => {
        return prevState.map((category) => {
          return { categoryId: category.categoryId, isToggled: false };
        });
      });
    }
  }, [creatorIsOrder]);

  useEffect(() => {
    if (isToggledFromCategory) {
      addAllMealsFromCategory.map((selectedCategory) => {
        if (selectedCategory.isToggled) {
          setMenu((prevState) =>
            prevState.map((category) => {
              if (category.subcategory_id === selectedCategory.categoryId) {
                setIsUpdatingTotalSum(true);
                return {
                  ...category,
                  menu_item_list: category.menu_item_list.map((menuItem) => {
                    return {
                      ...menuItem,
                      count:
                        menuItem.count >= 1
                          ? menuItem.count
                          : menuItem.count + 1,
                    };
                  }),
                };
              }

              return category;
            })
          );
          const currentOrder = selectedAvailableMealsForAttendees.find(
            (item) => item.id === agendaBlockId
          );

          setSelectedAvailableMealsForAttendees((prevState) => [
            ...prevState.filter((item) => item.id !== agendaBlockId),
            {
              id: agendaBlockId,
              productsIds: menu
                .find(
                  (categoryFromMenu) =>
                    categoryFromMenu.subcategory_id ===
                    selectedCategory.categoryId
                )!
                .menu_item_list.filter(
                  (item) => !currentOrder?.productsIds.includes(item.product_id)
                )
                .map((item) => item.product_id),
            },
          ]);
        } else {
          setMenu((prevState) =>
            prevState.map((category) => {
              if (category.subcategory_id === selectedCategory.categoryId) {
                setIsUpdatingTotalSum(true);
                return {
                  ...category,
                  menu_item_list: category.menu_item_list.map((menuItem) => {
                    return {
                      ...menuItem,
                      count: menuItem.count - 1,
                    };
                  }),
                };
              }

              return category;
            })
          );
          setSelectedAvailableMealsForAttendees((prevState) => [
            ...prevState.filter((item) => item.id !== agendaBlockId),
            {
              id: agendaBlockId,
              productsIds: menu
                .find(
                  (categoryFromMenu) =>
                    categoryFromMenu.subcategory_id ===
                    selectedCategory.categoryId
                )!
                .menu_item_list.map((item) => item.product_id),
            },
          ]);
        }
      });

      setIsToggledFromCategory(false);
    }
  }, [isToggledFromCategory]);

  return (
    <div className="restaurantMenu">
      <div className="selectRestaurant-main selectRestaurant-withoutPadding">
        <header className="selectRestaurant-main-header">
          <div
            className="flex flex-row gap-12 items-center border-gray-200 fullWidth"
            style={{
              position: "relative",
              zIndex: "30",
              padding: "0 0 24px 24px",
            }}
          >
            <img
              className="selectRestaurant-main-info-backButton"
              src={arrowLeft}
              alt=""
              onClick={() => setShowSelectRestaurantModal(false)}
            />
            <p className="text-xl font-semibold color-gray-900">
              Back to agenda block
            </p>
          </div>
        </header>
        {selectedRestaurant?.menuLink ? (
          <div className={`restaurantMenu-main-customRest`}>
            <div className="restaurantMenu-main-customRest-restaurant">
              <div className="homePage-content-todos-listIcon">
                <img width={40} height={40} src={bookIcon} alt="" />
              </div>
              <p className="restaurantMenu-main-customRest-restaurant-name">{selectedRestaurant.name}</p>
              <div className="flex items-center gap-6">
                <img src={pinIcon} alt="" />
                <p className="restaurantMenu-main-customRest-restaurant-location">{selectedRestaurant.address}</p>
              </div>
              <a className="textLinkColor" href={selectedRestaurant.menuLink} target="_blank">Menu link</a>
            </div>
            <p className="restaurantMenu-main-customRest-description">
              You can’t select meals for own added restaurants, please continue
              with this info or change the restaurant.
            </p>
          </div>
        ) : (
          <>
            {isDesktopSize || currentView === 1 ? (
              <div
                className="selectRestaurant-main-header-info"
                style={{ padding: "0 0 24px 24px" }}
              >
                <h3 className="selectRestaurant-main-header-info-text">
                  Select meals
                </h3>
                <div className="flex items-center gap-8">
                  <Toggle
                    toggleOn={!!creatorIsOrder}
                    setToggleOn={() =>
                      setCreatorIsOrder((prevState) => {
                        const currentItem = prevState.find(
                          (it) => it.id === agendaBlockId
                        );

                        return [
                          ...prevState.filter(
                            (it) => it.id !== currentItem?.id
                          ),
                          {
                            id: currentItem?.id || agendaBlockId,
                            state: currentItem ? !currentItem.state : false,
                          },
                        ];
                      })
                    }
                  />
                  <p>Attendees choose</p>
                </div>
              </div>
            ) : null}
            <main className={`restaurantMenu-main`}>
              {isDesktopSize || currentView === 1 ? (
                <>
                  <CategoryTabs
                    categories={menu.map((category) => ({
                      name: category.name,
                      items: category.menu_item_list.length,
                      isSelected: selectedCategory === category.name,
                      onSelect: () => setSelectedCategory(category.name),
                    }))}
                  />
                  <div className="restaurantMenu-main-menu overflowYScroll">
                    {menu.length > 0 || isLoading ? (
                      selectedCategory ? (
                        menu.map((category) => {
                          if (category.name !== selectedCategory) {
                            return null;
                          }

                          return (
                            <React.Fragment key={category.subcategory_id}>
                              {category.menu_item_list.map((menuItem) => {
                                return (
                                  <MenuItem
                                    customizations={menuItem.customizations}
                                    key={menuItem.product_id}
                                    setIsUpdatingTotalSum={
                                      setIsUpdatingTotalSum
                                    }
                                    setMenu={setMenu}
                                    name={menuItem.name}
                                    description={menuItem.description}
                                    price={menuItem.formatted_price}
                                    image={menuItem.image}
                                    count={
                                      orders.find(
                                        (order) =>
                                          order.productId ===
                                          menuItem.product_id
                                      )?.count || 0
                                    }
                                    categoryId={category.subcategory_id}
                                    productId={menuItem.product_id}
                                    orderForAttendees={!!creatorIsOrder}
                                    selectedAvailableMealsForAttendees={
                                      selectedAvailableMealsForAttendees
                                    }
                                    setSelectedAvailableMealsForAttendees={
                                      setSelectedAvailableMealsForAttendees
                                    }
                                    setTotalSum={setTotalSum}
                                    setAddAllMealsFromCategory={
                                      setAddAllMealsFromCategory
                                    }
                                    orderedMeals={orderedMeals}
                                    setOrderedMeals={setOrderedMeals}
                                    markedPrice={menuItem.price}
                                    setOrders={setOrders}
                                    orders={orders}
                                    agendaBlockId={agendaBlockId}
                                    showSelectCustomizationModal={
                                      showSelectCustomizationModal
                                    }
                                    setShowSelectCustomizationModal={
                                      setShowSelectCustomizationModal
                                    }
                                  />
                                );
                              })}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        menu.map((category) => (
                          <React.Fragment key={category.subcategory_id}>
                            {category.menu_item_list.map((menuItem) => {
                              return (
                                <MenuItem
                                  customizations={menuItem.customizations}
                                  key={menuItem.product_id}
                                  setIsUpdatingTotalSum={setIsUpdatingTotalSum}
                                  setMenu={setMenu}
                                  name={menuItem.name}
                                  description={menuItem.description}
                                  price={menuItem.formatted_price}
                                  image={menuItem.image}
                                  count={
                                    orders.find(
                                      (order) =>
                                        order.productId === menuItem.product_id
                                    )?.count || 0
                                  }
                                  categoryId={category.subcategory_id}
                                  productId={menuItem.product_id}
                                  orderForAttendees={!!creatorIsOrder}
                                  selectedAvailableMealsForAttendees={
                                    selectedAvailableMealsForAttendees
                                  }
                                  setSelectedAvailableMealsForAttendees={
                                    setSelectedAvailableMealsForAttendees
                                  }
                                  setTotalSum={setTotalSum}
                                  setAddAllMealsFromCategory={
                                    setAddAllMealsFromCategory
                                  }
                                  orderedMeals={orderedMeals}
                                  setOrderedMeals={setOrderedMeals}
                                  markedPrice={menuItem.price}
                                  setOrders={setOrders}
                                  orders={orders}
                                  agendaBlockId={agendaBlockId}
                                  showSelectCustomizationModal={
                                    showSelectCustomizationModal
                                  }
                                  setShowSelectCustomizationModal={
                                    setShowSelectCustomizationModal
                                  }
                                />
                              );
                            })}
                          </React.Fragment>
                        ))
                      )
                    ) : (
                      <div className="flex items-center fullWidth justifyCenter">
                        <Loader size="xl" />
                      </div>
                    )}
                  </div>
                </>
              ) : null}
              {isDesktopSize || currentView === 2 ? (
                <RestaurantDetails
                  isLoading={isLoading}
                  name={selectedRestaurant?.name || "Restaurant name example"}
                  status={selectedRestaurant?.status}
                  address={selectedRestaurant?.address}
                  distance={selectedRestaurant?.distance}
                  cuisines={selectedRestaurant?.cuisines || []}
                  fee={fee}
                  total={total}
                  logo={""}
                  creatorIsOrder={!!creatorIsOrder}
                  mealsForAttendeesChoose={menu.map((category) => {
                    let selectedProductsName: {
                      name: string;
                      count: number;
                    }[] = [];
                    const filteredList = category.menu_item_list.filter(
                      (menuItem) => menuItem.count >= 1
                    );
                    filteredList.map((selectedProduct) => {
                      selectedProductsName = [
                        ...selectedProductsName,
                        {
                          name: selectedProduct.name,
                          count: selectedProduct.count,
                        },
                      ];
                    });

                    return selectedProductsName;
                  })}
                  orderedMeals={orders.map((order) => {
                    let product:
                      | {
                          count: number;
                          name: string;
                          price: number;
                          qty_available: null;
                          unit_size: null;
                          unit_of_measurement: string;
                          description: string;
                          is_available: boolean;
                          image: string;
                          customizations: [
                            {
                              name: string;
                              min_choice_options: number;
                              max_choice_options: number;
                              options: [
                                {
                                  name: string;
                                  price: number;
                                  customizations: any[];
                                  min_qty: number;
                                  max_qty: number;
                                  conditional_price: {};
                                  formatted_price: string;
                                  default_qty: number;
                                  option_id: string;
                                }
                              ];
                              customization_id: string;
                            }
                          ];
                          min_price: number;
                          original_price: number;
                          formatted_price: string;
                          attributes: [];
                          product_id: string;
                          thumbnail_image: string;
                          should_fetch_customizations: boolean;
                          supports_image_scaling: boolean;
                        }
                      | undefined;

                    menu.map((category) => {
                      category.menu_item_list.map((item) => {
                        if (item.product_id === order.productId) {
                          product = item;
                        }
                      });
                    });

                    const productToOrder = orders.find(
                      (item) => item.productId === order?.productId
                    );

                    return {
                      img: product?.image || "",
                      title: product?.name || "",
                      notes: productToOrder?.notes || "",
                      price: product?.formatted_price || "",
                      count: order.count,
                      id: product?.product_id || "",
                      customizationsPrice: productToOrder?.customizations
                        .map((cust) => cust.markedPrice)
                        .reduce((previous, current) => previous + current, 0),
                      onIncrease: () => {
                        setOrders((prevState) => {
                          const productToOrder = prevState.find(
                            (item) => item.productId === order?.productId
                          );
                          const productToOrderIndex = prevState.findIndex(
                            (item) => item.productId === order?.productId
                          );

                          if (!!productToOrder) {
                            setTotalSum(
                              (prevState) =>
                                prevState +
                                (order.markedPrice / 100 +
                                  order.customizations.reduce(
                                    (accum, currentValue) =>
                                      currentValue.markedPrice / 100 + accum,
                                    0
                                  ))
                            );
                            return [
                              ...prevState.slice(0, productToOrderIndex),
                              {
                                ...productToOrder,
                                count: productToOrder.count + 1,
                              },
                              ...prevState.slice(productToOrderIndex + 1),
                            ];
                          }

                          return prevState;
                        });
                      },
                      onDecrease: () => {
                        setOrders((prevState) => {
                          const productToOrder = prevState.find(
                            (item) => item.productId === order.productId
                          );
                          const productToOrderIndex = prevState.findIndex(
                            (item) => item.productId === order?.productId
                          );

                          if (!!productToOrder) {
                            setTotalSum(
                              (prevState) =>
                                prevState -
                                (order.markedPrice / 100 +
                                  order.customizations.reduce(
                                    (accum, currentValue) =>
                                      currentValue.markedPrice / 100 + accum,
                                    0
                                  ))
                            );

                            if (productToOrder.count === 1) {
                              return [
                                ...prevState.filter(
                                  (item) => item.productId !== order?.productId
                                ),
                              ];
                            }
                            return [
                              ...prevState.slice(0, productToOrderIndex),
                              {
                                ...productToOrder,
                                count: productToOrder.count - 1,
                              },
                              ...prevState.slice(productToOrderIndex + 1),
                            ];
                          }

                          return prevState;
                        });
                      },
                    };
                  })}
                  openMealEdit={setShowSelectCustomizationModal}
                />
              ) : null}
            </main>
          </>
        )}
      </div>
      <footer className="selectRestaurant-footer restaurantMenu-footer">
        {isDesktopSize ? (
          <>
            <Button
              size="lg"
              hierarchy="newDesign-secondary"
              newDesignPaddingNone
              buttonType="regular"
              onClick={() => setCurrentStep("Restaurant")}
            >
              Change restaurant
            </Button>
            <Button
              size="lg"
              hierarchy={"newDesign-primary"}
              newDesignPaddingNone
              buttonType="regular"
              onClick={() => {
                onAddBlock();
                setShowSelectRestaurantModal(false);
              }}
            >
              Add to block
            </Button>
          </>
        ) : currentView === 1 ? (
          <>
            <Button
              size="lg"
              hierarchy="newDesign-secondary"
              newDesignPaddingNone
              buttonType="regular"
              onClick={() => setCurrentStep("Restaurant")}
            >
              Change restaurant
            </Button>
            <Button
              size="lg"
              hierarchy={"newDesign-primary"}
              newDesignPaddingNone
              buttonType="regular"
              onClick={() => {
                setCurrentView(2);
              }}
            >
              Review
            </Button>
          </>
        ) : currentView === 2 ? (
          <>
            <Button
              size="lg"
              hierarchy="newDesign-secondary"
              newDesignPaddingNone
              buttonType="regular"
              onClick={() => setCurrentView(1)}
            >
              Edit order
            </Button>
            <Button
              size="lg"
              hierarchy={"newDesign-primary"}
              newDesignPaddingNone
              buttonType="regular"
              onClick={() => {
                onAddBlock();
                setShowSelectRestaurantModal(false);
              }}
            >
              Add to block
            </Button>
          </>
        ) : null}
      </footer>
    </div>
  );
}
