import React from "react";
import Alert from "../Alert/Alert";
import Loader from "../Loader";
import SimpleAddMeal from "./MealSelect/SimpleAddMeal";
import faceFrown from "../../assets/images/face-frown.png";
import { Button } from "../Buttons/Button";

interface Props {
  name: string;
  status?: "Open" | "Close";
  address?: string;
  distance?: number;
  cuisines: string[];
  fee: number;
  total: number;
  creatorIsOrder: boolean;
  logo: string;
  mealsForAttendeesChoose: { name: string; count: number }[][];
  isLoading: boolean;
  withProductCards?: boolean;
  orderedMeals: {
    img: string;
    title: string;
    notes: string;
    price: string;
    count: number;
    onIncrease: () => void;
    onDecrease: () => void;
    id: string;
    customizationsPrice?: number;
  }[];
  openMealEdit: (mealId: string) => void;
  withAddToOrder?: () => Promise<void>;
}

export default function RestaurantDetails({
  name,
  status,
  address,
  distance,
  cuisines,
  fee,
  total,
  openMealEdit,
  creatorIsOrder,
  mealsForAttendeesChoose,
  isLoading,
  withAddToOrder,
  orderedMeals,
}: Props) {
  let totalOrderedCountOfMeals = 0;

  mealsForAttendeesChoose.map((category) =>
    category.map((item) => {
      totalOrderedCountOfMeals += item.count;
    })
  );

  return (
    <div className="restaurantDetails overflowYScroll">
      {isLoading ? (
        <div className="flex items-center justifyCenter fullWidth fullHeight">
          <Loader size="xl" />
        </div>
      ) : (
        <div className="restaurantDetails-info">
          <h3 className="restaurantDetails-label">Order details</h3>
          <div
            className={`restaurantDetails-info-restaurant ${
              status && address && distance && cuisines.length
                ? "restaurantDetails-info-restaurant-selected"
                : ""
            }`}
          >
            <div className="restaurantDetails-info-restaurant-info">
              <h3 className="restaurantDetails-info-restaurant-info-name">
                {name}
              </h3>
              {status && address && distance && cuisines.length ? (
                <>
                  <p className="restaurantDetails-info-restaurant-info-text">
                    {address} • {distance.toFixed(2)}mi •{" "}
                    {cuisines.slice(0, 3).join(", ")}
                  </p>
                </>
              ) : (
                <p className="restaurantDetails-info-restaurant-info-text">
                  Description example
                </p>
              )}
            </div>
          </div>
          {totalOrderedCountOfMeals >= 10 ? (
            <Alert
              title={"Orders over 10 items"}
              description={
                "For orders with over 10 items, we recommend to place the order 72 hours in advance to make sure the restaurant can accommodate. Don't worry - we'll remind you!"
              }
            />
          ) : null}
          <div className="restaurantDetails-info-noFood">
            {!orderedMeals.length &&
            !(
              mealsForAttendeesChoose.length &&
              mealsForAttendeesChoose[0].length
            ) ? (
              <>
                <img
                  className="restaurantDetails-info-noFood-image"
                  src={faceFrown}
                  alt=""
                />
                <p className="restaurantDetails-info-noFood-label">
                  No food items selected
                </p>
              </>
            ) : null}
            {creatorIsOrder ? (
              <>
                {mealsForAttendeesChoose.map((category) =>
                  category.map((meal) => (
                    <p
                      className="restaurantDetails-paymentInfo-content-label"
                      key={meal.name}
                    >
                      {meal.name}
                    </p>
                  ))
                )}
              </>
            ) : (
              <>
                {orderedMeals.map((item) => (
                  <SimpleAddMeal
                    title={item.title}
                    notes={item.notes}
                    price={
                      item.customizationsPrice
                        ? `$${
                            +(+item.price.slice(1)).toFixed() +
                            +item.customizationsPrice.toFixed() / 100
                          }`
                        : item.price
                    }
                    onIcnrease={item.onIncrease}
                    onDescrease={item.onDecrease}
                    count={item.count}
                    onNotesClick={(mealId: string) => openMealEdit(mealId)}
                    id={item.id}
                  />
                ))}
              </>
            )}
          </div>
          {withAddToOrder ? (
            <Button
              size={"md"}
              hierarchy={"newDesign-primary"}
              buttonType={"regular"}
              fullWidth
              contentCenter
              newDesignPaddingNone
              asyncOnClick={async () => {
                await withAddToOrder();
              }}
            >
              Add to order
            </Button>
          ) : (
            <div className="restaurantDetails-paymentInfo">
              <div className="restaurantDetails-paymentInfo-content">
                <p className="restaurantDetails-paymentInfo-content-label">
                  Fees
                </p>
                <p className="restaurantDetails-paymentInfo-content-sum">
                  ${fee.toFixed(2)}
                </p>
              </div>
              <div className="restaurantDetails-paymentInfo-content">
                <p className="restaurantDetails-paymentInfo-content-label">
                  Estimated total
                </p>
                <p className="restaurantDetails-paymentInfo-content-sum">
                  ${total.toFixed(2)}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
