import React, { useEffect, useState } from "react";
import menuIcon from "../../assets/images/menu-05-white.svg";
import dotsIcon from "../../assets/images/dots-vertical.svg";
import trashIcon from "../../assets/images/trash-01-white.svg";
import editIcon from "../../assets/images/edit-01-white.svg";
import { Button } from "../Buttons/Button";
import FeaturedIcon from "../FeaturedIcons/FeaturedIcon";
import { useWindowSize } from "@uidotdev/usehooks";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import DotsModal from "../Modal/DotsModal";
import { AgendaBlockType } from "../../types/agendaBlockType";
import { useQuery } from "@tanstack/react-query";
import { fetchMeals } from "../../httpQueries/http";
import Loader from "../Loader";

interface Props {
  id: string;
  icon: string;
  startTime: string;
  endTime: string;
  name: string;
  duration: {
    id: number;
    name: string;
    value?: number | undefined;
  };
  index: number;
  description: string;
  type: AgendaBlockType;
  onClick?: () => Promise<void>;
  setAgenda?: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
        index: number;
      }[]
    >
  >;
  hasEditActions?: boolean;
  hasDragElement?: boolean;
  setShowAgendaModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentItem?: React.Dispatch<
    React.SetStateAction<
      | {
          id: string;
          icon: string;
          name: string;
          description: string;
          duration: {
            id: number;
            name: string;
            value?: number | undefined;
          };
          startTime: string;
          endTime: string;
          type: AgendaBlockType;
          index: number;
        }
      | undefined
    >
  >;
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
  isReview?: boolean;
  height: "full" | "max";
  isAgendaMealBlockWithoutMeals?: boolean;
  mealBlockId?: number;
}

export default function AgendaItem({
  id,
  icon,
  startTime,
  endTime,
  name,
  index,
  duration,
  description,
  type,
  setAgenda,
  setShowAgendaModal,
  setCurrentItem,
  dragHandleProps,
  hasDragElement,
  hasEditActions,
  isReview,
  height,
  isAgendaMealBlockWithoutMeals,
  onClick,
  mealBlockId,
}: Props) {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [showDotsModal, setShowDotsModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const { data: mealBlock } = useQuery({
    queryKey: ["mealBlock", mealBlockId],
    queryFn: async () => await fetchMeals({ id: mealBlockId ?? 0, token }),
  });

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);
  return (
    <div
      className={`agendaItem ${
        !hasDragElement ? "agendaItem-withoutEditActions" : ""
      } ${isReview ? "reviewAgendaModalBlock" : ""} ${
        height === "max" ? "agendaItem-maxHeight" : ""
      }`}
    >
      <div
        className={`agendaItem-timeInfo ${
          !hasDragElement ? "agendaItem-timeInfo-withoutEditActions" : ""
        } ${isReview ? "reviewAgendaModalBlock-timeInfo" : ""}`}
      >
        <div className="flex items-center gap-16">
          {hasDragElement ? (
            <div {...dragHandleProps} className="agendaItem-info-dragBtn">
              <Button
                size="md"
                buttonType="small"
                hierarchy="tertiaryGray"
                buttonImage={menuIcon}
                pointerEventsNone
                disabled
                hoverDark
              />
            </div>
          ) : null}
          <div className="agendaItem-timeInfo-container">
            <div
              className={`agendaItem-timeInfo-time-circle ${
                !hasDragElement
                  ? "agendaItem-timeInfo-time-circle-withoutEditActions"
                  : ""
              }`}
            />
            <p className="agendaItem-timeInfo-time">
              {startTime} - {endTime}
            </p>
          </div>
        </div>
        {!mealBlock && hasEditActions ? (
          <>
            {!sizeIsDesktop ? (
              <Button
                size="md"
                buttonType="small"
                hierarchy="tertiaryGray"
                buttonImage={dotsIcon}
                hoverDark
                onClick={() => setShowDotsModal((prev) => !prev)}
              />
            ) : null}

            {sizeIsDesktop ? (
              <div className="agendaItem-info-actions">
                <Button
                  size="md"
                  buttonType="small"
                  hierarchy="tertiaryGray"
                  hoverDark
                  buttonImage={trashIcon}
                  onClick={() =>
                    setAgenda &&
                    setAgenda((prevState) =>
                      prevState.filter((item) => item.id !== id)
                    )
                  }
                />
                <Button
                  size="md"
                  buttonType="small"
                  hierarchy="tertiaryGray"
                  buttonImage={editIcon}
                  hoverDark
                  onClick={() => {
                    setShowAgendaModal && setShowAgendaModal(true);
                    setCurrentItem &&
                      setCurrentItem({
                        id,
                        description,
                        duration,
                        endTime,
                        icon,
                        name,
                        startTime,
                        type,
                        index,
                      });
                  }}
                />
              </div>
            ) : null}
          </>
        ) : null}
        {showDotsModal && !sizeIsDesktop ? (
          <DotsModal
            items={[
              {
                id: 1,
                img: editIcon,
                text: "Edit",
                onClick: () => {
                  setShowAgendaModal && setShowAgendaModal(true);
                  setCurrentItem &&
                    setCurrentItem({
                      id,
                      description,
                      duration,
                      endTime,
                      icon,
                      name,
                      startTime,
                      type,
                      index,
                    });
                },
              },
              {
                id: 2,
                img: trashIcon,
                text: "Delete",
                onClick: () =>
                  setAgenda &&
                  setAgenda((prevState) =>
                    prevState.filter((item) => item.id !== id)
                  ),
              },
            ]}
            setIsOpen={setShowDotsModal}
            top="60px"
            right="0"
          />
        ) : null}
      </div>
      <div
        className={`agendaItem-info 
          ${!hasDragElement ? "agendaItem-info-withoutEditActions" : ""} 
          ${isReview ? "reviewAgendaModalBlock-info" : ""}
          ${onClick && "cursorPointer"}
        `}
        onClick={async () => {
          setIsLoading(true);
          onClick && (await onClick());
          setIsLoading(false);
        }}
      >
        {!isLoading ? (
          <div className="agendaItem-info-title-wrapper">
            <div className="agendaItem-info-title">
              <div
                className={`
                      agendaItem-info-title-icon 
                      ${
                        type === AgendaBlockType.Break
                          ? "agendaItem-info-title-icon-break"
                          : type === AgendaBlockType.Meal
                          ? "agendaItem-info-title-icon-meal"
                          : ""
                      }
                    `}
              >
                <FeaturedIcon
                  icon={icon}
                  type={
                    type === AgendaBlockType.Session
                      ? "primary"
                      : type === AgendaBlockType.Break
                      ? "gray"
                      : "warning"
                  }
                  size="sm"
                />
              </div>
              <div className="flex flex-column gap-6">
                <h3 className="agendaItem-info-title-text">{name}</h3>
                {mealBlock ? (
                  <p className="agendaItem-info-description">
                    {mealBlock.mealBlock?.restaurantName} • Estimated total $
                    {mealBlock?.totalPrice &&
                      (mealBlock.totalPrice / 100).toFixed(2)}
                  </p>
                ) : null}
              </div>
              {/* <p className='agendaItem-info-title-duration'>{duration.name}</p> */}
            </div>
            {description.length ? (
              <p className="agendaItem-info-description">{description}</p>
            ) : null}
          </div>
        ) : (
          <div className="flex items-center justifyCenter fullWidth heightWidth">
            <Loader size={"md"}></Loader>
          </div>
        )}
      </div>
    </div>
  );
}
