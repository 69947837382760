import React, { Suspense } from "react";
import { SearchInput } from "./SearchInput";
import PasswordInput from "./PasswordInput";
import { User } from "../../types/userForAddType";
import InputWithActions from "./InputWithActions";
import arrowDown from "../../assets/images/chevron-down.svg";
import helpIcon from "../../assets/images/help-circle.svg";
import errorIcon from "../../assets/images/alert-circle.svg";

interface Props {
  isError?: boolean;
  label: string;
  hintMessage?: string;
  errorMessage?: string;
  type:
    | "Card"
    | "Email"
    | "EmailWithIconBefore"
    | "Phone"
    | "Sale"
    | "TextArea"
    | "TextAreaWithAddFeat"
    | "Website"
    | "WebsiteWithCopyFeat"
    | "Users"
    | "Text"
    | "SearchPrimary"
    | "SearchGray"
    | "SearchDark"
    | "Password"
    | "InputWithActions";
  disabled?: boolean;
  hasHelpIcon?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value?: string;
  border?: "full" | "left" | "right" | "bottom" | "top" | "none";
  hasDropdown?: boolean;
  hasIconBefore?: string | boolean;
  fullWidth?: boolean;
  setShow?: () => void;
  onFocus?: () => void;
  websiteStartWith?: string;
  setUsersFromInput?: React.Dispatch<React.SetStateAction<User[]>>;
  usersFromInput?: User[];
  onAddNewItem?: (newItem: User) => void;
  onBlur?: () => void;
  design?: "new" | "old";
  padding?: "lg" | "sm";
  id?: string;
  placeholder?: string;
}

const CardInput = React.lazy(() => import("./CardInput"));
const EmailInput = React.lazy(() => import("./EmailInput"));
const NumberInput = React.lazy(() => import("./NumberInput"));
const TextArea = React.lazy(() => import("./TextArea"));
const UsersInput = React.lazy(() => import("./UsersInput"));
const WebsiteInput = React.lazy(() => import("./WebsiteInput"));

export function FormInput({
  isError,
  label,
  placeholder,
  hintMessage,
  errorMessage,
  type,
  disabled,
  hasHelpIcon,
  onChange,
  value,
  border = "full",
  hasDropdown,
  hasIconBefore,
  fullWidth,
  setShow,
  onFocus,
  websiteStartWith,
  setUsersFromInput,
  usersFromInput,
  onAddNewItem,
  onBlur,
  design = "old",
  padding = "sm",
  id,
}: Props) {
  function getInputType() {
    switch (type) {
      case "Card":
        return (
          <CardInput
            isError={isError}
            disabled={disabled}
            hasHelpIcon={hasHelpIcon}
            onChange={onChange}
            value={value}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            placeholder={placeholder}
          />
        );
      case "Email":
        return (
          <EmailInput
            isError={isError}
            disabled={disabled}
            type="email"
            hasHelpIcon={hasHelpIcon}
            onChange={onChange}
            value={value}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            design={design}
            placeholder={placeholder}
          />
        );
      case "EmailWithIconBefore":
        return (
          <EmailInput
            isError={isError}
            disabled={disabled}
            hasBeforeIcon={hasIconBefore}
            type="email"
            hasHelpIcon={hasHelpIcon}
            onChange={onChange}
            value={value}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            design={design}
            placeholder={placeholder}
          />
        );
      case "Phone":
        return (
          <NumberInput
            isError={isError}
            disabled={disabled}
            type="phoneNumber"
            hasHelpIcon={hasHelpIcon}
            onChange={onChange}
            value={value}
            hasDropdown={hasDropdown}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            design={design}
            placeholder={placeholder}
          />
        );
      case "Sale":
        return (
          <NumberInput
            isError={isError}
            disabled={disabled}
            type="saleAmount"
            hasHelpIcon={hasHelpIcon}
            onChange={onChange}
            value={value}
            hasDropdown={hasDropdown}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            design={design}
            placeholder={placeholder}
          />
        );
      case "TextArea":
        return (
          <TextArea
            isError={isError}
            disabled={disabled}
            onChange={onChange}
            value={value}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            design={design}
            id={id}
            placeholder={placeholder}
          />
        );
      case "TextAreaWithAddFeat":
        return (
          <TextArea
            isError={isError}
            disabled={disabled}
            withAddFeat
            onChange={onChange}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            design={design}
            id={id}
            placeholder={placeholder}
          />
        );
      case "Website":
        return (
          <WebsiteInput
            isError={isError}
            disabled={disabled}
            hasHelpIcon={hasHelpIcon}
            onChange={onChange}
            value={value}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            websiteStartWith={websiteStartWith}
            placeholder={placeholder}
          />
        );
      case "WebsiteWithCopyFeat":
        return (
          <WebsiteInput
            isError={isError}
            disabled={disabled}
            withCopyFeat
            hasHelpIcon={hasHelpIcon}
            onChange={onChange}
            value={value}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            websiteStartWith={websiteStartWith}
            placeholder={placeholder}
          />
        );
      case "Users":
        return (
          <UsersInput
            isError={isError}
            disabled={disabled}
            hasHelpIcon={hasHelpIcon}
            onChange={onChange}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            setUsersFromInput={setUsersFromInput!}
            usersFromInput={usersFromInput!}
            design={design}
            placeholder={placeholder}
          />
        );

      case "Text":
        return (
          <EmailInput
            isError={isError}
            disabled={disabled}
            type="text"
            hasHelpIcon={hasHelpIcon}
            onChange={onChange}
            value={value}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            design={design}
            placeholder={placeholder}
          />
        );

      case "SearchPrimary":
        return (
          <SearchInput
            type="primary"
            onChange={onChange}
            isError={isError}
            hasHelpIcon={hasHelpIcon}
            value={value}
            border={border}
            hasIconBefore={hasIconBefore}
            fullWidth={fullWidth}
            setShow={setShow}
            onFocus={onFocus}
            design={design}
            placeholder={placeholder}
          />
        );

      case "SearchGray":
        return (
          <SearchInput
            type="gray"
            onChange={onChange}
            isError={isError}
            hasHelpIcon={hasHelpIcon}
            value={value}
            border={border}
            hasIconBefore={hasIconBefore}
            fullWidth={fullWidth}
            setShow={setShow}
            onFocus={onFocus}
            design={design}
            placeholder={placeholder}
          />
        );

      case "SearchDark":
        return (
          <SearchInput
            type="dark"
            onChange={onChange}
            isError={isError}
            hasHelpIcon={hasHelpIcon}
            value={value}
            border={border}
            hasIconBefore={hasIconBefore}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            design={design}
            placeholder={placeholder}
          />
        );

      case "Password":
        return (
          <PasswordInput
            onChange={onChange}
            isError={isError}
            hasHelpIcon={hasHelpIcon}
            value={value}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            design={design}
            placeholder={placeholder}
          />
        );

      case "InputWithActions":
        return (
          <InputWithActions
            isError={isError}
            disabled={disabled}
            hasHelpIcon={hasHelpIcon}
            onChange={onChange}
            fullWidth={fullWidth}
            onFocus={onFocus}
            setShow={setShow}
            setUsersFromInput={setUsersFromInput!}
            usersFromInput={usersFromInput!}
            onAddNewItem={onAddNewItem}
            onBlur={onBlur}
            placeholder={placeholder}
          />
        );
    }
  }

  return (
    <div className={`flex flex-column gap-12 ${fullWidth ? 'fullWidth' : ''}`}>
      <form
        className={`
          form 
          ${design === "new" && "formNew"} 
          ${isError ? (design === "new" ? "inputNew-error" : "form_error") : ""}
          ${padding === "lg" ? "formNew-paddingLg" : ""}
          ${hasDropdown ? "positionRelative" : ""}
          ${type === "TextArea" ? "formNew-textArea" : ""}
        `}
        onSubmit={(e) => e.preventDefault()}
        id={id}
      >
        {(!value?.length && !usersFromInput?.length) || design === "new" ? (
          <label
            className={`
                  ${
                    value?.length || usersFromInput?.length
                      ? "formNew-label"
                      : "formNew-placeholder"
                  }
                  ${
                    (type === "SearchDark" ||
                      type === "SearchGray" ||
                      type === "SearchPrimary") &&
                    hasIconBefore
                      ? value?.length
                        ? ""
                        : design === "new"
                        ? "formNew-placeholder-search"
                        : "formNew-placeholder-search-old"
                      : ""
                  }
                  ${
                    type === "TextArea" && design === "old"
                      ? "formNew-placeholder-textArea-old"
                      : (type === "Website" ||
                          type === "WebsiteWithCopyFeat") &&
                        design === "old"
                      ? "display-none"
                      : ""
                  }
                `}
          >
            {label}
          </label>
        ) : (
          <label className="text-sm font-medium">{label}</label>
        )}
        <Suspense fallback={<div>Loading...</div>}>{getInputType()}</Suspense>
        {hasDropdown ? (
          <img
            src={arrowDown}
            alt=""
            className="positionAbsolute right-0 absolute-center"
          />
        ) : null}
        {hintMessage ? (
          <p className="text-sm font-regular">{hintMessage}</p>
        ) : null}
      </form>
      {isError && design === "new" ? (
        <div className="flex items-center gap-8">
          {hasHelpIcon ? (
            <img src={isError ? errorIcon : helpIcon} alt="help" />
          ) : null}
          <p className="inputNew-error-text">{errorMessage}</p>
        </div>
      ) : null}
    </div>
  );
}
