import React from "react";
import { Button } from "../Buttons/Button";
import trashIcon from "../../assets/images/trash-01-black.svg";
import pencilIcon from "../../assets/images/edit-01.svg";
import { deleteDocument } from "../../httpQueries/http";

interface Props {
  url: string;
  key: string;
  img: string;
  name: string;
  type: "document" | "link";
  id?: number;
  eventId?: number;
  onEdit?: () => void;
  withoutActions?: boolean;
  onDelete?: () => void;
}

export default function Document({
  url,
  key,
  img,
  name,
  type,
  onEdit,
  id,
  eventId,
  withoutActions,
  onDelete
}: Props) {
  const token = localStorage.getItem("token");

  return (
    <a
      href={url}
      key={key}
      target="_blank"
      className="border-gray text-decoration-none border-radius-12 px-12 py-16 flex items-center gap-4"
    >
      <img src={img} alt="" />
      <p className="text-sm font-medium color-primary-600">{name}</p>
      {withoutActions ? null : (
        <div className="ml-auto flex items-center">
          {type === "link" ? (
            <Button
              buttonType="small"
              hierarchy="tertiaryGray"
              size="md"
              buttonImage={pencilIcon}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onEdit && onEdit();
              }}
            />
          ) : null}
          <Button
            buttonType="small"
            hierarchy="tertiaryGray"
            size="md"
            buttonImage={trashIcon}
            asyncOnClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (eventId && id) {
                await deleteDocument({ id: eventId, token, documentId: id });
                onDelete && onDelete();
              }
            }}
          />
        </div>
      )}
    </a>
  );
}
