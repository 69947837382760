import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../Buttons/Button";
import { restaurantSortTypes } from "../../../types/restaurantSortTypes";
import {
  fetchCustomRestaurants,
  fetchRestaurants,
} from "../../../httpQueries/http";
import RestaurantItem from "./RestaurantItem";
import { FormInput } from "../../Inputs/FormInput";
import markerIcon from "../../../assets/images/marker-pin-01-gray.svg";
import arrowLeft from "../../../assets/images/arrow-narrow-left.svg";
import FeaturedIcon from "../../FeaturedIcons/FeaturedIcon";
import searchIcon from "../../../assets/images/search-lg-gray.svg";
import plusIcon from "../../../assets/images/plus-primary.svg";
import Loader from "../../Loader";
import { userRestaurantResponseType } from "../../../types/userRestaurantResponse";
import UserRestaurantItem from "./UserRestaurantItem";
import InfiniteScroll from "react-infinite-scroll-component";
import ToggleButtons from "../../ToggleButtons";
import shoppingBagWhite from "../../../assets/images/shopping-bag-02.svg";
import routeBlack from "../../../assets/images/route.svg";
import shoppingBagBlack from "../../../assets/images/shopping-bag-02-black.png";
import routeWhite from "../../../assets/images/route-white.png";
import listWhiteIcon from "../../../assets/images/list-selected.svg";
import listBlackIcon from "../../../assets/images/list-black.svg";
import mapWhiteIcon from "../../../assets/images/map-white.svg";
import mapBlackIcon from "../../../assets/images/map-black.svg";
import { MapboxMap } from "../../MapBox/Map";
import { createPortal } from "react-dom";
import CustomModal from "../../Modal/CustomModal";
import { AgendaBlockType } from "../../../types/agendaBlockType";
import DropdownSelect from "../../Dropdown/DropdownSelect";
import OrderSelectionDropdown from "../../Dropdown/OrderSelectionDropdown";
import AddNewRestaurantModal from "../../Modal/AddNewRestaurantModal";

interface Props {
  longitude: number;
  latitude: number;
  location: string;
  setSelectedRestaurant: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      name: string;
      logo: string;
      status?: "Open" | "Close";
      address: string;
      distance?: number;
      cuisines?: string[];
      menuLink?: string;
    } | null>
  >;
  setShowSelectRestaurantModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentStep: React.Dispatch<React.SetStateAction<"Restaurant" | "Meals">>;
  setIsPickup: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        state: boolean;
      }[]
    >
  >;
  isPickup: boolean;
  selectedRestaurant: {
    id: string | number;
    name: string;
    logo: string;
    status?: "Open" | "Close";
    address: string;
    distance?: number;
    cuisines?: string[];
    menuLink?: string;
  } | null;
  dayId: number;
  agendaBlockStartTime: number;
  isDesktopSize: boolean;
  showSelectRestaurantModal: boolean;
  searchValue: string;
  agendaBlockId: number;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  restaurantsForOrders: {
    id: string | number;
    name: string;
    status?: "Open" | "Close" | undefined;
    logo: string;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
    agendaBlockId: number;
  }[];
  setRestaurantsForOrders: React.Dispatch<
    React.SetStateAction<
      {
        id: string | number;
        name: string;
        status?: "Open" | "Close" | undefined;
        logo: string;
        address: string;
        distance?: number | undefined;
        cuisines?: string[] | undefined;
        agendaBlockId: number;
      }[]
    >
  >;
  setCreatorIsOrder: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        state: boolean | null;
      }[]
    >
  >;
  creatorIsOrder: boolean | null;
}

export default function RestaurantSelect({
  longitude,
  latitude,
  location,
  selectedRestaurant,
  setSelectedRestaurant,
  setShowSelectRestaurantModal,
  setIsPickup,
  isPickup,
  agendaBlockStartTime,
  dayId,
  isDesktopSize,
  showSelectRestaurantModal,
  searchValue,
  setSearchValue,
  restaurantsForOrders,
  setRestaurantsForOrders,
  agendaBlockId,
  setCreatorIsOrder,
  creatorIsOrder,
}: Props) {
  const token = localStorage.getItem("token");
  const [restaurants, setRestaurants] = useState<
    | {
        stores: {
          _id: string;
          name: string;
          phone_number: number;
          address: {
            street_addr: string;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            street_addr_2: string;
            latitude: number;
            longitude: number;
          };
          description: string;
          local_hours: {
            operational: {
              Monday: string;
              Tuesday: string;
              Wednesday: string;
              Thursday: string;
              Friday: string;
              Saturday: string;
              Sunday: string;
            };
            delivery: {
              Monday: string;
              Tuesday: string;
              Wednesday: string;
              Thursday: string;
              Friday: string;
              Saturday: string;
              Sunday: string;
            };
            pickup: {
              Monday: string;
              Tuesday: string;
              Wednesday: string;
              Thursday: string;
              Friday: string;
              Saturday: string;
              Sunday: string;
            };
            dine_in: {
              Monday: string;
              Tuesday: string;
              Wednesday: string;
              Thursday: string;
              Friday: string;
              Saturday: string;
              Sunday: string;
            };
          };
          cuisines: string[];
          food_photos: string[];
          logo_photos: string[];
          store_photos: string[];
          dollar_signs: number;
          pickup_enabled: boolean;
          delivery_enabled: boolean;
          is_open: boolean;
          offers_first_party_delivery: boolean;
          offers_third_party_delivery: boolean;
          quote_ids: string[];
          miles: number;
          weighted_rating_value: number;
          aggregated_rating_count: number;
          supports_upc_codes: boolean;
          type: "restaurant";
        }[];
        next_page: number;
      }
    | undefined
  >();
  const [customRestaurants, setCustomRestaurants] = useState<
    userRestaurantResponseType[] | undefined
  >();
  let timer = setTimeout(() => {}, 300);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState<{
    id: number;
    name: string;
  } | null>({ id: 1, name: "Gatherwise restaurants" });
  const [page, setPage] = useState(1);
  const [selectedView, setSelectedView] = useState<1 | 2>(1);
  const [orderTypeModalIsOpen, setOrderTypeModalIsOpen] = useState<{
    resolve: (value: any) => void;
  } | null>(null);
  const [newRestaurantModalIsOpen, setNewRestaurantModalIsOpen] =
    useState(false);

  const pageHasBeenRendered = useRef(false);

  useEffect(() => {
    if (pageHasBeenRendered.current) {
      clearTimeout(timer);

      timer = setTimeout(async () => {
        setIsLoading(true);
        fetchRestaurants({
          token,
          search: searchValue,
          isPickup,
          isOpen: false,
          location,
          latitude,
          longitude,
          agendaBlockStartTime,
          dayId,
          page,
        }).then((res) => {
          setRestaurants(res);
          setIsLoading(false);
        });

        fetchCustomRestaurants({
          token,
          search: searchValue,
          skip: 0,
          take: 10,
          latitude: `${latitude}`,
          longitude: `${longitude}`,
        }).then((res) => {
          setCustomRestaurants(res);
          setIsLoading(false);
        });
      }, 400);

      return () => clearTimeout(timer);
    }
  }, [searchValue]);

  useEffect(() => {
    setIsLoading(true);
    fetchRestaurants({
      token,
      search: searchValue,
      isPickup,
      isOpen: false,
      location,
      latitude,
      longitude,
      agendaBlockStartTime,
      dayId,
      page,
    }).then((res) => {
      setRestaurants(res);
      setIsLoading(false);
    });

    fetchCustomRestaurants({
      token,
      search: searchValue,
      skip: 0,
      take: 10,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
    }).then((res) => {
      setCustomRestaurants(res);
      setIsLoading(false);
    });
  }, [isPickup, location, latitude, longitude, token, currentTab]);

  useEffect(() => {
    pageHasBeenRendered.current = true;
  }, [])

  const onOrderTypeSelect = (state: boolean) =>
    setCreatorIsOrder((prevState) => {
      const currentItem = prevState.find((it) => it.id === agendaBlockId);

      return [
        ...prevState.filter((it) => it.id !== currentItem?.id),
        {
          id: currentItem?.id || agendaBlockId,
          state: state,
        },
      ];
    });

  const onRestaurantSelect = () =>
    new Promise<any>((resolve, reject) => {
      try {
        setOrderTypeModalIsOpen({ resolve });
      } catch (error) {
        reject(error);
      }
    });

  return (
    <div className="selectRestaurant selectRestaurant-withoutPadding">
      {orderTypeModalIsOpen?.resolve
        ? createPortal(
            <CustomModal
              setIsOpen={() => setOrderTypeModalIsOpen(null)}
              title={"Select meals"}
              description={
                "How would you like to select your meals, you can let your attendees choose or you can choose for them"
              }
            >
              <div className="flex flex-column fullWidth gap-52 items-end">
                <OrderSelectionDropdown
                  creatorIsOrder={
                    creatorIsOrder === null ? null : creatorIsOrder
                  }
                  title={"Meal selection"}
                  content={[
                    {
                      id: 1,
                      name: "I'll order for everyone",
                      organizerOrder: false,
                    },
                    {
                      id: 2,
                      name: "Attendees can select their own food choices",
                      organizerOrder: true,
                    },
                  ]}
                  onSelect={onOrderTypeSelect}
                />
                <Button
                  size={"md"}
                  hierarchy={"newDesign-primary"}
                  buttonType={"regular"}
                  newDesignPaddingNone
                  onClick={() => {
                    orderTypeModalIsOpen.resolve(creatorIsOrder);
                    setOrderTypeModalIsOpen(null);
                  }}
                >
                  Select meals
                </Button>
              </div>
            </CustomModal>,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {newRestaurantModalIsOpen
        ? createPortal(
            <AddNewRestaurantModal
              setShow={() =>
                setNewRestaurantModalIsOpen((prevState) => !prevState)
              }
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      <div className="selectRestaurant-main selectRestaurant-main-withoutFooter">
        <header className="selectRestaurant-main-header">
          <div
            className="flex flex-row gap-12 items-center border-gray-200 fullWidth"
            style={{
              position: "relative",
              zIndex: "30",
              padding: "0 0 24px 24px",
            }}
          >
            <img
              className="selectRestaurant-main-info-backButton"
              src={arrowLeft}
              alt=""
              onClick={() => setShowSelectRestaurantModal(false)}
            />
            <p className="text-xl font-semibold color-gray-900">
              Back to agenda block
            </p>
          </div>
        </header>
        {isDesktopSize ? null : (
          <div
            className={`eventsPage-toggle ${
              showSelectRestaurantModal ? "" : "positionStatic"
            }`}
            style={{ bottom: "75px" }}
          >
            <ToggleButtons
              type={isDesktopSize ? "default" : "gray"}
              buttons={{
                first: {
                  id: 1,
                  name: "List",
                  onSelect: () => setSelectedView(1),
                  image: listBlackIcon,
                  imageSelected: listWhiteIcon,
                },
                second: {
                  id: 2,
                  name: "Map",
                  onSelect: () => {
                    setSelectedView(2);
                  },
                  image: mapBlackIcon,
                  imageSelected: mapWhiteIcon,
                },
              }}
              selected={selectedView}
            />
          </div>
        )}
        <div className="flex fullWidth fullHeight">
          {isDesktopSize || selectedView === 1 ? (
            <main
              className={`selectRestaurant-main-info selectRestaurant-main-info-fullHeight ${
                isDesktopSize
                  ? "selectRestaurant-main-withMaxWidth"
                  : "fullWidth"
              }`}
            >
              <div className="selectRestaurant-main-header-info">
                <h3 className="selectRestaurant-main-header-info-text">
                  Add restaurant
                </h3>
                <div className="selectRestaurant-main-info-location">
                  <img src={markerIcon} alt="" />
                  <p className="text-xs font-semibold color-gray-900">
                    {location}
                  </p>
                </div>
              </div>
              <div className="flex fullWidth gap-12 items-end fullWidth">
                <FormInput
                  type="SearchGray"
                  label={"Search for a place"}
                  hasIconBefore
                  design="new"
                  value={searchValue}
                  fullWidth
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              {isLoading ? (
                <div className="flex items-center fullWidth justifyCenter">
                  <Loader size={"xl"} />
                </div>
              ) : (
                <>
                  <p>
                    {(restaurants?.stores.length || 0) +
                      (customRestaurants?.length || 0)}{" "}
                    results nearby
                  </p>
                  <ToggleButtons
                    type="gray"
                    buttons={{
                      first: {
                        id: 1,
                        name: "Delivery",
                        onSelect: () => {
                          setIsPickup((prevState) => [
                            ...prevState.filter(
                              (it) => it.id !== agendaBlockId
                            ),
                            { id: agendaBlockId, state: false },
                          ]);
                        },
                        image: routeBlack,
                        imageSelected: routeWhite,
                      },
                      second: {
                        id: 2,
                        name: "Pickup",
                        onSelect: () => {
                          setIsPickup((prevState) => [
                            ...prevState.filter(
                              (it) => it.id !== agendaBlockId
                            ),
                            { id: agendaBlockId, state: true },
                          ]);
                        },
                        image: shoppingBagBlack,
                        imageSelected: shoppingBagWhite,
                      },
                    }}
                    selected={isPickup ? 2 : 1}
                    secondButtonPaddingNone
                  />
                  <div
                    className="selectRestaurant-main-info-restaurants"
                    id="scrollableDiv"
                  >
                    {restaurants?.stores.length || customRestaurants?.length ? (
                      <div className="mb-120 flex flex-column gap-24">
                        {restaurants?.stores.length ? (
                          <InfiniteScroll
                            dataLength={restaurants.stores.length}
                            next={async () => {
                              const res = await fetchRestaurants({
                                token,
                                search: searchValue,
                                isPickup,
                                isOpen: false,
                                location,
                                latitude,
                                longitude,
                                agendaBlockStartTime,
                                dayId,
                                page,
                              });

                              if (res) {
                                setRestaurants((prevState) => {
                                  const combinedRestaurants = [
                                    ...prevState!.stores,
                                    ...res!.stores,
                                  ];
                                  return {
                                    stores: combinedRestaurants,
                                    next_page: res!.next_page,
                                  };
                                });
                              }

                              setPage((prevState) => prevState + 1);
                            }}
                            hasMore={page < restaurants.next_page}
                            loader={
                              <div className="flex fullWidth justifyCenter">
                                <Loader size={"xl"} />
                              </div>
                            }
                            scrollableTarget="scrollableDiv"
                          >
                            <div className="flex flex-column gap-16">
                              {restaurants?.stores.map((restaurant, index) => (
                                <React.Fragment key={restaurant._id}>
                                  <RestaurantItem
                                    id={restaurant._id}
                                    name={restaurant.name}
                                    status={restaurant.is_open}
                                    location={restaurant.address.street_addr}
                                    distance={restaurant.miles}
                                    cuisines={restaurant.cuisines}
                                    image={restaurant.logo_photos[0]}
                                    setSelectedRestaurant={
                                      setSelectedRestaurant
                                    }
                                    selectedRestaurantId={
                                      selectedRestaurant?.id
                                    }
                                    key={restaurant._id}
                                    restaurantsForOrders={restaurantsForOrders}
                                    setRestaurantsForOrders={
                                      setRestaurantsForOrders
                                    }
                                    agendaBlockId={agendaBlockId}
                                    onRestaurantSelect={onRestaurantSelect}
                                  />
                                  {index + 1 < restaurants.stores.length ? (
                                    <div className="selectRestaurant-main-info-restaurants-divider"></div>
                                  ) : null}
                                </React.Fragment>
                              ))}
                            </div>
                          </InfiniteScroll>
                        ) : null}
                        {customRestaurants?.length ? (
                          <InfiniteScroll
                            dataLength={customRestaurants.length}
                            next={async () => {
                              const res = await fetchCustomRestaurants({
                                token,
                                search: searchValue,
                                skip: page * 10,
                                take: 10,
                                latitude: `${latitude}`,
                                longitude: `${longitude}`,
                              });

                              if (res) {
                                setCustomRestaurants((prevState) => {
                                  const combinedRestaurants = [
                                    ...prevState!,
                                    ...res,
                                  ];

                                  return combinedRestaurants;
                                });
                              }

                              setPage((prevState) => prevState + 1);
                            }}
                            hasMore={
                              customRestaurants.length > 10 &&
                              customRestaurants.length < 11
                            }
                            loader={
                              <div className="flex fullWidth justifyCenter">
                                {customRestaurants.length < 11 ? (
                                  <Loader size={"xl"} />
                                ) : null}
                              </div>
                            }
                            scrollableTarget="scrollableDiv"
                          >
                            <div className="flex flex-column gap-16">
                              {customRestaurants.map((restaurant) => (
                                <UserRestaurantItem
                                  id={restaurant.id}
                                  name={restaurant.name}
                                  location={restaurant.location}
                                  setSelectedRestaurant={setSelectedRestaurant}
                                  selectedRestaurantId={selectedRestaurant?.id}
                                  key={restaurant.id}
                                  menuLink={restaurant.url}
                                />
                              ))}
                            </div>
                          </InfiniteScroll>
                        ) : null}
                      </div>
                    ) : (
                      <div className="modalInvite-noResult-container">
                        <div className="modalInvite-noResult-content">
                          <div className="homePage-content-todos-listIcon">
                            <img
                              width={40}
                              height={40}
                              src={searchIcon}
                              alt=""
                            />
                          </div>
                          <div className="modalInvite-noResult-content-info">
                            <p className="modalInvite-noResult-content-info-title">
                              No results found
                            </p>
                            {searchValue.length ? (
                              <p className="modalInvite-noResult-content-info-description">{`Your search “${searchValue}” did not match any restaurant. Please try again or add a new restaurant.`}</p>
                            ) : null}
                            <Button
                              size={"md"}
                              hierarchy={"newDesign-secondary"}
                              buttonType={"regular"}
                              newDesignPaddingNone
                              onClick={() => setNewRestaurantModalIsOpen(true)}
                            >
                              <p className="color-gray-900">
                                Add new restaurant
                              </p>
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </main>
          ) : null}
          {(isDesktopSize || selectedView === 2) &&
          showSelectRestaurantModal ? (
            <MapboxMap
              restaurants={restaurants}
              setSelectedRestaurant={setSelectedRestaurant}
              setRestaurantsForOrders={setRestaurantsForOrders}
              agendaBlockId={agendaBlockId}
              location={location}
              lng={longitude}
              lat={latitude}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
