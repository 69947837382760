import React, { useState, useEffect, useContext } from "react";
import { createPortal } from "react-dom";
import ModalNotification from "../../../components/Modal/ModalNotification";
import { Button } from "../../../components/Buttons/Button";
import ModalAgenda from "../../../components/Modal/ModalAgenda";
import AgendaItem from "../../../components/Agenda/AgendaItem";
import { editTime } from "../../../utilities/EditAgendaBlockTime";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import closeIcon from "../../../assets/images/x-close-black.svg";
import { onDragEnd } from "../../../utilities/onDragEnd";
import { EventInfoContext } from "../../../utilities/eventInfoContext";
import { useNavigate, useParams } from "react-router-dom";
import ModalSaveAsDraft from "../../../components/Modal/ModalSaveAsDraft";
import { getItemBeforeEndTime } from "../../../utilities/getItemBeforeEndTime";
import ModalAgendaExtendsBeyondEndTime from "../../../components/Modal/ModalAgendaExtendsBeyondEndTime";
import {
  editEventAgenda,
  fetchEvent,
  fetchEventAgenda,
  postEventAgenda,
  postMeals,
  postMealsSelections,
  postPreSelect,
  postRestaurants,
} from "../../../httpQueries/http";
import presentationIcon from "../../../assets/images/presentation-chart-01.svg";
import clockPlusIcon from "../../../assets/images/clock-plus.svg";
import happyFaceIcon from "../../../assets/images/face-happy.svg";
import { durations } from "../../../utilities/agendaDurationsArray";
import { supabase } from "../../../utilities/supabaseClient";
import { AgendaBlockType } from "../../../types/agendaBlockType";
import moment from "moment";
import { getUTCTimeFromMinutes } from "../../../utilities/getUTCTimeFromMinutes";
import { MealBlockChoice, MealBlockType } from "../../../types/meals";
import arrowLeft from "../../../assets/images/arrow-narrow-left.svg";
import { getEventTime } from "../../../utilities/getEventStartTime";

interface Props {
  isDesktopSize: boolean;
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    mealBlockId?: number;
    index: number;
  }[];
  setAgenda: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
        mealBlockId?: number;
        index: number;
      }[]
    >
  >;
  isEditActions?: boolean;
  setSelectedStartTime: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
    } | null>
  >;
  setSelectedEndTime: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
    } | null>
  >;
  setFirstDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  setDescriptionInputValue: React.Dispatch<React.SetStateAction<string>>;
  setNameInputValue: React.Dispatch<React.SetStateAction<string>>;
  setEventIds: React.Dispatch<
    React.SetStateAction<{
      eventId: number;
      eventDayId: number;
    } | null>
  >;
  setSelectedLocation: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      supportText?: string | undefined;
      lat?: string;
      lng?: string;
    } | null>
  >;
  setLocationNotes: React.Dispatch<React.SetStateAction<string>>;
  setSelectedZone: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      timezone?: string;
    } | null>
  >;
  setCurrentLastStep: React.Dispatch<React.SetStateAction<number>>;
  currentLastStep: number;
  eventSlug: string;
  setSlug: React.Dispatch<React.SetStateAction<string>>;
}

export default function EventAgenda({
  isDesktopSize,
  agenda,
  setAgenda,
  isEditActions,
  setDescriptionInputValue,
  setEventIds,
  setFirstDate,
  setLocationNotes,
  setNameInputValue,
  setSelectedEndTime,
  setSelectedLocation,
  setSelectedStartTime,
  setSelectedZone,
  setCurrentLastStep,
  currentLastStep,
  eventSlug,
  setSlug,
}: Props) {
  const currentStep = 2;
  const [contentIsScrolled, setContentIsScrolled] = useState(false);
  const [modalNotificationIsOpen, setModalNotificationIsOpen] = useState<{
    name: string;
    description: string;
    firstButtonName: string;
    secondButtonName: string;
    onFirstButtonClick: () => void;
    onSecondButtonClick: () => void;
  } | null>(null);
  const [showAgendaModal, setShowAgendaModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<
    | {
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
        index: number;
      }
    | undefined
  >();
  const [isEdit, setIsEdit] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  const [itemBeforeEndTime, setItemBeforeEndTime] = useState<{
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
  } | null>(null);
  const [agendaExtendsBeyondEndTime, setAgendaExtendsBeyondEndTime] =
    useState(false);
  const [showAgendaExtendsBeyondEndTime, setShowAgendaExtendsBeyondEndTime] =
    useState(false);
  const [isSavedAsDraft, setIsSavedAsDraft] = useState(false);
  const { startTime, endTime, eventIds, location, timeZone } =
    useContext(EventInfoContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const slug = useParams().slug;
  const [orders, setOrders] = useState<
    {
      agendaBlockId: number;
      customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
      }[];
      productId: string;
      markedPrice: number;
      notes: string;
      count: number;
    }[]
  >([]);
  const [
    selectedAvailableMealsForAttendees,
    setSelectedAvailableMealsForAttendees,
  ] = useState<{ id: number; productsIds: string[] }[]>([]);
  const [mealSelectedOrders, setMealSelectedOrders] = useState<
    {
      restaurantId: string | number;
      mealAgendaItemId: string;
      orderedMeals: {
        customizations?: [
          {
            customizationId: string;
            optionId: string;
            markedPrice: number;
          }
        ];
        productId: string;
        markedPrice: number;
        notes: string;
      }[];
    }[]
  >([]);
  const [isPickup, setIsPickup] = useState<{ id: number; state: boolean }[]>(
    []
  );
  const [creatorIsOrder, setCreatorIsOrder] = useState<
    { id: number; state: boolean | null }[]
  >([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState<{
    id: string | number;
    name: string;
    status?: "Open" | "Close";
    logo: string;
    address: string;
    distance?: number;
    cuisines?: string[];
    menuLink?: string,
  } | null>(null);
  const [restaurantsForOrders, setRestaurantsForOrders] = useState<
    {
      id: string | number;
      name: string;
      status?: "Open" | "Close";
      logo: string;
      address: string;
      distance?: number;
      cuisines?: string[];
      agendaBlockId: number;
      menuLink?: string,
    }[]
  >([]);
  const [mealBlocksWithoutMealsSelection, setMealBlocksWithoutMealsSelection] =
    useState<string[]>([]);

  async function loadEditableEventData() {
    const data = await fetchEvent({ slug, token });
    return data;
  }

  useEffect(() => {
    if (isEditActions) {
      loadEditableEventData().then((data) => {
        const startTime = getEventTime(data!.startTime, data?.timezoneName);
        const endTime = data?.endTime
          ? getEventTime(data!.endTime, data?.timezoneName)
          : null;

        fetchEventAgenda({ dayId: data!.id, token }).then((res) => {
          const agenda = editTime(
            res!
              .sort((it1, it2) => it1.index - it2.index)
              .map((item, index) => ({
                id: `${item.id}`,
                icon:
                  item.type === AgendaBlockType.Break
                    ? clockPlusIcon
                    : item.type === AgendaBlockType.Meal
                    ? happyFaceIcon
                    : presentationIcon,
                name: item.name,
                description: item.description,
                duration: durations.find(
                  (duration) => duration.value === item.duration
                ) || {
                  id: 1,
                  name: `${item.duration} min`,
                  value: item.duration,
                },
                startTime:
                  getEventTime(data!.startTime, data?.timezoneName)?.name ?? "",
                endTime: data?.endTime
                  ? getEventTime(data!.endTime, data?.timezoneName)?.name ?? ""
                  : "",
                type: item.type,
                mealBlockId: item.mealBlockId,
                index,
              })),
            getEventTime(data!.startTime, timeZone?.timezone)?.name ?? ""
          );
          setAgenda(agenda!);
        });
        setFirstDate(moment(data!.startDate));
        setDescriptionInputValue(data!.description);
        setNameInputValue(data!.name);
        setSelectedEndTime(endTime || null);
        setSelectedLocation({
          id: 1,
          name: data!.location,
          supportText: "",
          lat: data?.locationLatitude ? data.locationLatitude : "0",
          lng: data?.locationLongitude ? data.locationLongitude : "0",
        });
        setLocationNotes(data?.locationNote || "");
        setSelectedStartTime(startTime!);
        setSelectedZone(
          data?.timezone
            ? { id: 1, name: data.timezone, timezone: data.timezoneName }
            : null
        );
        setEventIds({ eventId: data!.id, eventDayId: data!.days[0].id });
        setSlug(data!.slug);
      });
    }
  }, [isEditActions]);

  useEffect(() => {
    if (!startTime && !isEditActions) {
      navigate("/");
    }

    if (currentLastStep < currentStep) {
      setCurrentLastStep(currentStep);
    }
  }, []);

  useEffect(() => {
    if (startTime) {
      editTime(agenda, startTime.name, setAgenda);
    }
    if (endTime) {
      getItemBeforeEndTime(
        endTime,
        agenda,
        setAgendaExtendsBeyondEndTime,
        setItemBeforeEndTime
      );
    }
  }, [agenda.length]);

  useEffect(() => {
    if (isEdit === false && startTime) {
      editTime(agenda, startTime.name, setAgenda);
      if (endTime) {
        getItemBeforeEndTime(
          endTime,
          agenda,
          setAgendaExtendsBeyondEndTime,
          setItemBeforeEndTime
        );
      }
      setCurrentItem(undefined);
    }
  }, [isEdit, startTime]);

  useEffect(() => {
    if (isDrag === true) {
      editTime(agenda, startTime!.name, setAgenda);
      if (endTime) {
        getItemBeforeEndTime(
          endTime,
          agenda,
          setAgendaExtendsBeyondEndTime,
          setItemBeforeEndTime
        );
      }
      setIsDrag(false);
    }
  }, [isDrag]);

  useEffect(() => {
    if (isSavedAsDraft === true) {
      setTimeout(() => setIsSavedAsDraft(false), 5000);
    }
  }, [isSavedAsDraft]);

  useEffect(() => {
    if (agendaExtendsBeyondEndTime) {
      setShowAgendaExtendsBeyondEndTime(true);
    }
  }, [agendaExtendsBeyondEndTime]);

  const onContinue = async () => {
    const agendaItems = agenda.map((item, i) => ({
      name: item.name,
      index: i,
      description: item.description,
      duration: item.duration.value || 0,
      type: item.type,
      dayId: eventIds!.eventDayId,
    }));

    const response = await postEventAgenda({
      dayId: eventIds!.eventId,
      body: agendaItems,
      token,
    });

    if (response?.status === 200 || response?.status === 201) {
      response.data
        .sort((item1, item2) => item1.index - item2.index)
        .map(async (agendaItem, index) => {
          const selectedRestaurant = restaurantsForOrders.find(
            (item) => item.agendaBlockId === index
          );

          if (
            agendaItem.type === AgendaBlockType.Meal &&
            selectedRestaurant &&
            (orders.find((order) => order.agendaBlockId === index)?.productId ||
              selectedAvailableMealsForAttendees.find(
                (order) => order.id === index
              )?.productsIds.length)
          ) {
            const mealId = agendaItem.mealBlock?.id;

            const postedMealResponse = await postMeals({
              body: {
                type: !!isPickup.find((it) => it.id === index)?.state
                  ? MealBlockType.Pickup
                  : MealBlockType.Delivery,
                choice: !!creatorIsOrder.find((it) => it.id === index)?.state
                  ? MealBlockChoice.PreSelected
                  : MealBlockChoice.Organizer,
                agendaItemId: agendaItem.id!,
              },
              token,
            });

            const restaurantQueryBody =
              typeof selectedRestaurant.id === "number"
                ? { customRestaurantId: selectedRestaurant.id }
                : { restaurantId: selectedRestaurant!.id };
            await postRestaurants({
              id: postedMealResponse!.id,
              body: restaurantQueryBody,
              token,
            });

            if (!creatorIsOrder.find((it) => it.id === index)?.state) {
              Promise.all(
                orders
                  .filter((order) => order.agendaBlockId === index)
                  .map(async (order) => {
                    for (let i = 0; i < order.count; i++) {
                      await postMealsSelections({
                        id: postedMealResponse!.id,
                        body: {
                          customizations: order.customizations,
                          productId: order.productId,
                          markedPrice: order.markedPrice,
                          notes: order.notes,
                        },
                        token,
                      });
                    }
                  })
              );
            } else if (!!creatorIsOrder.find((it) => it.id === index)?.state) {
              const currentOrderIds: string[] = [];

              selectedAvailableMealsForAttendees
                .filter((order) => order.id === index)
                .map((item) => {
                  currentOrderIds.push(...item.productsIds);
                });

              await postPreSelect({
                id: postedMealResponse!.id,
                body: {
                  productIds: currentOrderIds,
                },
                token,
              });
            }
          } 
          // else if (agendaItem.type === AgendaBlockType.Meal && selectedRestaurant && selectedRestaurant.menuLink) {
          //   const postedMealResponse = await postMeals({
          //     body: {
          //       type: !!isPickup.find((it) => it.id === index)?.state
          //         ? MealBlockType.Pickup
          //         : MealBlockType.Delivery,
          //       choice: !!creatorIsOrder.find((it) => it.id === index)?.state
          //         ? MealBlockChoice.PreSelected
          //         : MealBlockChoice.Organizer,
          //       agendaItemId: agendaItem.id!,
          //     },
          //     token,
          //   });

          //   const restaurantQueryBody =
          //     typeof selectedRestaurant.id === "number"
          //       ? { customRestaurantId: selectedRestaurant.id }
          //       : { restaurantId: selectedRestaurant!.id };
          //   await postRestaurants({
          //     id: postedMealResponse!.id,
          //     body: restaurantQueryBody,
          //     token,
          //   });
          // }
        });
      if (isEditActions) {
        if (currentStep + 1 >= currentLastStep && !isEditActions) {
          navigate(`/createEvent/inviteAttendees`);
        } else {
          navigate(`/edit/${slug}/inviteAttendees`);
        }
      } else {
        navigate("/createEvent/inviteAttendees");
      }
    } else {
      const { error } = await supabase().auth.signOut();
      localStorage.clear();
      localStorage.clear();

      navigate("/login");
    }
  };

  const onEditContinue = async () => {
    const agendaItems = agenda.map((item, i) => ({
      id: +item.id ? +item.id : undefined,
      name: item.name,
      index: i,
      description: item.description,
      duration: item.duration.value || 0,
      type: item.type,
      dayId: eventIds!.eventDayId,
      mealBlockId: item.mealBlockId,
    }));

    const response = await editEventAgenda({
      dayId: eventIds!.eventId,
      body: agendaItems,
      token,
    });

    console.log(response?.status);
    

    if (response?.status === 200 || response?.status === 201) {
      response.data
        .sort((item1, item2) => item1.index - item2.index)
        .map(async (agendaItem, index) => {
          if (
            agendaItem.type === AgendaBlockType.Meal &&
            selectedRestaurant &&
            (orders.find((order) => order.agendaBlockId === index)?.productId ||
              selectedAvailableMealsForAttendees.find(
                (order) => order.id === index
              )?.productsIds.length)
          ) {
            const mealId = agendaItem.mealBlockId;
            const selectedRestaurant = restaurantsForOrders.find(
              (item) => item.agendaBlockId === index
            );

            if (mealId) {
              return;
            }

            const postedMealResponse = await postMeals({
              body: {
                type: !!isPickup.find((it) => it.id === index)?.state
                  ? MealBlockType.Pickup
                  : MealBlockType.Delivery,
                choice: !!creatorIsOrder.find((it) => it.id === index)?.state
                  ? MealBlockChoice.PreSelected
                  : MealBlockChoice.Organizer,
                agendaItemId: agendaItem.id!,
              },
              token,
            });

            const restaurantQueryBody =
              typeof selectedRestaurant?.id === "number"
                ? { customRestaurantId: selectedRestaurant.id }
                : { restaurantId: selectedRestaurant?.id };
            const postedRestaurantResponse = await postRestaurants({
              id: postedMealResponse!.id,
              body: restaurantQueryBody,
              token,
            });

            if (!creatorIsOrder.find((it) => it.id === index)?.state) {
              Promise.all(
                orders.map(async (order) => {
                  for (let i = 0; i < order.count; i++) {
                    await postMealsSelections({
                      id: postedMealResponse!.id,
                      body: {
                        customizations: order.customizations,
                        productId: order.productId,
                        markedPrice: order.markedPrice,
                        notes: order.notes,
                      },
                      token,
                    });
                  }
                })
              );
            } else if (!!creatorIsOrder.find((it) => it.id === index)?.state) {
              const currentOrderIds: string[] = [];

              selectedAvailableMealsForAttendees
                .filter((order) => order.id === index)
                .map((item) => {
                  currentOrderIds.push(...item.productsIds);
                });

              await postPreSelect({
                id: postedMealResponse!.id,
                body: {
                  productIds: currentOrderIds,
                },
                token,
              });
            }
          }
        });
    }

    navigate(`/edit/${slug}/inviteAttendees`);
  };

  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear();
    localStorage.clear();

    navigate("/login");

    return null;
  }

  return (
    <div className="fullHeight fullWidth flex flex-column gap-24">
      <div className="eventDetails_main-header eventDetails_main-header-absolute">
        <h2 className="color-gray-900 text-xl font-semibold">Create event</h2>
        <img
          src={closeIcon}
          alt=""
          onClick={() => navigate("/events?filter=organizer")}
        />
      </div>
      <div
        className="overflowYScroll eventDetails_content-absolute"
        style={{ minHeight: "75%" }}
      >
        <div
          className="eventDetails_main eventDetails_main-createEvent"
          style={{
            height: "fit-content",
            marginBottom: "100px",
            minHeight: "100%",
          }}
        >
          {modalNotificationIsOpen
            ? createPortal(
                <ModalNotification
                  setIsOpen={setModalNotificationIsOpen}
                  name={modalNotificationIsOpen.name}
                  description={modalNotificationIsOpen.description}
                  onFirstButton={modalNotificationIsOpen.onFirstButtonClick}
                  onSecondButton={modalNotificationIsOpen.onSecondButtonClick}
                  firstButtonName={modalNotificationIsOpen.firstButtonName}
                  secondButtonName={modalNotificationIsOpen.secondButtonName}
                />,
                document.getElementById("modal") as HTMLElement
              )
            : null}
          {showAgendaExtendsBeyondEndTime
            ? createPortal(
                <ModalAgendaExtendsBeyondEndTime
                  setIsOpen={setShowAgendaExtendsBeyondEndTime}
                />,
                document.getElementById("modal") as HTMLElement
              )
            : null}
          {isSavedAsDraft ? (
            <ModalSaveAsDraft setIsOpen={setIsSavedAsDraft} />
          ) : null}
          <div
            className="eventDetails_content eventDetails_content-agenda"
            id="mobileEventDetailsContent"
            onScroll={() =>
              setContentIsScrolled(
                (
                  document.getElementById(
                    "mobileEventDetailsContent"
                  ) as HTMLElement
                ).scrollTop >= 32
              )
            }
          >
            <div
              className="eventDetails_main-content eventDetails_main-content-agenda"
              style={{ minHeight: "80%" }}
            >
              <div className="flex gap-12 items-center">
                <img
                  className="selectRestaurant-main-info-backButton"
                  src={arrowLeft}
                  alt=""
                  onClick={() => navigate(`/edit/${eventSlug}/details`)}
                />
                <h3 className="xs font-semibold color-gray-900">Agenda</h3>
              </div>
              {agenda.length === 0 ? (
                <div
                  className="eventAgenda-createNotification"
                  onClick={() => setShowAgendaModal(true)}
                >
                  Add block
                </div>
              ) : (
                <DragDropContext
                  onDragEnd={(result) =>
                    onDragEnd(result, setIsDrag, agenda, setAgenda)
                  }
                >
                  <Droppable droppableId="droppable-1" type="AGENDA">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className="eventDetails_main-content-agenda-draggable"
                        {...provided.droppableProps}
                        style={{
                          paddingBottom: snapshot.isDraggingOver
                            ? "105px"
                            : "0",
                        }}
                      >
                        {agenda
                          .sort((it1, it2) => it1.index - it2.index)
                          .map((item, i) => (
                            <React.Fragment key={item.id}>
                              <Draggable draggableId={item.id} index={i}>
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <AgendaItem
                                        {...item}
                                        setAgenda={setAgenda}
                                        setShowAgendaModal={setShowAgendaModal}
                                        setCurrentItem={setCurrentItem}
                                        dragHandleProps={
                                          provided.dragHandleProps
                                        }
                                        hasDragElement
                                        hasEditActions
                                        height="full"
                                        isAgendaMealBlockWithoutMeals={
                                          orders.find(
                                            (order) =>
                                              order.agendaBlockId ===
                                              item.mealBlockId
                                          ) &&
                                          item.type === AgendaBlockType.Meal
                                        }
                                      />
                                    </div>
                                  );
                                }}
                              </Draggable>
                              {itemBeforeEndTime &&
                              endTime &&
                              item.id === itemBeforeEndTime.id ? (
                                <div className="eventAgenda-endTime">
                                  <div className="eventAgenda-endTime-info">
                                    <h3
                                      className={`eventAgenda-endTime-info-end  ${
                                        agendaExtendsBeyondEndTime
                                          ? "eventAgenda-endTime-info-end-error"
                                          : "eventAgenda-endTime-info-end-info"
                                      }`}
                                    >
                                      {endTime.name}
                                    </h3>
                                    <p className="eventAgenda-endTime-info-description">
                                      End of event
                                    </p>
                                  </div>
                                  <div className="eventAgenda-endTime-rectangles">
                                    <div
                                      className={`
                                                eventAgenda-endTime-rectangles-rectangle 
                                                ${
                                                  agendaExtendsBeyondEndTime
                                                    ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                    : "eventAgenda-endTime-rectangles-rectangle-info"
                                                }
                                              `}
                                    />
                                    <div
                                      className={`
                                                eventAgenda-endTime-rectangles-rectangle 
                                                ${
                                                  agendaExtendsBeyondEndTime
                                                    ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                    : "eventAgenda-endTime-rectangles-rectangle-info"
                                                }
                                              `}
                                    />
                                    <div
                                      className={`
                                                eventAgenda-endTime-rectangles-rectangle 
                                                ${
                                                  agendaExtendsBeyondEndTime
                                                    ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                    : "eventAgenda-endTime-rectangles-rectangle-info"
                                                }
                                              `}
                                    />
                                    <div
                                      className={`
                                                eventAgenda-endTime-rectangles-rectangle 
                                                ${
                                                  agendaExtendsBeyondEndTime
                                                    ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                    : "eventAgenda-endTime-rectangles-rectangle-info"
                                                }
                                              `}
                                    />
                                    <div
                                      className={`
                                                eventAgenda-endTime-rectangles-rectangle 
                                                ${
                                                  agendaExtendsBeyondEndTime
                                                    ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                    : "eventAgenda-endTime-rectangles-rectangle-info"
                                                }
                                              `}
                                    />
                                    <div
                                      className={`
                                                eventAgenda-endTime-rectangles-rectangle 
                                                ${
                                                  agendaExtendsBeyondEndTime
                                                    ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                    : "eventAgenda-endTime-rectangles-rectangle-info"
                                                }
                                              `}
                                    />
                                    <div
                                      className={`
                                                eventAgenda-endTime-rectangles-rectangle 
                                                ${
                                                  agendaExtendsBeyondEndTime
                                                    ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                    : "eventAgenda-endTime-rectangles-rectangle-info"
                                                }
                                              `}
                                    />
                                    <div
                                      className={`
                                                eventAgenda-endTime-rectangles-rectangle 
                                                ${
                                                  agendaExtendsBeyondEndTime
                                                    ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                    : "eventAgenda-endTime-rectangles-rectangle-info"
                                                }
                                              `}
                                    />
                                    <div
                                      className={`
                                                eventAgenda-endTime-rectangles-rectangle 
                                                ${
                                                  agendaExtendsBeyondEndTime
                                                    ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                    : "eventAgenda-endTime-rectangles-rectangle-info"
                                                }
                                              `}
                                    />
                                    <div
                                      className={`
                                                eventAgenda-endTime-rectangles-rectangle 
                                                ${
                                                  agendaExtendsBeyondEndTime
                                                    ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                    : "eventAgenda-endTime-rectangles-rectangle-info"
                                                }
                                              `}
                                    />
                                    {isDesktopSize ? (
                                      <>
                                        <div
                                          className={`
                                                        eventAgenda-endTime-rectangles-rectangle 
                                                        ${
                                                          agendaExtendsBeyondEndTime
                                                            ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                            : "eventAgenda-endTime-rectangles-rectangle-info"
                                                        }
                                                      `}
                                        />
                                        <div
                                          className={`
                                                        eventAgenda-endTime-rectangles-rectangle 
                                                        ${
                                                          agendaExtendsBeyondEndTime
                                                            ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                            : "eventAgenda-endTime-rectangles-rectangle-info"
                                                        }
                                                      `}
                                        />
                                        <div
                                          className={`
                                                        eventAgenda-endTime-rectangles-rectangle 
                                                        ${
                                                          agendaExtendsBeyondEndTime
                                                            ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                            : "eventAgenda-endTime-rectangles-rectangle-info"
                                                        }
                                                      `}
                                        />
                                        <div
                                          className={`
                                                        eventAgenda-endTime-rectangles-rectangle 
                                                        ${
                                                          agendaExtendsBeyondEndTime
                                                            ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                            : "eventAgenda-endTime-rectangles-rectangle-info"
                                                        }
                                                      `}
                                        />
                                        <div
                                          className={`
                                                        eventAgenda-endTime-rectangles-rectangle 
                                                        ${
                                                          agendaExtendsBeyondEndTime
                                                            ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                            : "eventAgenda-endTime-rectangles-rectangle-info"
                                                        }
                                                      `}
                                        />
                                        <div
                                          className={`
                                                        eventAgenda-endTime-rectangles-rectangle 
                                                        ${
                                                          agendaExtendsBeyondEndTime
                                                            ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                            : "eventAgenda-endTime-rectangles-rectangle-info"
                                                        }
                                                      `}
                                        />
                                        <div
                                          className={`
                                                        eventAgenda-endTime-rectangles-rectangle 
                                                        ${
                                                          agendaExtendsBeyondEndTime
                                                            ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                            : "eventAgenda-endTime-rectangles-rectangle-info"
                                                        }
                                                      `}
                                        />
                                        <div
                                          className={`
                                                        eventAgenda-endTime-rectangles-rectangle 
                                                        ${
                                                          agendaExtendsBeyondEndTime
                                                            ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                            : "eventAgenda-endTime-rectangles-rectangle-info"
                                                        }
                                                      `}
                                        />
                                        <div
                                          className={`
                                                        eventAgenda-endTime-rectangles-rectangle 
                                                        ${
                                                          agendaExtendsBeyondEndTime
                                                            ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                            : "eventAgenda-endTime-rectangles-rectangle-info"
                                                        }
                                                      `}
                                        />
                                        <div
                                          className={`
                                                        eventAgenda-endTime-rectangles-rectangle 
                                                        ${
                                                          agendaExtendsBeyondEndTime
                                                            ? "eventAgenda-endTime-rectangles-rectangle-error"
                                                            : "eventAgenda-endTime-rectangles-rectangle-info"
                                                        }
                                                      `}
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                            </React.Fragment>
                          ))}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}

              {agenda.length > 0 ? (
                <div className="eventAgenda-addAction">
                  <Button
                    hierarchy="newDesign-secondary"
                    size="lg"
                    buttonType="regular"
                    newDesignPaddingNone
                    contentCenter
                    onClick={() => setShowAgendaModal(true)}
                  >
                    Add another item
                  </Button>
                </div>
              ) : null}

              {showAgendaModal
                ? createPortal(
                    <ModalAgenda
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                      currentItem={currentItem}
                      setIsOpen={setShowAgendaModal}
                      setAgenda={setAgenda}
                      agenda={agenda}
                      location={`${location?.name}${location?.supportText}`}
                      latitude={location?.lat ? +location.lat : 0}
                      longitude={location?.lng ? +location.lng : 0}
                      setMealSelectedOrders={setMealSelectedOrders}
                      dayId={eventIds!.eventDayId}
                      setIsPickup={setIsPickup}
                      isPickup={isPickup}
                      creatorIsOrder={creatorIsOrder}
                      setCreatorIsOrder={setCreatorIsOrder}
                      setSelectedRestaurant={setSelectedRestaurant}
                      selectedRestaurant={selectedRestaurant}
                      setOrders={setOrders}
                      orders={orders}
                      setMealBlocksWithoutMealsSelection={
                        setMealBlocksWithoutMealsSelection
                      }
                      setSelectedAvailableMealsForAttendees={
                        setSelectedAvailableMealsForAttendees
                      }
                      selectedAvailableMealsForAttendees={
                        selectedAvailableMealsForAttendees
                      }
                      agendaBlockId={
                        isEdit ? currentItem!.index : agenda.length
                      }
                      restaurantsForOrders={restaurantsForOrders}
                      setRestaurantsForOrders={setRestaurantsForOrders}
                    />,
                    document.getElementById("modal") as HTMLElement
                  )
                : null}
            </div>
          </div>
        </div>
      </div>
      <div
        className="eventDetails_main-footer"
        style={{ position: "fixed", bottom: "0" }}
      >
        {isDesktopSize ? (
          <Button
            buttonType="regular"
            hierarchy="tertiaryColor"
            size="lg"
            onClick={() =>
              setModalNotificationIsOpen({
                name: "Delete event",
                description:
                  "You have not saved this event yet. If you leave now, your event will be deleted. Are you sure you want to continue?",
                firstButtonName: "Cancel",
                secondButtonName: "Delete event",
                onFirstButtonClick: () => setModalNotificationIsOpen(null),
                onSecondButtonClick: () => navigate("/"),
              })
            }
          >
            Cancel
          </Button>
        ) : null}
        <div
          className={`flex flex-row gap-12 items-center ${
            !isDesktopSize ? "spaceBetween fullWidth" : ""
          }`}
        >
          <Button
            buttonType="regular"
            hierarchy="newDesign-secondary"
            size="lg"
            fullWidth
            contentCenter
            onClick={() => setIsSavedAsDraft(true)}
            newDesignPaddingNone
          >
            <div className="eventDetails_main-footer-buttonSaveAsDraft">
              <p>Save as draft</p>
            </div>
          </Button>
          <Button
            size="lg"
            buttonType="regular"
            hierarchy="newDesign-primary"
            fullWidth
            contentCenter
            disabled={!agenda.length}
            onClick={isEditActions ? onEditContinue : onContinue}
            newDesignPaddingNone
            fullHeight
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
