import React, { useContext, useState } from "react";
import logo from "../../assets/images/logo-lg-color.svg";
import keyIcon from "../../assets/images/key-01.svg";
import { Button } from "../../components/Buttons/Button";
import FeaturedIcon from "../../components/FeaturedIcons/FeaturedIcon";
import { FormInput } from "../../components/Inputs/FormInput";
import checkIcon from "../../assets/images/checkIconGray.svg";
import checkSuccessIcon from "../../assets/images/Check-icon-success.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import googleIcon from "../../assets/images/googleIcon.svg";
import Loader from "../../components/Loader";
import { supabase } from "../../utilities/supabaseClient";
import { checkIsPasswordHasSpecialCharacter } from "../../utilities/passwordHasSpecialCharacter";
import { validateEmail } from "../../utilities/validateEmail";
import { registerUser } from "../../httpQueries/http";
import { RSVPEventValue } from "../../utilities/RSVPEventContext";

export default function LoginByRSVP() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSecondStep, setIsSecondStep] = useState(false);
  const [params] = useSearchParams();
  const { setEventSlug } = useContext(RSVPEventValue);
  const navigate = useNavigate();

  async function LoginUserByGoogle() {
    setIsLoading(true);
    const res = await supabase()
      .auth.signInWithOAuth({
        provider: "google",
      })
      .then(() => {
        sessionStorage.setItem("googleSignUpWorks", "true");
      });
  }

  async function RegisterUser() {
    if (!email.trim().length || !validateEmail(email.trim())) {
      return;
    }

    if (
      !(password.trim().length >= 8) ||
      !checkIsPasswordHasSpecialCharacter(password)
    ) {
      return;
    }

    if (
      email.trim().length &&
      validateEmail(email.trim()) &&
      password.trim().length >= 8 &&
      checkIsPasswordHasSpecialCharacter(password) &&
      name.length
    ) {
      setIsLoading(true);
      const { data } = await supabase().auth.signUp({
        email: email.trim(),
        password: password.trim(),
      });

      if (data.user) {
        try {
          const response = await registerUser({
            user: {
              fullName: name,
              email: data.user.email!,
              id: data.user.id,
            },
          });

          if (response.status >= 200 && response.status < 400) {
            setIsLoading(false);
            setEventSlug(params.get("slug") ?? "");
            navigate("/login");
          }

          if (response.status >= 400) {
            throw Error();
          }
        } catch {
          setIsLoading(false);
        }
      }
    }
  }

  return (
    <div className="accountCreated">
      <header className="accountCreated-header">
        <img src={logo} alt="" className="accountCreated-header-logo" />
      </header>
      <section className="accountCreated-content">
        <div className="accountCreated-content-content">
          <div className="flex flex-column gap-24 items-center">
            <FeaturedIcon icon={keyIcon} size="lg" type="gray" />
            <div className="flex flex-column items-center justifyCenter">
              <h3 className="accountCreated-content-content-title">
                Create an account!
              </h3>
              <p className="accountCreated-content-content-description whiteSpaceNoWrap">
                Welcome, we’re so happy you’re joining the Gatherwise community!
              </p>
            </div>
          </div>
          <div className="flex flex-column gap-24 fullWidth">
            {isSecondStep ? (
              <p className="text-lg font-medium color-gray-600 mx-auto">
                {email}
              </p>
            ) : (
              <FormInput
                type="Email"
                label={email.length ? "Email*" : "Enter your email"}
                design="new"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            )}
            {isSecondStep ? (
              <>
                <FormInput
                  type="Text"
                  label={email.length ? "Full name" : "Full name"}
                  design="new"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <FormInput
                  type="Password"
                  label={email.length ? "Password" : "Password"}
                  design="new"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="flex flex-column gap-12">
                  <div className="flex gap-8">
                    <img
                      src={
                        password.trim().length >= 8
                          ? checkSuccessIcon
                          : checkIcon
                      }
                      alt=""
                    />
                    <p>Must be at least 8 characters</p>
                  </div>
                  <div className="flex gap-8">
                    <img
                      src={
                        checkIsPasswordHasSpecialCharacter(password)
                          ? checkSuccessIcon
                          : checkIcon
                      }
                      alt=""
                    />
                    <p>Must contain one special character</p>
                  </div>
                </div>
              </>
            ) : null}
            {isSecondStep && isLoading ? (
              <div className="flex justifyCenter fullWidth">
                <Loader size="sm" scale="2.5" />
              </div>
            ) : (
              <Button
                buttonType="regular"
                hierarchy="primary"
                size="lg"
                fullWidth
                contentCenter
                asyncOnClick={async () => {
                  if (isSecondStep) {
                    await RegisterUser();
                  } else {
                    setIsSecondStep(true);
                  }
                }}
              >
                {isSecondStep ? "Create account and RSVP" : "Continue"}
              </Button>
            )}
            {isLoading && !isSecondStep ? (
              <div className="flex justifyCenter fullWidth">
                <Loader size="sm" scale="2.5" />
              </div>
            ) : isSecondStep ? (
              <Button
                buttonType="regular"
                hierarchy="linkColor"
                size="lg"
                contentCenter
                onClick={() => setIsSecondStep(false)}
              >
                Change email
              </Button>
            ) : (
              <Button
                buttonType="regular"
                hierarchy="newDesign-terciary"
                newDesignPaddingNone
                size="lg"
                fullWidth
                contentCenter
                asyncOnClick={LoginUserByGoogle}
              >
                <div className="flex gap-12">
                  <img src={googleIcon} alt="" />
                  <p>Sign in with Google</p>
                </div>
              </Button>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
