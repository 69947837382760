import { QueryClient } from "@tanstack/react-query";
import axios from "axios";
// import { baseUrl } from './baseUrl';
import { refreshToken } from "../utilities/refreshToken";
import { logoColor } from "../types/logoColor";
import { logoImage } from "../types/logoImage";
import { AttendeeRSVP } from "../types/attendeeRSVP";
import { EventsFilter } from "../types/eventsFilter";
import { MealBlockChoice, MealBlockState, MealBlockType } from "../types/meals";
import { AgendaBlockType } from "../types/agendaBlockType";
import { userRestaurantResponseType } from "../types/userRestaurantResponse";
import { TodoItem } from "../types/todoItem";

export const queryClient = new QueryClient();

const baseUrl = process.env.REACT_APP_API_URL;

type mealBlockTypeOrganizer = {
  mealBlock: {
    id: number;
    type: MealBlockType;
    choice: MealBlockChoice;
    state: MealBlockState;
    restaurantId: string;
    restaurantName: string;
    restaurant: null;
    preSelectedMealsIds: string[] | null;
    agendaItem: {
      id: number;
      index: number;
      name: string;
      description: string;
      duration: number;
      type: AgendaBlockType;
      dayId: number;
      mealBlockId: number;
      createdAt: string;
      updatedAt: string;
      day: {
        id: number;
        startTime: string;
        endTime: string;
        event: {
          id: number;
          name: string;
          slug: string;
          description: string;
          accountId: number;
          location: string;
          locationNote: null | string;
          locationLongitude: string;
          locationLatitude: string;
          timezone: string;
          startDate: string;
          endDate: string;
          createdAt: string;
          updatedAt: string;
          isPublished: boolean;
          color: null | logoColor;
          pattern: null | logoImage;
        };
        agenda: [
          {
            id: number;
            index: number;
            name: string;
            description: string;
            duration: number;
            type: AgendaBlockType;
            dayId: number;
            mealBlockId: number;
            createdAt: string;
            updatedAt: string;
          }
        ];
      };
    };
  };
  totalPrice: number;
};

type mealBlockTypeAttendee = {
  id: number;
  type: MealBlockType;
  choice: MealBlockChoice;
  state: MealBlockState;
  restaurantId: string;
  restaurantName: string;
  restaurant: null;
  preSelectedMealsIds: string[] | null;
  agendaItem: {
    id: number;
    index: number;
    name: string;
    description: string;
    duration: number;
    type: AgendaBlockType;
    dayId: number;
    mealBlockId: number;
    createdAt: string;
    updatedAt: string;
    day: {
      id: number;
      startTime: string;
      endTime: string;
      event: {
        id: number;
        name: string;
        slug: string;
        description: string;
        accountId: number;
        location: string;
        locationNote: null | string;
        locationLongitude: string;
        locationLatitude: string;
        timezone: string;
        startDate: string;
        endDate: string;
        createdAt: string;
        updatedAt: string;
        isPublished: boolean;
        color: null | logoColor;
        pattern: null | logoImage;
      };
      agenda: [
        {
          id: number;
          index: number;
          name: string;
          description: string;
          duration: number;
          type: AgendaBlockType;
          dayId: number;
          mealBlockId: number;
          createdAt: string;
          updatedAt: string;
        }
      ];
    };
  };
};

function loginIsNotFinished(response: any) {
  if (response.data.statusCode === 403) {
    window.location.href = "/login";
  }
}

export async function fetchEvents({
  skip,
  take,
  start,
  end,
  token,
  eventFilter,
  isPublished,
  isActive,
  isFinished,
}: {
  skip?: number;
  take?: number;
  start: number;
  end: number;
  token: string | null;
  eventFilter: EventsFilter;
  isPublished: boolean;
  isFinished?: boolean;
  isActive?: boolean;
}) {
  // if (!token) return;

  const refreshedToken = await refreshToken();

  // const currentToken = refreshedToken ? refreshedToken : token;

  let url;

  if (skip && take) {
    url = `${baseUrl}events?skip=${skip}&take=${take}&start=${start}&end=${end}&filter=${eventFilter}&isPublished=${isPublished}`;
  } else {
    url = `${baseUrl}events?start=${start}&end=${end}&filter=${eventFilter}&isPublished=${isPublished}`;
  }

  if (isFinished) {
    url += `&isFinished=${isFinished}`;
  }

  if (!isFinished && isActive) {
    url += `&isActive=${isActive}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    const {
      data,
    }: {
      data: {
        startTime: string;
        endTime?: string;
        slug: string;
        name: string;
        description: string;
        location: string;
        locationNote?: string;
        timezone?: string;
        timezoneName?: string;
        startDate: string;
        endDate: string;
        isOrganizer: boolean;
        color: logoColor | null;
        pattern: logoImage | null;
        totalDuration: number;
      }[];
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function registerUser({
  user,
}: {
  user: { fullName: string; email: string; id: string };
}) {
  const response = await axios.post(`${baseUrl}auth/register`, {
    fullName: user.fullName,
    email: user.email,
    id: user.id,
  });

  return response;
}

export async function fetchMyData({ token }: { token: string | null }) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}users/me`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        id: 0;
        email: string;
        fullName: string;
        account: {
          id: 0;
          owner: string;
          ownerId: 0;
          users: [string];
          events: [
            {
              id: 0;
              name: string;
              slug: string;
              description: string;
              account: string;
              accountId: 0;
              days: [
                {
                  id: 0;
                  event: string;
                  startTime: string;
                  endTime: string;
                  agenda: [
                    {
                      id: 0;
                      index: 0;
                      name: string;
                      description: string;
                      duration: 15;
                      type: AgendaBlockType;
                      day: string;
                      dayId: 0;
                      mealBlock: string;
                      mealBlockId: 1;
                      createdAt: string;
                      updatedAt: string;
                    }
                  ];
                }
              ];
              location: string;
              locationNote: string;
              locationLongitude: string;
              locationLatitude: string;
              timezone: string;
              startDate: string;
              endDate: string;
              organizers: [string];
              createdAt: string;
              updatedAt: string;
              isPublished: true;
              color: string;
              pattern: logoImage;
              attendees: [
                {
                  id: 0;
                  event: string;
                  networkMember: string;
                  networkMemberId: 0;
                  eventId: 0;
                  status: "added";
                  rsvp: AttendeeRSVP;
                  inviter: string;
                  inviterId: 0;
                  createdAt: string;
                  updatedAt: string;
                }
              ];
              documents: [
                {
                  id: 0;
                  event: string;
                  key: string;
                  originalFileName: string;
                  url: string;
                  isUploaded: true;
                  uploader: string;
                  createdAt: string;
                }
              ];
            }
          ];
          businessName: string;
          status: "active";
          network: [
            {
              id: 0;
              fullName: string;
              email: string;
              phone: string;
              businessName: string;
              address: string;
              title: string;
              user: string;
              userId: 0;
              attendees: [
                {
                  id: 0;
                  event: string;
                  networkMember: string;
                  networkMemberId: 0;
                  eventId: 0;
                  status: "added";
                  rsvp: AttendeeRSVP;
                  inviter: string;
                  inviterId: 0;
                  createdAt: string;
                  updatedAt: string;
                }
              ];
              account: string;
              accountId: 0;
              createdAt: string;
              updatedAt: string;
            }
          ];
          restaurants: [
            {
              id: 0;
              name: string;
              location: string;
              locationLongitude: string;
              locationLatitude: string;
              url: string;
              account: string;
              accountId: 0;
              createdAt: string;
            }
          ];
          createdAt: string;
          updatedAt: string;
        };
        accountId: 0;
        supabaseId: string;
        phone: string;
        status: "pending";
        organizedEvents: [
          {
            id: 0;
            role: "admin";
            userId: 0;
            eventId: 0;
            user: string;
            event: {
              id: 0;
              name: string;
              slug: string;
              description: string;
              account: string;
              accountId: 0;
              days: [
                {
                  id: 0;
                  event: string;
                  startTime: string;
                  endTime: string;
                  agenda: [
                    {
                      id: 0;
                      index: 0;
                      name: string;
                      description: string;
                      duration: 15;
                      type: AgendaBlockType;
                      day: string;
                      dayId: 0;
                      mealBlock: string;
                      mealBlockId: 1;
                      createdAt: string;
                      updatedAt: string;
                    }
                  ];
                }
              ];
              location: string;
              locationNote: string;
              locationLongitude: string;
              locationLatitude: string;
              timezone: string;
              startDate: string;
              endDate: string;
              organizers: [string];
              createdAt: string;
              updatedAt: string;
              isPublished: true;
              color: string;
              pattern: logoImage;
              attendees: [
                {
                  id: 0;
                  event: string;
                  networkMember: string;
                  networkMemberId: 0;
                  eventId: 0;
                  status: "added";
                  rsvp: AttendeeRSVP;
                  inviter: string;
                  inviterId: 0;
                  createdAt: string;
                  updatedAt: string;
                }
              ];
              documents: [
                {
                  id: 0;
                  event: string;
                  key: string;
                  originalFileName: string;
                  url: string;
                  isUploaded: true;
                  uploader: string;
                  createdAt: string;
                }
              ];
            };
          }
        ];
        isTutorialCompleted: true;
        mealSelections: [
          {
            id: 0;
            mealBlockId: 0;
            user: string;
            productId: string;
            markedPrice: 0;
            notes: string;
            mealBlock: {
              id: 0;
              agendaItem: {
                id: 0;
                index: 0;
                name: string;
                description: string;
                duration: 15;
                type: AgendaBlockType;
                day: string;
                dayId: 0;
                mealBlock: string;
                mealBlockId: 1;
                createdAt: string;
                updatedAt: string;
              };
              agendaItemId: 0;
              type: MealBlockType;
              choice: MealBlockChoice;
              restaurantId: string;
              restaurant: {
                id: 0;
                name: string;
                location: string;
                locationLongitude: string;
                locationLatitude: string;
                url: string;
                account: string;
                accountId: 0;
                createdAt: string;
              };
              customRestaurantId: 0;
              restaurantName: string;
              cartId: string;
              realOrderId: string;
              state: MealBlockState;
              mealSelections: [string];
              preSelectedMealsIds: [string];
              createdAt: string;
            };
            customizations: [
              {
                id: 0;
                customizationId: string;
                optionId: string;
                markedPrice: 0;
                mealSelection: string;
              }
            ];
          }
        ];
        location: string;
        timezone: string;
        pronouns: string;
        foodPreferences: string[];
        allergies: string[];
        createdAt: string;
        updatedAt: string;
      };
    } = await response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchEvent({
  slug,
  token,
}: {
  slug: string | undefined;
  token: string | null;
}) {
  if (!token || !slug) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}events/${slug}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        slug: string;
        id: number;
        startTime: string;
        endTime?: string;
        name: string;
        description: string;
        location: string;
        locationNote?: string;
        timezone?: string;
        timezoneName?: string;
        startDate: string;
        endDate: string;
        locationLongitude: string | null;
        locationLatitude: string | null;
        isOrganizer: boolean;
        color: logoColor | null;
        pattern: logoImage | null;
        agenda: [
          {
            id: number;
            index: number;
            name: string;
            description: string;
            duration: number;
            type: AgendaBlockType;
            dayId: number;
            createdAt: string;
            updatedAt: string;
            mealBlockId: number;
          }
        ];
        attendees: [
          {
            id: number;
            email: string;
            fullName: string;
            userId: null | number;
            businessName: string;
            eventId: number;
            status: string;
            rsvp: null | string;
            inviterId: number;
            title: string;
            address: string;
            createdAt: string;
            updatedAt: string;
            networkMemberId: number;
          }
        ];
        documents: [
          {
            id: number;
            isUploaded: boolean;
            key: string;
            originalFileName: string;
            url: string | null;
          }
        ];
        days: [
          {
            id: number;
            startTime: string;
            agenda: [];
          }
        ];
        isPublished: true;
        rsvp: AttendeeRSVP;
        totalDuration: number;
      };
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postEvent({
  body,
  token,
}: {
  body: {
    startTime: number;
    endTime?: number;
    name: string;
    description: string;
    location: string;
    locationLongitude: string | null;
    locationLatitude: string | null;
    locationNote?: string;
    timezone?: string;
    timezoneName?: string;
    startDate: string;
    endDate: string;
  };
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}events`;

  try {
    const { data } = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function editEvent({
  body,
  token,
  id,
}: {
  body: {
    startTime: number;
    endTime?: number;
    name: string;
    description: string;
    location: string;
    locationLongitude: string | null;
    locationLatitude: string | null;
    locationNote?: string;
    timezone?: string;
    timezoneName?: string;
    startDate: string;
    endDate: string;
  };
  token: string | null;
  id: number;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}events/${id}`;

  try {
    const { data } = await axios.put(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postEventAgenda({
  body,
  dayId,
  token,
}: {
  body: {
    name: string;
    index: number;
    description: string;
    duration: number;
    type: AgendaBlockType;
    dayId: number;
  }[];
  dayId: number;
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}events/${dayId}/agenda`;

  try {
    const response = await axios.post(
      url,
      {
        agendaItems: body,
      },
      {
        headers: {
          Authorization: "Bearer " + currentToken,
        },
      }
    );

    const {
      data,
    }: {
      data: {
        id?: number;
        name: string;
        index: number;
        description: string;
        duration: number;
        type: AgendaBlockType;
        dayId: number;
        mealBlock?: {
          id: number;
          agendaItem: string;
          agendaItemId: number;
          type: MealBlockType;
          choice: MealBlockChoice;
          restaurantId: string;
          restaurantName: string;
          cartId: string;
          realOrderId: string;
          state: MealBlockState;
          mealSelections: [
            {
              id: number;
              mealBlockId: number;
              userId: number;
              productId: string;
              markedPrice: number;
              notes: string;
              mealBlock: string;
              customizations: [
                {
                  id: number;
                  customizationId: string;
                  optionId: string;
                  markedPrice: number;
                  mealSelection: string;
                }
              ];
            }
          ];
          preSelectedMealsIds?: [string];
          createdAt: string;
        };
      }[];
    } = response;

    return { status: response.status, data };
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function editEventAgenda({
  body,
  dayId,
  token,
}: {
  body: {
    id?: number;
    name: string;
    index: number;
    description: string;
    duration: number;
    type: AgendaBlockType;
    dayId: number;
  }[];
  dayId: number;
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}events/${dayId}/agenda`;

  try {
    const response = await axios.post(
      url,
      {
        agendaItems: body,
      },
      {
        headers: {
          Authorization: "Bearer " + currentToken,
        },
      }
    );

    const {
      data,
    }: {
      data: {
        id?: number;
        name: string;
        index: number;
        description: string;
        duration: number;
        type: AgendaBlockType;
        dayId: number;
        mealBlockId: number | undefined;
        mealBlock?: {
          id: number;
          agendaItem: string;
          agendaItemId: number;
          type: MealBlockType;
          choice: MealBlockChoice;
          restaurantId: string;
          restaurantName: string;
          cartId: string;
          realOrderId: string;
          state: MealBlockState;
          mealSelections: [
            {
              id: number;
              mealBlockId: number;
              userId: number;
              productId: string;
              markedPrice: number;
              notes: string;
              mealBlock: string;
              customizations: [
                {
                  id: number;
                  customizationId: string;
                  optionId: string;
                  markedPrice: number;
                  mealSelection: string;
                }
              ];
            }
          ];
          preSelectedMealsIds?: [string];
          createdAt: string;
        };
      }[];
    } = response;

    return { status: response.status, data };
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchEventAgenda({
  dayId,
  token,
}: {
  dayId: number;
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}events/${dayId}/agenda`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        id?: number;
        name: string;
        index: number;
        description: string;
        duration: number;
        type: AgendaBlockType;
        dayId: number;
        mealBlockId: number;
        mealBlock: {
          id: number;
          agendaItemId: number;
          type: MealBlockType;
          choice: MealBlockChoice;
          restaurantId: string;
          customRestaurantId: null | number;
          restaurantName: string;
          cartId: null | string;
          realOrderId: null | string;
          state: MealBlockChoice;
          preSelectedMealsIds: string[];
          createdAt: string;
        };
      }[];
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchAttendeesNetwork({
  search,
  token,
  skip,
  take,
  fetchEvents,
}: {
  search?: string;
  token: string | null;
  skip: number;
  take: number;
  fetchEvents: boolean;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}attendee-network?skip=${skip}&take=${take}&fetchEvents=${fetchEvents}`;

  if (search) {
    url += `&search=${search}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        network: [
          {
            id: number;
            fullName: string;
            email: string;
            phone?: string;
            businessName: string;
            address: string;
            title: string;
            updatedAt: string;
            user: {
              id: number;
              email: string;
              fullName: string;
              phone?: null | string;
            };
            events: [
              {
                id: number;
                name: string;
                slug: string;
                description: string;
                timezone: string;
                color: null | logoColor;
                pattern: null | logoImage;
                startTime: string;
                endTime: string;
                attendee: {
                  email: string;
                  userId: number;
                  status: "added";
                  rsvp: null | AttendeeRSVP;
                };
              }
            ];
          }
        ];
        count: number;
      };
    } = await response;

    return data.network.map((attendee) => ({
      accountId: attendee.id,
      createdAt: "",
      email: attendee.email,
      fullName: attendee.fullName,
      id: attendee.id,
      phone: attendee.phone,
      updatedAt: "",
      address: attendee.address,
      businessName: attendee.businessName,
      title: attendee.title,
    }));
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchAttendeesContacts({
  search,
  token,
  skip,
  take,
  fetchEvents,
}: {
  search?: string;
  token: string | null;
  skip: number;
  take: number;
  fetchEvents: boolean;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}attendee-network?skip=${skip}&take=${take}&fetchEvents=${fetchEvents}`;

  if (search) {
    url += `&search=${search}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        network: [
          {
            id: number;
            fullName: string;
            email: string;
            phone?: string;
            businessName: string;
            address: string;
            title: string;
            updatedAt: string;
            user: {
              id: number;
              email: string;
              fullName: string;
              phone?: null | string;
            };
            events: [
              {
                id: number;
                name: string;
                slug: string;
                description: string;
                timezone: string;
                color: null | logoColor;
                pattern: null | logoImage;
                startTime: string;
                endTime: string;
                attendee: {
                  email: string;
                  userId: number;
                  status: "added";
                  rsvp: null | AttendeeRSVP;
                };
              }
            ];
          }
        ];
        count: number;
      };
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postAttendee({
  body,
  token,
}: {
  body: {
    email: string;
    businessName: string;
    address: string;
    title: string;
    eventId: number;
    fullName: string;
    phone?: string;
  };
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}attendees`;

  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const { data } = await response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function editAttendee({
  body,
  token,
  id,
}: {
  body: {
    email: string;
    businessName: string;
    address: string;
    title: string;
    fullName: string;
    phone?: string;
  };
  token: string | null;
  id: number;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}attendee-network/${id}`;

  try {
    const { data } = await axios.put(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postAttendeeToAttendeesNetwork({
  body,
  token,
}: {
  body: {
    email: string;
    businessName: string;
    address: string;
    title: string;
    fullName: string;
    phone?: string;
  };
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}attendee-network`;

  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const { data } = await response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postEventLogo({
  body,
  token,
  id,
}: {
  body: {
    color: logoColor;
    pattern: logoImage;
  };
  token: string | null;
  id: number;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}events/${id}/appearance`;

  try {
    const { data } = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchInvitedAttendees({
  token,
  eventId,
  skip,
  take,
}: {
  token: string | null;
  eventId: number;
  take: number;
  skip: number;
}) {
  if (!token || !eventId) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}attendees?eventId=${eventId}&skip=${skip}&take=${take}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        attendees: {
          id: number;
          networkMemberId: number;
          status: "added";
          rsvp: AttendeeRSVP | null;
          networkMember: {
            id: number;
            fullName: string;
            email: string;
            phone: string;
            businessName: string;
            address: string;
            title: string;
            userId: null | number;
            accountId: number;
            createdAt: string;
            updatedAt: string;
            user: {
              accountId: number;
              createdAt: string;
              email: string;
              fullName: string;
              id: number;
              phone?: string | null;
              status: string;
              supabaseId: string;
              updatedAt: string;
            } | null;
          };
          mealSelections: any[];
        }[];
        count: number;
      };
    } = response;
    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function deleteAttendee({
  token,
  attendeeId,
}: {
  token: string | null;
  attendeeId: number;
}) {
  if (!token || !attendeeId) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}attendees/${attendeeId}`;

  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const { data } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function deleteAttendeeFromContacts({
  token,
  attendeeId,
}: {
  token: string | null;
  attendeeId: number;
}) {
  if (!token || !attendeeId) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}attendee-network/${attendeeId}`;

  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const { data } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function publishEvent({
  token,
  eventId,
}: {
  token: string | null;
  eventId: number;
}) {
  if (!token || !eventId) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}events/${eventId}/publish`;

  const response = await axios.put(url, undefined, {
    headers: {
      Authorization: "Bearer " + currentToken,
    },
  });

  const { data } = response;

  return data;
}

export async function fetchSupportInfoData({
  token,
}: {
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}support`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        Email: string;
        Phone: string;
        Schedule: string;
      };
    } = await response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postAttendeeRSVP({
  body,
  token,
}: {
  body: { rsvp: AttendeeRSVP; eventId: number };
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}attendees/rsvp`;

  try {
    const { data } = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postUserCompleteTutorial({
  token,
}: {
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}users/complete-tutorial`;

  const { data } = await axios.post(url, undefined, {
    headers: {
      Authorization: "Bearer " + currentToken,
    },
  });

  return data;
}

export async function fetchMeals({
  id,
  token,
}: {
  id: number;
  token: string | null;
}) {
  if (!token || !id) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}meals/${id}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: mealBlockTypeOrganizer | mealBlockTypeAttendee;
    } = response;

    if (Object.keys(data).includes("mealBlock")) {
      return data as mealBlockTypeOrganizer;
    } else {
      return {
        mealBlock: data as mealBlockTypeAttendee,
        totalPrice: undefined,
      };
    }
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postMeals({
  body,
  token,
}: {
  body: {
    type: MealBlockType;
    choice: MealBlockChoice;
    agendaItemId: number;
  };
  token: string | null;
}) {
  if (!token || !body) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}meals`;

  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        agendaItemId: number;
        choice: MealBlockChoice;
        type: MealBlockType;
        cartId: null;
        createdAt: string;
        customRestaurantId: null;
        id: number;
        preSelectedMealsIds: null;
        realOrderId: null;
        restaurantId: null;
        restaurantName: null;
        state: MealBlockState;
        userId: number;
      };
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchRestaurants({
  location,
  search,
  token,
  isOpen,
  isPickup,
  latitude,
  longitude,
  dayId,
  agendaBlockStartTime,
  page,
}: {
  location: string;
  search?: string;
  token: string | null;
  latitude: number;
  longitude: number;
  isOpen: boolean;
  isPickup: boolean;
  dayId: number;
  agendaBlockStartTime: number;
  page: number;
}) {
  if (!token || !location) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}meals/restaurants?location=${location}&isOpen=${isOpen}&isPickup=${isPickup}&latitude=${latitude}&longitude=${longitude}&eventDayId=${dayId}&agendaItemStart=${agendaBlockStartTime}&page=${page}`;

  if (search) {
    url = `${baseUrl}meals/restaurants?location=${location}&isOpen=${isOpen}&isPickup=${isPickup}&latitude=${latitude}&longitude=${longitude}&eventDayId=${dayId}&agendaItemStart=${agendaBlockStartTime}&search=${search}&page=${page}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        stores: [
          {
            _id: string;
            name: string;
            phone_number: number;
            address: {
              street_addr: string;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              street_addr_2: string;
              latitude: number;
              longitude: number;
            };
            description: string;
            local_hours: {
              operational: {
                Monday: string;
                Tuesday: string;
                Wednesday: string;
                Thursday: string;
                Friday: string;
                Saturday: string;
                Sunday: string;
              };
              delivery: {
                Monday: string;
                Tuesday: string;
                Wednesday: string;
                Thursday: string;
                Friday: string;
                Saturday: string;
                Sunday: string;
              };
              pickup: {
                Monday: string;
                Tuesday: string;
                Wednesday: string;
                Thursday: string;
                Friday: string;
                Saturday: string;
                Sunday: string;
              };
              dine_in: {
                Monday: string;
                Tuesday: string;
                Wednesday: string;
                Thursday: string;
                Friday: string;
                Saturday: string;
                Sunday: string;
              };
            };
            cuisines: string[];
            food_photos: string[];
            logo_photos: string[];
            store_photos: string[];
            dollar_signs: number;
            pickup_enabled: boolean;
            delivery_enabled: boolean;
            is_open: boolean;
            offers_first_party_delivery: boolean;
            offers_third_party_delivery: boolean;
            quote_ids: string[];
            miles: number;
            weighted_rating_value: number;
            aggregated_rating_count: number;
            supports_upc_codes: boolean;
            type: "restaurant";
          }
        ];
        next_page: number;
      };
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchSpecificRestaurant({
  id,
  token,
}: {
  id: string;
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}meals/restaurants/${id}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        _id: string;
        name: string;
        phone_number: number;
        address: {
          street_addr: string;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          street_addr_2: string;
          latitude: number;
          longitude: number;
        };
        description: string;
        local_hours: {
          operational: {
            Monday: string;
            Tuesday: string;
            Wednesday: string;
            Thursday: string;
            Friday: string;
            Saturday: string;
            Sunday: string;
          };
          delivery: {
            Monday: string;
            Tuesday: string;
            Wednesday: string;
            Thursday: string;
            Friday: string;
            Saturday: string;
            Sunday: string;
          };
          pickup: {
            Monday: string;
            Tuesday: string;
            Wednesday: string;
            Thursday: string;
            Friday: string;
            Saturday: string;
            Sunday: string;
          };
          dine_in: {
            Monday: string;
            Tuesday: string;
            Wednesday: string;
            Thursday: string;
            Friday: string;
            Saturday: string;
            Sunday: string;
          };
        };
        cuisines: string[];
        food_photos: string[];
        logo_photos: string[];
        store_photos: string[];
        dollar_signs: number;
        pickup_enabled: boolean;
        delivery_enabled: boolean;
        is_open: boolean;
        offers_first_party_delivery: boolean;
        offers_third_party_delivery: boolean;
        quote_ids: string[];
        miles: number;
        weighted_rating_value: number;
        aggregated_rating_count: number;
        supports_upc_codes: boolean;
        type: "restaurant";
      };
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postRestaurants({
  id,
  body,
  token,
}: {
  id: number;
  body: { restaurantId?: string; customRestaurantId?: number };
  token: string | null;
}) {
  if (!token || !id) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}meals/${id}/restaurants`;

  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const { data } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchRestaurantMenu({
  id,
  token,
  isPickup,
}: {
  id: string;
  token: string | null;
  isPickup: boolean;
}) {
  if (!token || !id) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}meals/menu/${id}?isPickup=${isPickup}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        name: string;
        subcategory_id: string;
        menu_item_list: {
          name: string;
          price: number;
          qty_available: null;
          unit_size: null;
          unit_of_measurement: string;
          description: string;
          is_available: boolean;
          image: string;
          customizations: [
            {
              name: string;
              min_choice_options: number;
              max_choice_options: number;
              options: [
                {
                  name: string;
                  price: number;
                  customizations: any[];
                  min_qty: number;
                  max_qty: number;
                  conditional_price: {};
                  formatted_price: string;
                  default_qty: number;
                  option_id: string;
                }
              ];
              customization_id: string;
            }
          ];
          min_price: number;
          original_price: number;
          formatted_price: string;
          attributes: [];
          product_id: string;
          thumbnail_image: string;
          should_fetch_customizations: boolean;
          supports_image_scaling: boolean;
        }[];
      }[];
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postMealsSelections({
  id,
  body,
  token,
}: {
  id: number;
  body: {
    customizations: {
      customizationId: string;
      optionId: string;
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
  };
  token: string | null;
}) {
  if (!token || !id) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}meals/${id}/selections`;

  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const { data } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchMealsSelections({
  id,
  token,
}: {
  id: number;
  token: string | null;
}) {
  if (!token || !id) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}meals/${id}/selections`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: [
        {
          id: number;
          mealBlockId: number;
          userId: number;
          productId: string;
          markedPrice: number;
          notes: string;
          mealBlock: {
            id: number;
            agendaItem: {
              id: number;
              index: number;
              name: string;
              description: string;
              duration: number;
              type: AgendaBlockType;
              day: {
                id: number;
                event: {
                  id: number;
                  name: string;
                  slug: string;
                  description: string;
                  account: {
                    id: number;
                    owner: {
                      id: number;
                      email: string;
                      fullName: string;
                      account: string;
                      accountId: number;
                      supabaseId: string;
                      phone: string;
                      status: "pending";
                      organizedEvents: [string];
                      isTutorialCompleted: boolean;
                      asAttendee: [string];
                      createdAt: string;
                      updatedAt: string;
                    };
                    ownerId: number;
                    users: [
                      {
                        id: number;
                        email: string;
                        fullName: string;
                        account: string;
                        accountId: number;
                        supabaseId: string;
                        phone: string;
                        status: "pending";
                        organizedEvents: [string];
                        isTutorialCompleted: boolean;
                        asAttendee: [string];
                        createdAt: string;
                        updatedAt: string;
                      }
                    ];
                    events: [string];
                    businessName: string;
                    status: "active";
                    network: [
                      {
                        id: number;
                        fullName: string;
                        email: string;
                        phone: string;
                        businessName: string;
                        address: string;
                        title: string;
                        user: {
                          id: number;
                          email: string;
                          fullName: string;
                          account: string;
                          accountId: number;
                          supabaseId: string;
                          phone: string;
                          status: "pending";
                          organizedEvents: [string];
                          isTutorialCompleted: true;
                          asAttendee: [string];
                          createdAt: string;
                          updatedAt: string;
                        };
                        account: string;
                        accountId: number;
                        createdAt: string;
                        updatedAt: string;
                      }
                    ];
                    createdAt: string;
                    updatedAt: string;
                  };
                  accountId: number;
                  days: [string];
                  location: string;
                  locationNote: string;
                  timezone: string;
                  startDate: string;
                  endDate: string;
                  organizers: [
                    {
                      id: number;
                      role: "admin";
                      userId: number;
                      eventId: number;
                      user: {
                        id: number;
                        email: string;
                        fullName: string;
                        account: string;
                        accountId: number;
                        supabaseId: string;
                        phone: string;
                        status: "pending";
                        organizedEvents: [string];
                        isTutorialCompleted: true;
                        asAttendee: [string];
                        createdAt: string;
                        updatedAt: string;
                      };
                      event: string;
                    }
                  ];
                  createdAt: string;
                  updatedAt: string;
                  isPublished: boolean;
                  color: logoColor;
                  pattern: logoImage;
                  attendees: [
                    {
                      id: number;
                      email: string;
                      fullName: string;
                      user: {
                        id: number;
                        email: string;
                        fullName: string;
                        account: string;
                        accountId: number;
                        supabaseId: string;
                        phone: string;
                        status: "pending";
                        organizedEvents: [string];
                        isTutorialCompleted: true;
                        asAttendee: [string];
                        createdAt: string;
                        updatedAt: string;
                      };
                      userId: number;
                      event: string;
                      businessName: string;
                      eventId: number;
                      status: "added";
                      rsvp: AttendeeRSVP;
                      inviter: {
                        id: number;
                        email: string;
                        fullName: string;
                        account: string;
                        accountId: number;
                        supabaseId: string;
                        phone: string;
                        status: "pending";
                        organizedEvents: [string];
                        isTutorialCompleted: true;
                        asAttendee: [string];
                        createdAt: string;
                        updatedAt: string;
                      };
                      inviterId: number;
                      pendingInvites: [
                        {
                          id: number;
                          email: string;
                          attendee: string;
                          attendeeId: number;
                          createdAt: string;
                        }
                      ];
                      title: string;
                      address: string;
                      createdAt: string;
                      updatedAt: string;
                    }
                  ];
                };
                startTime: string;
                endTime: string;
                agenda: [string];
              };
              dayId: number;
              mealBlock: string;
              createdAt: string;
              updatedAt: string;
            };
            agendaItemId: number;
            type: MealBlockType;
            choice: MealBlockChoice;
            restaurantId: string;
            restaurantName: string;
            cartId: string;
            realOrderId: string;
            state: MealBlockState;
            mealSelections: [string];
            preSelectedMealsIds: [string];
            createdAt: string;
          };
          customizations: [
            {
              id: number;
              customizationId: string;
              optionId: string;
              markedPrice: number;
              mealSelection: string;
            }
          ];
        }
      ];
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function deleteMealSelections({
  id,
  token,
  selectionId,
}: {
  id: number;
  selectionId: number;
  token: string | null;
}) {
  if (!token || !id) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}meals/${id}/selections/${selectionId}`;

  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const { data } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchMealsSelectionsAll({
  id,
  token,
}: {
  id: number;
  token: string | null;
}) {
  if (!token || !id) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}meals/${id}/selections/all`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: [
        {
          id: number;
          mealBlockId: number;
          userId: number;
          productId: string;
          markedPrice: number;
          notes: string;
          mealBlock: {
            id: number;
            agendaItem: {
              id: number;
              index: number;
              name: string;
              description: string;
              duration: number;
              type: AgendaBlockType;
              day: {
                id: number;
                event: {
                  id: number;
                  name: string;
                  slug: string;
                  description: string;
                  account: {
                    id: number;
                    owner: {
                      id: number;
                      email: string;
                      fullName: string;
                      account: string;
                      accountId: number;
                      supabaseId: string;
                      phone: string;
                      status: "pending";
                      organizedEvents: [string];
                      isTutorialCompleted: boolean;
                      asAttendee: [string];
                      createdAt: string;
                      updatedAt: string;
                    };
                    ownerId: number;
                    users: [
                      {
                        id: number;
                        email: string;
                        fullName: string;
                        account: string;
                        accountId: number;
                        supabaseId: string;
                        phone: string;
                        status: "pending";
                        organizedEvents: [string];
                        isTutorialCompleted: boolean;
                        asAttendee: [string];
                        createdAt: string;
                        updatedAt: string;
                      }
                    ];
                    events: [string];
                    businessName: string;
                    status: "active";
                    network: [
                      {
                        id: number;
                        fullName: string;
                        email: string;
                        phone: string;
                        businessName: string;
                        address: string;
                        title: string;
                        user: {
                          id: number;
                          email: string;
                          fullName: string;
                          account: string;
                          accountId: number;
                          supabaseId: string;
                          phone: string;
                          status: "pending";
                          organizedEvents: [string];
                          isTutorialCompleted: true;
                          asAttendee: [string];
                          createdAt: string;
                          updatedAt: string;
                        };
                        account: string;
                        accountId: number;
                        createdAt: string;
                        updatedAt: string;
                      }
                    ];
                    createdAt: string;
                    updatedAt: string;
                  };
                  accountId: number;
                  days: [string];
                  location: string;
                  locationNote: string;
                  timezone: string;
                  startDate: string;
                  endDate: string;
                  organizers: [
                    {
                      id: number;
                      role: "admin";
                      userId: number;
                      eventId: number;
                      user: {
                        id: number;
                        email: string;
                        fullName: string;
                        account: string;
                        accountId: number;
                        supabaseId: string;
                        phone: string;
                        status: "pending";
                        organizedEvents: [string];
                        isTutorialCompleted: true;
                        asAttendee: [string];
                        createdAt: string;
                        updatedAt: string;
                      };
                      event: string;
                    }
                  ];
                  createdAt: string;
                  updatedAt: string;
                  isPublished: boolean;
                  color: logoColor;
                  pattern: logoImage;
                  attendees: [
                    {
                      id: number;
                      email: string;
                      fullName: string;
                      user: {
                        id: number;
                        email: string;
                        fullName: string;
                        account: string;
                        accountId: number;
                        supabaseId: string;
                        phone: string;
                        status: "pending";
                        organizedEvents: [string];
                        isTutorialCompleted: true;
                        asAttendee: [string];
                        createdAt: string;
                        updatedAt: string;
                      };
                      userId: number;
                      event: string;
                      businessName: string;
                      eventId: number;
                      status: "added";
                      rsvp: AttendeeRSVP;
                      inviter: {
                        id: number;
                        email: string;
                        fullName: string;
                        account: string;
                        accountId: number;
                        supabaseId: string;
                        phone: string;
                        status: "pending";
                        organizedEvents: [string];
                        isTutorialCompleted: true;
                        asAttendee: [string];
                        createdAt: string;
                        updatedAt: string;
                      };
                      inviterId: number;
                      pendingInvites: [
                        {
                          id: number;
                          email: string;
                          attendee: string;
                          attendeeId: number;
                          createdAt: string;
                        }
                      ];
                      title: string;
                      address: string;
                      createdAt: string;
                      updatedAt: string;
                    }
                  ];
                };
                startTime: string;
                endTime: string;
                agenda: [string];
              };
              dayId: number;
              mealBlock: string;
              createdAt: string;
              updatedAt: string;
            };
            agendaItemId: number;
            type: MealBlockType;
            choice: MealBlockChoice;
            restaurantId: string;
            restaurantName: string;
            cartId: string;
            realOrderId: string;
            state: MealBlockState;
            mealSelections: [string];
            preSelectedMealsIds: [string];
            createdAt: string;
          };
          customizations: [
            {
              id: number;
              customizationId: string;
              optionId: string;
              markedPrice: number;
              mealSelection: string;
            }
          ];
        }
      ];
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postLockOrders({
  id,
  token,
}: {
  id: number;
  token: string | null;
}) {
  if (!token || !id) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}meals/${id}/lock`;

  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: "Bearer " + currentToken,
        },
      }
    );

    const {
      data,
    }: {
      data: {
        id: number;
        agendaItemId: number;
        type: MealBlockType;
        choice: MealBlockChoice;
        restaurantId: string | null;
        customRestaurantId: null | number;
        restaurantName: string;
        cartId: string;
        realOrderId: null;
        state: MealBlockState;
        preSelectedMealsIds: string[];
        createdAt: string;
        agendaItem: {
          id: number;
          index: number;
          name: string;
          description: string;
          duration: number;
          type: AgendaBlockType;
          dayId: number;
          mealBlockId: number;
          createdAt: string;
          updatedAt: string;
          day: {
            id: number;
            startTime: string;
            endTime: null | string;
            event: {
              id: number;
              name: string;
              slug: string;
              description: string;
              accountId: number;
              location: string;
              locationNote: null | string;
              locationLongitude: string;
              locationLatitude: string;
              timezone: string;
              startDate: string;
              endDate: string;
              createdAt: string;
              updatedAt: string;
              isPublished: boolean;
              color: null | logoColor;
              pattern: null | logoImage;
            };
            agenda: {
              id: number;
              index: number;
              name: string;
              description: string;
              duration: number;
              type: AgendaBlockType;
              dayId: number;
              mealBlockId: number;
              createdAt: string;
              updatedAt: string;
            }[];
          };
        };
        restaurant: null;
      };
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postPreSelect({
  id,
  token,
  body,
}: {
  id: number;
  body: {
    productIds: string[];
  };
  token: string | null;
}) {
  if (!token || !id) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}meals/${id}/pre-select`;

  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const { data } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchLocations({
  search,
  token,
}: {
  search: string;
  token: string | null;
}) {
  if (!token || !search) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}maps?search=${search}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: [
        {
          name: string;
          lat: number;
          lng: number;
        }
      ];
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchSbConfig() {
  let url = `${baseUrl}utils/config`;

  const response = await axios.get(url);

  const {
    data,
  }: {
    data: {
      sbPublicKey: string;
      sbUrl: string;
    };
  } = response;

  return data;
}

export async function postDocument({
  id,
  token,
  body,
}: {
  id: number;
  body: {
    fileName: string;
    fileType?: string;
    fileSize?: number;
    url?: string;
  };
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}events/${id}/documents`;

  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        document: {
          event: { id: number };
          id: number;
          isUploaded: boolean;
          key: string;
          uploader: { id: number };
          url: null | string;
        };
        uploadUrl: string;
      };
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchDocument({
  id,
  token,
  documentId,
}: {
  id: number;
  documentId: number;
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}events/${id}/documents/${documentId}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        url?: "string";
        document: {
          id: number;
          key: string;
          originalFileName: string;
          url: string | null;
          isUploaded: boolean;
        };
      };
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function updateDocument({
  id,
  token,
  documentId,
}: {
  id: number;
  documentId: number;
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}events/${id}/documents/${documentId}/mark-as-uploaded`;

  try {
    const response = await axios.put(
      url,
      {},
      {
        headers: {
          Authorization: "Bearer " + currentToken,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function deleteDocument({
  id,
  token,
  documentId,
}: {
  id: number;
  documentId: number;
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}events/${id}/documents/${documentId}`;

  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const { data } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function resetPassword({
  body,
}: {
  body: {
    email: string;
  };
}) {
  let url = `${baseUrl}auth/reset-password`;

  const response = await axios.post(url, body);

  const { data } = response;

  return data;
}

export async function updatePassword({
  body,
  token,
}: {
  body: {
    password: string;
  };
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}auth/update-password`;

  const response = await axios.post(url, body, {
    headers: {
      Authorization: "Bearer " + currentToken,
    },
  });

  const { data } = response;

  return data;
}

export async function postNewRestaurant({
  body,
  token,
}: {
  body: {
    location: string;
    locationLatitude: string;
    locationLongitude: string;
    url: string;
    name: string;
  };
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}restaurants`;

  try {
    const { data } = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchCustomRestaurants({
  search,
  token,
  take,
  skip,
  latitude,
  longitude,
}: {
  search?: string;
  token: string | null;
  take: number;
  skip: number;
  latitude: string;
  longitude: string;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}restaurants?take=${take}&skip=${skip}&latitude=${latitude}&longitude=${longitude}`;

  if (search) {
    url = `${baseUrl}restaurants?take=${take}&skip=${skip}&search=${search}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: userRestaurantResponseType[];
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function updateNewRestaurant({
  body,
  token,
  id,
}: {
  body: {
    location: string;
    locationLatitude: string;
    locationLongitude: string;
    url: string;
    name: string;
  };
  token: string | null;
  id: number;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}restaurants/${id}`;

  try {
    const { data } = await axios.put(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function deleteNewRestaurant({
  token,
  id,
}: {
  token: string | null;
  id: number;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  const url = `${baseUrl}restaurants/${id}`;

  try {
    const { data } = await axios.delete(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchTodoItems({
  search,
  token,
  take,
  skip,
  eventId,
  isCompleted,
}: {
  search?: string;
  token: string | null;
  take: number;
  skip: number;
  eventId?: number;
  isCompleted?: boolean;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}todo-items?take=${take}&skip=${skip}`;

  if (search) {
    url = `${baseUrl}todo-items?take=${take}&skip=${skip}&search=${search}`;
  }

  if (eventId) {
    url += `&eventId=${eventId}`;
  }

  if (isCompleted !== undefined) {
    url += `&isCompleted=${isCompleted}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        todoItems: TodoItem[];
        total: number;
      };
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postTodoAsCompleted({
  id,
  token,
}: {
  id: number;
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}todo-items/${id}/mark-as-completed`;

  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: "Bearer " + currentToken,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function postReminder({
  type,
  token,
  networkMemberId,
  userId,
  eventId,
}: {
  type: "event-reminder";
  networkMemberId: number;
  userId: number;
  eventId: number;
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}notifications/reminder`;

  try {
    const response = await axios.post(
      url,
      {
        type,
        networkMemberId,
        userId,
        eventId,
      },
      {
        headers: {
          Authorization: "Bearer " + currentToken,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function patchUserSettings({
  body,
  token,
}: {
  body: {
    phone?: string;
    fullName?: string;
    location?: string;
    allergies?: string[];
    foodPreferences?: string[];
    pronouns?: string;
    timezone?: string;
  };
  token: string | null;
}) {
  if (!token) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}users`;

  try {
    const response = await axios.patch(url, body, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const { data } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchTimezone({
  lat,
  lng,
  token,
}: {
  lat?: number;
  lng?: number;
  token: string | null;
}) {
  if (!token || !lat || !lng) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}maps/timezone?lat=${lat}&lng=${lng}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: {
        timezoneName: string;
        timezoneOffset: number;
      };
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function fetchDocuments({
  slug,
  token,
}: {
  slug: string;
  token: string | null;
}) {
  if (!token || !slug) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}documents/${slug}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const {
      data,
    }: {
      data: any[];
    } = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function deleteEvent({
  id,
  token,
}: {
  id: number;
  token: string;
}) {
  if (!token || !id) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}events/${id}`;

  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: "Bearer " + currentToken,
      },
    });

    const { data }: any = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}

export async function editEventLogo({
  id,
  color,
  pattern,
  token,
  isOrganizer,
}: {
  id: number;
  color: logoColor;
  pattern: logoImage;
  token: string;
  isOrganizer: boolean;
}) {
  if (!token || (!pattern && !color) || !isOrganizer) return;

  const refreshedToken = await refreshToken();

  const currentToken = refreshedToken ? refreshedToken : token;

  let url = `${baseUrl}events/${id}/appearance`;

  try {
    const response = await axios.patch(
      url,
      {
        color,
        pattern,
      },
      {
        headers: {
          Authorization: "Bearer " + currentToken,
        },
      }
    );

    const { data }: any = response;

    return data;
  } catch (e: any) {
    console.log(e);
    loginIsNotFinished(e.response);
  }
}
