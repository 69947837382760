import React, { useEffect, useState } from "react";
import userPlus from "../../assets/images/user-plus-01.svg";
import FeaturedIcon from "../FeaturedIcons/FeaturedIcon";
import closeIcon from "../../assets/images/x-close-gray-lg.svg";
import userPlusIcon from "../../assets/images/user-plus-01-primary.svg";
import { FormInput } from "../Inputs/FormInput";
import { Button } from "../Buttons/Button";
import { DropdownMenu } from "../Dropdown/DropdownMenu";
import DropdownSelect from "../Dropdown/DropdownSelect";
import { postAttendee } from "../../httpQueries/http";

interface Props {
  attendees: {
    accountId: number;
    createdAt: string;
    email: string;
    fullName: string;
    id: number;
    phone?: string;
    updatedAt: string;
    address: string | null;
    businessName: string | null;
    title: string | null;
  }[];
  setShow: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      supportText?: string | undefined;
      value?: number | undefined;
    } | null>
  >;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  eventId: number;
  setIsUsersInvitedQueryWorks: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalAddAttendees({
  attendees,
  setShow,
  searchValue,
  setSearchValue,
  eventId,
  setIsUsersInvitedQueryWorks,
}: Props) {
  const [invitedAttendees, setInvitedAttendees] = useState<
    {
      id: number;
      name: string;
      supportText?: string;
      accountId?: number;
      createdAt?: string;
      phone?: string;
      updatedAt?: string;
      address?: string | null;
      businessName?: string | null;
      title?: string | null;
    }[]
  >([]);
  const [hasIconBefore, setHasIconBefore] = useState(true);
  const token = localStorage.getItem("token");

  async function OnAddAttendees() {
    if (invitedAttendees.length) {
      await Promise.all(
        invitedAttendees.map(async (item) => {
          return postAttendee({
            body: {
              email: item.supportText!,
              businessName: item.businessName ? item.businessName : "",
              address: item.address ? item.address : "",
              title: item.title ? item.title : "",
              eventId: eventId,
              fullName: item.name,
              phone: item.phone ? item.phone : "",
            },
            token,
          });
        })
      );

      setIsUsersInvitedQueryWorks(false);
    }

    setShow(null);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    document.getElementById("modal")?.classList.add("modal-main-container");
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
      document
        .getElementById("modal")
        ?.classList.remove("modal-main-container");
    };
  }, []);

  return (
    <div className="modalNotification">
      <div className="modalNotification-container modalNotification-container-inviteAttendees modalNotification-container-invite">
        <div className="modalNotification-circles-container">
          <div className="modalNotification-circles">
            <div className="eventDetails_circles">
              <div className="eventDetails-circle eventDetails-circle-fifth">
                <div className="eventDetails-circle eventDetails-circle-fourth">
                  <div className="eventDetails-circle eventDetails-circle-third">
                    <div className="eventDetails-circle eventDetails-circle-second">
                      <div className="eventDetails-circle eventDetails-circle-first"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modalNotification-header">
          <div className="modalNotification-header-actions">
            <FeaturedIcon
              icon={userPlus}
              size="lg"
              type="gray"
              positionAbsolute
            />
            <img
              src={closeIcon}
              alt="close icon"
              className="modalNotification-header-actions-close modalInvite-closeIcon"
              onClick={() => setShow(null)}
            />
          </div>
          <div className="modalNotification-header-info modalInvite-title">
            <h3>Add attendees</h3>
          </div>
        </div>
        <div className="modalNotification-main modalInvite-content">
          <div>
            <FormInput
              label={searchValue.length ? "" : "Search"}
              type="SearchGray"
              border="bottom"
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setSearchValue(event.target.value)}
              value={searchValue}
              hasIconBefore={hasIconBefore ? '' : undefined}
            />
            <Button
              buttonType="regular"
              size="md"
              hierarchy="linkColor"
              fullWidth
              onClick={() => setShow({ id: 2, name: "Add new" })}
            >
              <div className={`openButton text-sm`}>
                <img src={userPlusIcon} alt="" className="calendarIcon" />
                <p>Add new attendee</p>
              </div>
            </Button>
          </div>
          <DropdownSelect
            content={attendees.map((attendee) => ({
              accountId: attendee.accountId,
              createdAt: attendee.createdAt,
              supportText: attendee.email,
              name: attendee.fullName,
              id: attendee.id,
              phone: attendee.phone,
              updatedAt: attendee.updatedAt,
              address: attendee.address,
              businessName: attendee.businessName,
              title: attendee.title,
            }))}
            label="All users"
            selectedItems={invitedAttendees}
            setSelectedItems={setInvitedAttendees}
            inputValue={searchValue}
            setInputValue={setSearchValue}
            paddingNone
            setShowAddNewAttendee={setShow}
          />
        </div>
        {invitedAttendees.length ? (
          <div className="modalInvite-actions">
            <p className="modalInvite-actions-selectedItems">
              {invitedAttendees.length} selected
            </p>
            <Button
              buttonType="regular"
              size="sm"
              hierarchy="secondaryGray"
              onClick={OnAddAttendees}
            >
              Add attendees
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
