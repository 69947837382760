import React from "react";
import { Button } from "../Buttons/Button";
import plusIcon from "../../assets/images/plus.svg";

interface Props {
  title: string;
  emptyStateTilte: string;
  emptyStateImg: string;
  count?: number;
  items: React.ReactNode[];
  addButtonAction?: () => void;
  height?: string,
  minHeight?: string
}

export default function EventInfoPreview({
  title,
  emptyStateImg,
  emptyStateTilte,
  count,
  items,
  addButtonAction,
  height,
  minHeight
}: Props) {
  return (
    <div
      className="homePage-content-todos px-12 py-12 homePage-content-todos-min-w-200 bgWhite z100"
      style={{ height: height ?? "auto",  minHeight: minHeight ?? "unset" }}
    >
      <div className="homePage-content-todos-header">
        <div className="homePage-content-todos-header-topic">
          <h3 className="homePage-content-todos-header-topic-title">{title}</h3>
          <p className="homePage-content-todos-header-topic-todosCount">
            {count || items.length}
          </p>
        </div>
        {addButtonAction ? (
          <Button
            size={"sm"}
            hierarchy={"primary"}
            buttonType={"small"}
            isCircle
            onClick={addButtonAction}
          >
            <img src={plusIcon} alt="plus" width={16} height={16} />
          </Button>
        ) : null}
      </div>
      {items.length ? (
        <div className="homePage-content-todos-content">
          {items.map((item) => item)}
        </div>
      ) : (
        <div className="fullWidth items-center justifyCenter flex flex-column gap-16">
          <img
            src={emptyStateImg}
            alt=""
            className="homePage-content-todos-listIcon"
          />
          <div>
            <h3 className="textCenter color-gray-900 text-lg font-semibold">
              {emptyStateTilte}
            </h3>
          </div>
        </div>
      )}
    </div>
  );
}
