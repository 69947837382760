import React, { useEffect, useState } from "react";
import EventsGallery from "../../components/Gallery/EventsGallery";
import buttonPlusIcon from "../../assets/images/ButtonPlusImg.svg";
import SideBarNavigationNew from "../../components/Navigation/SideBarNavigationNew";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchTodoItems, postMealsSelections } from "../../httpQueries/http";
import { TodoItemType } from "../../types/todoItem";
import { createPortal } from "react-dom";
import PreviewPreselectedMealsInviteesMeals from "../ReviewEventsDetailsPage/SelectCustomizationAttendeesView/PreviewPreselectedMealsForAttendees";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../store/currentUser/selectors";
import TodoPreview from "../../components/Todo/TodoPreview";
import { useWindowSize } from "@uidotdev/usehooks";
import checkCircleIcon from "../../assets/images/check-circle-black.svg";
import BottomNavigation from "../../components/Navigation/BottomNavigation";
import { supabase } from "../../utilities/supabaseClient";

export default function HomePage({
  refetchEvents,
}: {
  refetchEvents: boolean;
}) {
  const navigate = useNavigate();
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const currentUser = useSelector(getCurrentUser);
  const token = localStorage.getItem("token");
  const [page, setPage] = useState<number>(0);
  const { data: todoItems } = useQuery({
    queryKey: ["home", refetchEvents],
    queryFn: async () =>
      await fetchTodoItems({
        token,
        take: 10,
        skip: page * 10,
        isCompleted: false,
      }),
  });
  const date = new Date();
  const formatter = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    weekday: "long",
  });
  const formattedDate = formatter.format(date);
  const [helloTextsArray, setHelloTextsArray] = useState<
    { id: number; text: string }[]
  >([]);
  const [currentHelloText, setCurrentHelloText] = useState({ id: 0, text: "" });

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (helloTextsArray.length) {
        setCurrentHelloText((prevState) => {
          if (prevState) {
            if (prevState.id + 1 > helloTextsArray.length - 1 && prevState) {
              return helloTextsArray[0];
            } else {
              return helloTextsArray[prevState.id + 1];
            }
          } else {
            return { id: 0, text: "" };
          }
        });
      }
    }, 5000);

    // return clearInterval(timer);
  }, [helloTextsArray]);

  useEffect(() => {
    if (!!currentUser && !!todoItems) {
      setHelloTextsArray([
        { id: 0, text: `Hello ${currentUser.fullName}` },
        { id: 1, text: `Today you have ${todoItems?.total} upcoming tasks` },
      ]);
    }
  }, [currentUser, todoItems]);

  useEffect(() => {
    const token = supabase()
      .auth.getSession()
      .then((response) => {
        if (!response.data.session?.access_token) {
          navigate("/login");
        }
      });
  }, []);

  return (
    <div className={`eventDetails_container homePage`}>
      <SideBarNavigationNew hierarchy="primary" currentItem={"Home"} />
      <div
        className={`overflowYScroll fullWidth ${
          sizeIsDesktop
            ? "eventDetails_main-container eventDetails_main-container-withoutBorder mb-none"
            : "fullHeight"
        }`}
      >
        <div className="eventDetails_main eventDetails_main-withoutPadding eventDetails_main-bgTransparent homePage-main">
          <div
            className={`eventDetails_main-header eventDetails_main-header-homePage homePage-header`}
            style={{ paddingTop: sizeIsDesktop ? "0" : "12px" }}
          >
            <div className="homePage-header-dayInfo">
              <p className="homePage-header-dayInfo-title">{formattedDate}</p>
              <p className="homePage-header-dayInfo-description">
                {currentHelloText.text}
              </p>
            </div>
            <div
              className="homePage-header-createEvent"
              onClick={() => navigate("/createEvent")}
            >
              <img
                src={buttonPlusIcon}
                alt=""
                className="homePage-header-createEvent-img"
              />
              <div className="homePage-header-createEvent-text">
                <p className="homePage-header-createEvent-text-title">
                  Create event
                </p>
              </div>
            </div>
          </div>
          <div
            className="eventDetails_content homePage-content overflowHidden"
            style={{ height: "fit-content" }}
          >
            <EventsGallery refetchEvents={refetchEvents} />
            <div
              className="homePage-content-todos bgWhite overflowYScroll"
              style={{
                height: "fit-content",
                minHeight: "unset",
                maxHeight: sizeIsDesktop ? "100%" : undefined,
              }}
            >
              <div className="homePage-content-todos-header">
                <div className="homePage-content-todos-header-topic">
                  <h3 className="homePage-content-todos-header-topic-title">
                    To do's
                  </h3>
                  <p className="homePage-content-todos-header-topic-todosCount">
                    {todoItems?.total}
                  </p>
                </div>
                <img src="" alt="" />
              </div>
              {todoItems?.total ? (
                <div className="homePage-content-todos-content">
                  {todoItems?.todoItems.map((todo) => (
                    <TodoPreview
                      toDo={todo}
                      name={
                        todo.type === TodoItemType.RespondToInvite
                          ? "Event invite"
                          : todo.type === TodoItemType.MealOrder
                          ? "Meal order"
                          : todo.type === TodoItemType.MealSelection
                          ? "Meal selection"
                          : todo.type === TodoItemType.SubmitEvent
                          ? "Submit event"
                          : todo.type === TodoItemType.ReviewDocument
                          ? "Review document"
                          : ""
                      }
                      description={
                        todo.type === TodoItemType.RespondToInvite
                          ? "Respond to the event invite"
                          : todo.type === TodoItemType.MealOrder
                          ? `Order meal for ${todo.event.name}`
                          : todo.type === TodoItemType.MealSelection
                          ? `Select meal for ${todo.event.name}`
                          : todo.type === TodoItemType.SubmitEvent
                          ? "Your event hasn’t been submitted."
                          : todo.type === TodoItemType.ReviewDocument
                          ? "Please, review the document"
                          : ""
                      }
                      key={todo.id}
                    />
                  ))}
                </div>
              ) : (
                <div className="fullWidth items-center justifyCenter flex flex-column gap-16">
                  <img
                    src={checkCircleIcon}
                    alt=""
                    className="homePage-content-todos-listIcon"
                  />
                  <div>
                    <h3 className="textCenter color-gray-900 text-lg font-semibold">
                      No tasks to do
                    </h3>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BottomNavigation selected="home" />
    </div>
  );
}
